/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Crm {
  constructor () {
    this.contato_id = uuidV4()
    this.cliente_id = ''
    this.motivo_contato_id = ''
    this.dt_contato = ''
    this.dt_retorno = ''
    this.informacoes = ''
    this.finalizado = false
  }
}
