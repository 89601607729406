/* eslint-disable */
import { API } from '../api'
import { acaoApi } from '../helpers/enums'

export const pegaLista= (url, data) => {
  return API.post(url, data)
}

export const lista = (url) => {
  return API.post(url)
}

export const processar = (url, objeto, acao) => {
  if (acao === acaoApi.REMOVER) {
    return API.delete(url, {data: objeto})
  } else {
    return API.post(url, objeto)
  }
}

export const pegaTema = (url, usuario) => {
  return API.get(url, { params: usuario })
}

export const pegaItem= (url, objeto) => {
  return API.get(url, { params: objeto })
}

export const pega = (url, id) => {
  return API.get(url + '/' + id)
}

export const listaSituacao = (url) => {
  return API.get(url + '/situacao')
}

export const dataAtual = (url) => {
  return API.get('/data_atual')
}

export const autentica = (url, objeto) => {
  return API.post(url, objeto)
}
export const getArquivo = (url,objeto) => {
  return API.post(url, {responseType: 'arraybuffer', item: objeto})
}
export const pegaDados = (url, data) => {
  return API.post(url, data)
}

export const pegaArquivo= (url, data) => {
  return API.post(url, data)
}

export const geraUrlRelatorio = async (url, data) => {
  let retorno = await API.post(url, data)
  return process.env.VUE_APP_API_URL + 'viewPdfReport/' + retorno.data
}

export const trataError = (error) => {
  if (error.response === undefined){
    return {
      message: "Falha na comunicação com o servidor.",
      success: false,
    }
  }
  return error.response.data
}
