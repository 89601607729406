/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";


export const  getModeloDocumentoSCH = (campo, valor, condicao)=>{
  var filtro = new Filtro()
  filtro.perPage = 1
  filtro.filters.addFilter(campo,valor,condicao)
  filtro.fields = ['*']
  return filtro
}

export const ModeloDocumentoSCH = (perPage)=> {
    var filtro = new Filtro()
    filtro.perPage = perPage
    filtro.fields = ['descricao', 'tp_doc']
    filtro.addOrder('descricao', 'asc')
  return filtro
}
