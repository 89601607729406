/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";

export const  getCrmSCH = (campo, valor, condicao)=>{
  var filtro = new Filtro()
  filtro.perPage = 1
  filtro.filters.addFilter(campo,valor,condicao)
  filtro.fields = ['*']
  return filtro
}

export const CrmSCH = (perPage = 1)=> {
    var filtro = new Filtro()
    filtro.perPage = perPage
    filtro.fields = ['dt_contato', 'dt_retorno', 'clientes.nome', 'contatos.motivo_contato_id', 'aux_motivos_contatos.descricao',
      'finalizado', 'hr_contato', 'hr_retorno']
    filtro.addOrder('descricao', 'asc')
    filtro.join.addLeft('clientes','clientes.cliente_id','contatos.cliente_id')
    filtro.join.addLeft('aux_motivos_contatos','aux_motivos_contatos.motivo_contato_id','contatos.motivo_contato_id')
  return filtro
}
