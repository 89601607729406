import {geraUrlRelatorio, trataError} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'

const url = '/contrato_receber'

const state = {
  pdf: ''
}

const getters = {
  pegaPdf: (state) => {
    return state.pdf
  }
}

const actions = {
  async processarEmissaoBoleto({commit}, dados) {
    try {
      const list = (await geraUrlRelatorio(url + '/gera_boleto', dados))
      commit('SET_PDF', list)
      EventBus.$emit('pdf-gerado', true)
    } catch (error) {
      this.dispatch('geral/setObjMsgError', trataError(error))
      EventBus.$emit('pdf-gerado', false)
    }
  }
}

const mutations = {
  SET_PDF(state, pdf) {
    state.pdf = pdf
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
