export const zeroEsquerda = function(value, totalWidth, paddingChar) {
  const length = totalWidth - value.toString().length + 1
  return Array(length).join(paddingChar || '0') + value
}

export const getDataAtual = function() {
  const d = zeroEsquerda(new Date().getDate(), 2)
  const m = zeroEsquerda(new Date().getMonth() + 1, 2)
  const a = new Date().getFullYear()
  return a + '-' + m + '-' + d
}
