/* eslint-disable */
import {geraUrlRelatorio, lista, pegaLista, processar, trataError} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {Empreendimento} from "../../../models/cadastros/empreendimento";
import {EmpreendimentoSCH, getEmpreendimentoSCH} from "../../../search/EmpreendimentoSCH"
import {Filtro, Where} from "../../../models/filtro/filtro";

const url = '/empreendimento'

const state = {
  all: [],
  listAux: [],
  filtro: new Filtro(),
  pdf: ''
}

const getters = {
  pegaPdf: (state) => {
    return state.pdf
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
  listaEmpreendimentos: (state) => {
    return state.all
  },
  listaEmpreendimentosSemFiltro: (state) => {
    return state.listAux
  },
  pegaEmpreendimentoId: (state) => (id) => {
    let mun = state.all.find(empreendimento => empreendimento.empreendimento_id === id)
    return mun !== null ? mun.nome : '** NÃO ENCONTRADO'
  },
  pegaEmpreendimento: (state) => (id) => {
    let mun = state.listAux.find(empreendimento => empreendimento.empreendimento_id === id)
    return mun !== null ? mun : new Empreendimento()
  },


}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = []
    state.filtro.filters.addSearch(
      ['empreendimentos.nome', 'logrado', 'bairro', 'municipios.nome'],
      '%' + search + '%',
      'ilike'
    )
  },
  async getItem({commit}, id) {
    var filtro = getEmpreendimentoSCH('empreendimento_id', id, '=')
    const list = (await pegaLista(url, filtro)).data
    EventBus.$emit('GET_EMPREENDIMENTO_CONCLUIDO', true, list.data[0])
  },
  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', EmpreendimentoSCH(perPage))
  },
  async setAllEmpreendimentosCompleto({commit}) {
    const list = (await lista(url+'/all')).data
    commit('SET_ALL_EMPREENDIMENTOS', list)
  },
  async setAllEmpreendimentos({commit},{onSucesso,onErro}) {
    try {
      const list = (await pegaLista(url, state.filtro)).data
      commit('SET_EMPREENDIMENTOS', list)
      onSucesso()
    } catch (error) {
      onErro()
    }
  },
  async processarEmpreendimento({commit}, {empreendimento, acao,onSucesso,onErro}) {
    try {
      let res = await processar(url, empreendimento, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      this.dispatch('logs_audit/processarLog', {item: empreendimento, key: 'empreendimento_id'})
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', trataError(error))
      onErro()
    }
  },
  async GerarRelatorioPdf({commit}, item) {
    try {
      const list = (await geraUrlRelatorio(url+'/pdf', item))
      commit('SET_PDF', list)
      EventBus.$emit('GERAR_PDF', true)
    } catch (error) {
      EventBus.$emit('GERAR_PDF', false)
    }

  },
}

const mutations = {
  SET_EMPREENDIMENTOS(state, empreendimentos) {
    state.all = empreendimentos
  },
  SET_ALL_EMPREENDIMENTOS(state, empreendimentos) {
    state.listAux = empreendimentos
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_PDF(state, pdf) {
    state.pdf = pdf
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
