/* eslint-disable */
import Vue from 'vue'
import crm from './modules/lancamento/crm'
import Vuex from 'vuex'
import lote from './modules/cadastro/lotes'
import home from './modules/home'
import geral from './modules/geral'
import token from './modules/token'
import caixa from './modules/cadastro/caixas'
import quadra from './modules/cadastro/quadras'
import arquivo from './modules/arquivo'
import empresa from "./modules/configuracao/empresas"
import cliente from './modules/cadastro/clientes'
import municipio from './modules/cadastro/municipios'
import corretora from './modules/cadastro/corretoras'
import contratos from './modules/lancamento/contratos'
import relatorio from './modules/relatorios/relatorio'
import funcionario from './modules/cadastro/funcionarios'
import evento_caixa from './modules/cadastro/eventos_caixa'
import conta_receber from './modules/lancamento/conta_receber'
import grupo_acesso from './modules/grupo_acesso'
import empreendimento from './modules/cadastro/empreendimentos'
import movimento_caixa from './modules/lancamento/movimento_caixa'
import aux_estado_civil from './modules/aux_estado_civil'
import modelo_documento from './modules/cadastro/modelos_documentos'
import aux_situacao from './modules/lancamento/aux_situacao'
import aux_motivo_contato from './modules/lancamento/aux_motivo_contato'
import configuracao from './modules/configuracao/configuracoes'
import logs_audit from './modules/LogsAudit'
import auditoria from './modules/configuracao/auditoria'
import emissao_boleto from './modules/mov_bancaria/emissao_boleto'
import geracao_remessa from "./modules/mov_bancaria/geracao_remessa";
import arquivo_retorno from "./modules/mov_bancaria/arquivo_retorno";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    crm,
    home,
    lote,
    geral,
    token,
    caixa,
    quadra,
    cliente,
    empresa,
    arquivo,
    municipio,
    corretora,
    contratos,
    relatorio,
    funcionario,
    grupo_acesso,
    configuracao,
    evento_caixa,
    conta_receber,
    empreendimento,
    movimento_caixa,
    aux_situacao,
    modelo_documento,
    aux_motivo_contato,
    aux_estado_civil,
    auditoria,
    logs_audit,
    emissao_boleto,
    arquivo_retorno,
    geracao_remessa
  }
})
