/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Lote {
  constructor () {


    this.lote_id = uuidV4()
    this.descricao = ''
    this.quadra_id = ''
    this.numero = ''
    this.tamanho = ''
    this.situacao_id = ''
    this.observacao = ''
    this.frente = ''
    this.fundos = ''
    this.lado_esq = ''
    this.lado_dir = ''

  }
}
