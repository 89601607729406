/* eslint-disable */
import {ClientePf} from "./cliente_pf";
import {ClientePj} from "./cliente_pj";
import { v4 as uuidV4 } from 'uuid';
export class Corretora {
  constructor () {


    this.corretora_id = uuidV4()
    this.nome = ''
    this.logrado = ''
    this.numero = ''
    this.complemento = ''
    this.bairro = ''
    this.municipio_id = ''
    this.cep = ''
    this.fone1 = ''
    this.fone2 = ''
    this.email = ''
    this.motivo_delete = ''

  }
}
