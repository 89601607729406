/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class EmpresaConfiguracoes {
  constructor (empresaId) {


    this.empresa_configuracao_id = uuidV4()
    this.empresa_id = empresaId
    this.dt_ultimo_processamento_ajuste = '' //'2019-05-02'
    this.dt_ultima_impressao_carne = '' //'2019-05-02'
    this.qtd_titulos_carne = ''
    this.dias_alerta_vencimento = ''
    this.dias_alerta_quitados = ''

  }
}
