/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";
export const  getAuditoriaSCH = (campo, valor, condicao)=>{
  var filtro = new Filtro()
  filtro.perPage = 1
  filtro.filters.addFilter(campo,valor,condicao)
  filtro.fields = ['*']
  return filtro
}


export const AuditoriaSCH = (perPage)=> {
  var filtro = new Filtro()
  filtro.perPage = perPage
  filtro.fields = ['acao', 'dt_fitragem', 'dt_log','hr_log','nome','rotina', 'funcionario_id', 'rotina_id']
  return filtro
}
