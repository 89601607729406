/* eslint-disable */
import {lista, pegaLista, processar, trataError} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {Filtro, Where} from "../../../models/filtro/filtro";
import {ModeloDocumento} from "../../../models/cadastros/modelo_documento";
import {getModeloDocumentoSCH, ModeloDocumentoSCH} from "../../../search/ModeloDocumentoSCH"

const url = '/modelo_documento'

const state = {
  all: [],
  allList: [],
  modelo_documento: new ModeloDocumento(),
  tipos: [
    {tp_doc: 1, descricao: 'Contrato'},
    {tp_doc: 2, descricao: 'Aditivo'},
    {tp_doc: 3, descricao: 'Recibos'},
    {tp_doc: 4, descricao: 'Distrato'},
    {tp_doc: 5, descricao: 'Acordo'},
    {tp_doc: 6, descricao: 'Anuencia'},
  ],
  filtro: new Filtro()
}

const getters = {
  listaAllModelos: (state) => {
    return state.allList
  },
  listaModelosDocumentos: (state) => {
    return state.all
  },
  pegaModeloById: (state) => (id) => {
    let mun = state.allList.find(modelo_documento => modelo_documento.modelo_id === id)
    return mun !== null && mun !== undefined ? mun.descricao : '** NÃO ENCONTRADO'
  },
  pegaModeloDocumentoId: (state) => (id) => {
    let mun = state.all.find(modelo_documento => modelo_documento.modelo_id === id)
    return mun !== null ? mun.descricao : '** NÃO ENCONTRADO'
  },
  pegaModeloDocumentoById: (state) => (id) => {
    let mun = state.all.find(modelo_documento => modelo_documento.modelo_id === id)
    return mun !== null ? mun : new ModeloDocumento()
  },
  pegaModeloDocumento: (state) => {
    return state.modelo_documento
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = []
    state.filtro.filters.addSearch(['descricao', 'tp_doc'],
      '%' + search + '%',
      'ilike'
    )
  },

  async setAllListModelos({commit}) {
    const list = (await lista(url + '/all')).data
    commit('SET_ALL_MODELOS', list)
  },

  async getItemModelo2({commit}, id) {
    var filtro = getModeloDocumentoSCH('modelo_id', id, '=')
    const list = (await pegaLista(url, filtro)).data
    commit('SET_MODELO', list.data[0])
    EventBus.$emit('GET_MODELO_IMPRIMIR', true)
  },

  async getItem({commit}, id) {
    var filtro = getModeloDocumentoSCH('modelo_id', id, '=')
    const list = (await pegaLista(url, filtro)).data
    commit('SET_MODELO', list.data[0])
    EventBus.$emit('GET_CONCLUIDO', true)
  },

  async getItemModelo({commit}, id) {
    var filtro = getModeloDocumentoSCH('modelo_id', id, '=')
    const list = (await pegaLista(url, filtro)).data
    commit('SET_MODELO', list.data[0])
    EventBus.$emit('GET_MODELO_CONCLUIDO', true)
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', ModeloDocumentoSCH(perPage))
  },

  async setAllModelosDocumentos({commit}, {onSucesso,onErro}) {
    try {
      const list = (await pegaLista(url, state.filtro)).data
      commit('SET_MODELOS_DOCUMENTOS', list)
      onSucesso()
    } catch (error) {
      onErro()
    }
  },
  async processarModeloDocumento({commit}, {acao,onSucesso,onErro} ) {
    try {
      let res = await processar(url, state.modelo_documento, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      this.dispatch('logs_audit/processarLog', {item: state.modelo_documento, key: 'modelo_id'})
      onSucesso()
      EventBus.$emit('PROCESSO_CONCLUIDO', true, true, 'ModeloDocumentos')

    } catch (error) {
      this.dispatch('geral/setObjMsgError', trataError(error))
      onErro()
      EventBus.$emit('PROCESSO_CONCLUIDO', false, true, 'ModelosCadastro')
    }
  },

  setModelo({commit}, modelo) {
    commit('SET_MODELO', modelo)
  }
}

const mutations = {
  SET_MODELOS_DOCUMENTOS(state, modelos_documentos) {
    state.all = modelos_documentos
  },
  SET_MODELO(state, modelo) {
    state.modelo_documento = modelo
  },
  SET_ALL_MODELOS(state, modelo) {
    state.allList = modelo
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
