/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";


export const  getContratoSCH = (campo, valor, condicao)=>{
  var filtro = new Filtro()
  filtro.perPage = 1
  filtro.filters.addFilter(campo,valor,condicao)
  filtro.fields = ['*']
  return filtro
}

export const setFiltroContratoSCH = (perPage, filtro)=> {
  filtro.perPage = perPage
  return filtro
}

export const ContratoSCH = (perPage)=> {
    var filtro = new Filtro()
    filtro.filters.addFilter('contratos_view.deleted',true,'<>')
    filtro.perPage = perPage
  return filtro
}
export const ContratoFinSCH = (perPage)=> {
    var filtro = new Filtro()
    filtro.perPage = perPage
  return filtro
}

export const ContratoDeletedSCH = (perPage)=> {
    var filtro = new Filtro()
    filtro.filters.addFilter('contratos_view.deleted',true,'=')
    filtro.perPage = perPage
  return filtro
}
