/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class LogAudit {
  constructor(rotina) {


    this.log_audit_id = uuidV4()
    this.usuario_id = sessionStorage.getItem("funcionario_id")
    this.rotina_id = sessionStorage.getItem("rotina_id") + rotina
    this.chave_registro = ''
    this.dados_registro = []

  }
}
