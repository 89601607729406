/* eslint-disable */
import {lista, processar, trataError} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {Empresa} from "../../../models/configuracao/empresa";

const url = '/empresa'

const state = {
  all: [],
  empresa: new Empresa()
}

const getters = {
  listaEmpresa: (state, getters, rootState) => {
    return state.all.filter(m => rootState.geral.search === '' ? true : m.nome.includes(rootState.geral.search))
  },
  listaEmpresaSemFiltro: (state) => {
    return state.all !== null && state.all !== undefined ? state.all : new Empresa()
  },
  pegaEmpresaDescricao: (state) => (id) => {
    let item = state.all.find(empresa => empresa.empresa_id === id)
    return item !== null ? item.nome : new Empresa()
  },
  pegaEmpresa: (state) => {
    return state.empresa
  },
  pegaQuantBoletos: (state) => {
    return state.empresa.empresa_configuracoes.qtd_titulos_carne
  }
}

const actions = {
  async setAllEmpresa({commit}) {
    const list = (await lista(url)).data
    commit('SET_EMPRESA', list[0])
  },
  async processarEmpresa({commit}, {empresa, acao}) {
    try {
      let res = await processar(url, empresa, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      this.dispatch('empresa/setAllEmpresa')
      this.dispatch('logs_audit/processarLog', {item: empresa, key: 'empresa_id'})

      EventBus.$emit('PROCESSO_CONCLUIDO', false, true, 'Empresa')
    } catch (error) {
      this.dispatch('geral/setObjMsgError', trataError(error))
      EventBus.$emit('PROCESSO_CONCLUIDO', false)

      EventBus.$emit('PROCESSO_CONCLUIDO', false, true, 'Empresa')
    }
  },
  setaImagens({commit}, imagens) {
    commit('SETA_IMAGENS', imagens)
  },

}

const mutations = {
  SET_EMPRESA(state, empresa) {
    state.all = empresa
    state.empresa = empresa
  },
  // IMAGENS
  SETA_IMAGENS(state, imagens) {
    state.all.imagens = imagens
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
