/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class MovimentoCaixa {
  constructor() {
    var array = ['E', 'S']

    this.mov_caixa_id = uuidV4()
    this.observacao = ''
    this.mov_eventos_caixa = []
    this.data = ''
    this.status = 'A'
    this.caixa_id = ''

    this.vl_receitas = 0
    this.vl_despesas = 0

  }
}
