/* eslint-disable */
import {GrupoAcessoPermissoes} from "./grupo_acesso_permissoes";
import { v4 as uuidV4 } from 'uuid';
export class GrupoAcesso {
  constructor () {


    this.grp_acesso_id = uuidV4()
    this.descricao = ''
    this.grupo_acessos_permissoes = []
  }
}
