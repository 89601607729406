/* eslint-disable */
export const acaoApi = Object.freeze({
  GRAVAR: 'gravar',
  REMOVER: 'remover',
  ESTORNAR: 'estornar',
  BAIXAR:'baixar',
  ISENTAR:'isentar',
  CANCELAR:'cancelar',
  LIMPAR_BOLETO:'limpar_boleto',
})
