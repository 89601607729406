/* eslint-disable */
import {geraUrlRelatorio, lista, pegaItem, pegaLista, processar, trataError} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {Quadra} from "../../../models/cadastros/quadra";
import {getQuadraSCH, QuadraSCH, setFiltroQuadraSCH} from "../../../search/QuadraSCH"
import {Filtro, Where} from "../../../models/filtro/filtro";

const url = '/quadra'

const state = {
  all: [],
  allList: [],
  filtro2: false,
  contfiltro: 0,
  filtro: new Filtro(),
  pdf: ''
}

const getters = {
  pegaPdf: (state) => {
    return state.pdf
  },
  listaQuadras: (state) => {
    return state.all
  },
  listaQuadrasSemFiltro: (state) => {
    return state.allList
  },
  pegaQuadraId: (state) => (id) => {
    let mun = state.allList.find(quadra => quadra.quadra_id === id)
    return mun !== null ? mun.descricao : '** NÃO ENCONTRADO'
  },
  pegaQuadra: (state) => (id) => {
    let mun = state.allList.find(quadra => quadra.quadra_id === id)
    return mun !== null ? mun : new Quadra()
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = []
    state.filtro.filters.addSearch(['quadras.descricao', 'quadras.numero',  'empreendimentos.nome'],
       '%'+search+'%',
        'ilike'
      )
  },
  async getItem({commit}, id) {
     var filtro = getQuadraSCH('quadra_id', id, '=')
     const list = (await pegaLista(url, filtro)).data

     EventBus.$emit('GET_QUADRA_CONCLUIDO', true, list.data[0])

  },
  setFiltro({commit}, perPage) {
     commit('SET_FILTRO', QuadraSCH(perPage))
  },

  setFiltroGet({commit}, {perPage, filtros}) {
     commit('SET_FILTRO', setFiltroQuadraSCH(perPage,filtros))
  },

  async setQuadrasByEmpreendimento({commit}, id) {
    const list = (await pegaItem(url+'/get_by_empreendimento', {empreendimento_id: id})).data
    commit('SET_ALL_QUADRAS', list)
    EventBus.$emit('GET_ALL_QUADRA', true)

  },
  async setAllQuadrasCompleto({commit}) {
    const list = (await lista(url+'/all')).data
    commit('SET_ALL_QUADRAS', list)
    EventBus.$emit('GET_ALL_QUADRA', true)
  },
  async setAllQuadras({commit}, {onSucesso,onErro}) {
    try {
     const list = (await pegaLista(url, state.filtro)).data
     commit('SET_QUADRAS', list)
      onSucesso()
    } catch (error) {
      onErro()
    }
  },
  async setAllQuadras2({commit}, {lista}) {
    commit('SET_QUADRAS', lista)
  },

  async filtrando({commit}, {filtro}) {
    commit('SET_FILTRO', filtro)
  },

  async processarQuadra({commit}, {quadra, acao, onSucesso, onErro}) {
    try {
      let res = await processar(url, quadra, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      this.dispatch('logs_audit/processarLog', {item: quadra, key: 'quadra_id'})
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', trataError(error))
      onErro()
    }
  },
  async GerarRelatorioPdf({commit}, item) {
    try {
      const list = (await geraUrlRelatorio(url+'/pdf', state.filtro))
      commit('SET_PDF', list)
      EventBus.$emit('GERAR_PDF', true)
    } catch (error) {
      EventBus.$emit('GERAR_PDF', false)
    }

  },
}

const mutations = {
  SET_ALL_QUADRAS(state, quadras) {
    state.allList = quadras
  },
  SET_QUADRAS(state, quadras) {
    state.all = quadras
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_PDF(state, pdf) {
    state.pdf = pdf
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
