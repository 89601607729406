/* eslint-disable */
import {geraUrlRelatorio, lista, pegaLista, processar, trataError} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {Corretora} from "../../../models/cadastros/corretora";
import {Filtro, Where} from "../../../models/filtro/filtro";
import {CorretoraSCH, getCorretoraSCH} from "../../../search/CorretoraSCH"

const url = '/corretora'

const state = {
  all: [],
  allCorretora: [],
  pdf: '',
  filtro: new Filtro()
}

const getters = {
  pegaPdf: (state) => {
    return state.pdf
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
  listaCorretoras: (state, getters, rootState, rootGetters) => {
    return state.all
  },
  listaAllCorretoras: (state) => {
    return state.allCorretora
  },
  listaCorretorasSemFiltro: (state) => {
    return state.all
  },
  pegaCorretoraId: (state) => (id) => {
    let mun = state.all.find(corretora => corretora.corretora_id === id)
    return mun !== null ? mun.descricao : '** NÃO ENCONTRADO'
  },
  pegaCorretora: (state) => (id) => {
    let mun = state.all.find(corretora => corretora.corretora_id === id)
    return mun !== null ? mun : new Corretora()
  },
}

const actions = {

  searchItem({commit}, search) {
    state.filtro.filters.search = []
    state.filtro.filters.addSearch(['corretoras.nome', 'cnpj_cpf',  'municipios.nome', 'fone1'],
      '%'+search+'%','ilike')
  },
  async getItem({commit}, id) {
    var filtro = getCorretoraSCH('corretora_id', id, '=')
    const list = (await pegaLista(url, filtro)).data
    EventBus.$emit('GET_CORRETORA_CONCLUIDO', true,  list.data[0])
  },
  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', CorretoraSCH(perPage))
  },

  async setAllListCorretoras ({ commit }) {
     const list = (await lista(url+'/all')).data
     commit('SET_ALL_CORRETORAS', list)
  },

  async setAllCorretoras ({ commit }, {onSucesso,onErro}) {
    try {
     const list = (await pegaLista(url, state.filtro)).data
     commit('SET_CORRETORAS', list)
      onSucesso()
    } catch (error) {
      onErro()
    }
  },
  async processarCorretora ({ commit }, { corretora, acao, onSucesso, onErro }) {
    try {
      let res = await processar(url, corretora, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      this.dispatch('logs_audit/processarLog', {item: corretora , key: 'corretora_id'})

      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', trataError(error))
      onErro()
    }
  },

  async GerarRelatorioPdf({commit}, item) {
    try {
      const list = (await geraUrlRelatorio(url+'/pdf', state.filtro))
      commit('SET_PDF', list)
      EventBus.$emit('GERAR_PDF', true)
    } catch (error) {
      EventBus.$emit('GERAR_PDF', false)
    }

  },

}

const mutations = {
  SET_CORRETORAS (state, corretoras) {
    state.all = corretoras
  },
  SET_ALL_CORRETORAS (state, corretoras) {
    state.allCorretora = corretoras
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_PDF(state, pdf) {
    state.pdf = pdf
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
