/* eslint-disable */
import Vue from 'vue'
import Router from 'vue-router'
import Dash from '../views/Dash.vue'
import Login from '../views/Login.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login
    },
    {
      path: '/admin',
      name: 'home',
      component: Dash,
      meta: 'Admin',
      children: [
        // PRINCIPAL
        {
          fullPath:'00',
          path: '/principal',
          name: 'Principal',
          meta: 'Principal',
          component: () => import(/* webpackChunkName: "about" */ '../views/Home/Home.vue')
        },
        // CADASTROS
        // CLIENTE
        {
          fullPath:'01.01.',
          path: '/cliente',
          name: 'Clientes',
          meta: 'Clientes',
          component: () => import(/* webpackChunkName: "about" */ '../views/Cadastro/cliente/ClienteLista.vue')
        },
        {
          fullPath:'01.01',
          path: '/clientes/cadastrar',
          name: 'ClienteCadastrar',
          meta: 'Clientes',
          component: () => import(/* webpackChunkName: "about" */ '../views/Cadastro/cliente/ClienteCadastro.vue')
        },
        // CORRETORA
        {
          fullPath:'01.03',
          path: '/corretora',
          name: 'Corretora',
          meta: 'Corretora',
          component: () => import(/* webpackChunkName: "about" */ '../views/Cadastro/corretora/CorretoraLista.vue')
        },
        // EMPREENDIMENTO
        {
          fullPath:'01.04',
          path: '/empreendimento',
          name: 'Empreendimento',
          meta: 'Empreendimento',
          component: () => import(/* webpackChunkName: "about" */ '../views/Cadastro/empreendimento/Empreendimento.vue')
        },


        // LOTE
        {
          fullPath:'01.06',
          path: '/lote',
          name: 'Lote',
          meta: 'Lote',
          component: () => import(/* webpackChunkName: "about" */ '../views/Cadastro/lote/Lote.vue')
        },        // MUNICIPIO
        {
          fullPath:'01.02',
          path: '/municipio',
          name: 'Município',
          meta: 'Município',
          component: () => import(/* webpackChunkName: "about" */ '../views/Cadastro/municipio/Municipio.vue')
        },
        // QUADRA
        {
          fullPath:'01.05',
          path: '/quadra',
          name: 'Quadra',
          meta: 'Quadra',
          component: () => import(/* webpackChunkName: "about" */ '../views/Cadastro/quadra/Quadra.vue')
        },
        // MODELOS DE DOCUMENTOS
        {
          fullPath:'01.07.',
          path: '/modelos-documento',
          name: 'ModeloDocumentos',
          meta: 'Modelos de Documentos',
          component: () => import(/* webpackChunkName: "about" */ '../views/Cadastro/modelos_documentos/ModelosDocumentos.vue')
        },
        {
          fullPath:'01.07',
          path: '/modelos-documentos/cadastrar',
          name: 'ModelosCadastro',
          meta: 'Modelos de Documentos',
          component: () => import(/* webpackChunkName: "about" */ '../views/Cadastro/modelos_documentos/ModelosCadastro.vue')
        },
        // USUARIOS / FUNCIONARIOS
        {
          fullPath:'01.08.',
          path: '/usuario-funcionario',
          name: 'UsuarioFuncionario',
          meta: 'Usuario/Funcionario',
          component: () => import(/* webpackChunkName: "about" */ '../views/Cadastro/usuario_funcionario/UsuarioFuncionario.vue')
        },

        // USUARIOS / FUNCIONARIOS
        {
          fullPath:'01.08',
          path: '/usuario-funcionario/cadastrar',
          name: 'UsuarioFuncionarioCadastrar',
          meta: 'Usuário/Funcionário',
          component: () => import(/* webpackChunkName: "about" */ '../views/Cadastro/usuario_funcionario/UsuarioCadastro.vue')
        },

        // CAIXAS
        {
          fullPath:'01.09',
          path: '/caixa',
          name: 'Caixas',
          meta: 'Caixas',
          component: () => import(/* webpackChunkName: "about" */ '../views/Cadastro/caixas/Caixas.vue')
        },
        // EVENTO CAIXA
        {
          fullPath:'01.10',
          path: '/evento-caixa',
          name: 'Evento Caixa',
          meta: 'Evento Caixa',
          component: () => import(/* webpackChunkName: "about" */ '../views/Cadastro/evento_caixa/EventoCaixaLista.vue')
        },

        // LANÇAMENTOS
        {
          fullPath:'02.01.',
          path: '/contrato',
          name: 'Contratos',
          meta: 'Contratos',
          component: () => import(/* webpackChunkName: "about" */ '../views/Lancamneto/contratos/Contratos.vue')
        },
        {
          fullPath:'02.01',
          path: '/contrato-cadastrar',
          name: 'ContratosCadastrar',
          meta: 'ContratosCadastrar',
          component: () => import(/* webpackChunkName: "about" */ '../views/Lancamneto/contratos/ContratoCadastrar.vue')
        },
        {
          fullPath:'02.02',
          path: '/contas-receber',
          name: 'ContasReceber',
          meta: 'Contas a receber',
          component: () => import(/* webpackChunkName: "about" */ '../views/Lancamneto/contas_receber/ContasReceber.vue')
        },
        {
          fullPath:'02.03',
          path: '/mov-caixa',
          name: 'MovimentosCaixa',
          meta: 'Movimentos Caixa',
          component: () => import(/* webpackChunkName: "about" */ '../views/Lancamneto/movimento_caixa/MovimentoCaixa.vue')
        },
        {
          fullPath:'02.04.',
          path: '/mov-evento-caixa',
          name: 'MovimentoEventoCaixa',
          meta: 'Movimento Evento Caixa',
          component: () => import(/* webpackChunkName: "about" */ '../views/Lancamneto/movimento_caixa/MovEventosCaixa.vue')
        },
        {
          fullPath:'02.04',
          path: '/crm',
          name: 'CRM',
          meta: 'CRM',
          component: () => import(/* webpackChunkName: "about" */ '../views/Lancamneto/crm/crm.vue')
        },
        // {
        //   fullPath:'02.05',
        //   path: '/multiplas_baixas',
        //   name: 'Multiplas Baixas',
        //   meta: 'Multiplas Baixas',
        //   component: () => import(/* webpackChunkName: "about" */ '../views/Lancamneto/multipas_baixas/MultiplasBaixas.vue')
        // },
        // MOVIMENTAÇÃO
        {
          path: '/baixa_arquivo',
          name: 'BaixaArquivoRetorno',
          meta: 'BaixaArquivoRetorno',
          component: () => import(/* webpackChunkName: "about" */ '../views/Movimentacao/baixa_arquivo_retorno/BaixaArquivoRetorno.vue')
        },
        {
          path: '/emissao_boleto',
          name: 'EmissaoBoleto',
          meta: 'EmissaoBoleto',
          component: () => import(/* webpackChunkName: "about" */ '../views/Movimentacao/emissao_boleto/EmissaoBoleto.vue')
        },
        {
          path: '/remessa',
          name: 'Remessa',
          meta: 'Remessa',
          component: () => import(/* webpackChunkName: "about" */ '../views/Movimentacao/remessa/Remessa.vue')
        },
        // CONFIGURAÇÕES
        {
          path: '/sobre',
          name: 'Sobre',
          meta: 'Sobre',
          component: () => import(/* webpackChunkName: "about" */ '../views/Configuracao/Sobre.vue')
        },
        {
          fullPath:'03.03',
          path: '/tema',
          name: 'Tema',
          meta: 'Tema',
          component: () => import(/* webpackChunkName: "about" */ '../views/Configuracao/Tema/Tema.vue')
        },
        {
          fullPath:'03.01',
          path: '/empresa',
          name: 'Empresa',
          meta: 'Empresa',
          component: () => import(/* webpackChunkName: "about" */ '../views/Configuracao/empresa/Empresa.vue')
        },
        {
          fullPath:'03.02',
          path: '/grupo_acesso',
          name: 'GrupoAcesso',
          meta: 'Grupo de Acesso',
          component: () => import(/* webpackChunkName: "about" */ '../views/Configuracao/grupo_acesso/GrupoAcesso.vue')
        },
        {
          fullPath:'03.04',
          path: '/auditoria',
          name: 'Auditoria',
          meta: 'Auditoria',
          component: () => import(/* webpackChunkName: "about" */ '../views/Configuracao/auditoria/Auditoria.vue')
        },
        {
          path: '/imprimir-boleto',
          name: 'Imprimir',
          component: () => import(/* webpackChunkName: "about" */ '../views/Lancamneto/ImpressaoBoleto.vue')
        },
        {
          path: '/financeiro',
          name: 'Financeiro',
          meta: 'Financeiro',
          component: () => import(/* webpackChunkName: "about" */ '../views/Relatorios/Financeiro.vue')
        },
        {
          path: '/extrato',
          name: 'Extrato',
          meta: 'Extrato',
          component: () => import(/* webpackChunkName: "about" */ '../views/Relatorios/Extrato.vue')
        },
        {
          path: '/cobranca',
          name: 'Cobrança',
          meta: 'Cobrança',
          component: () => import(/* webpackChunkName: "about" */ '../views/Relatorios/Cobranca.vue')
        },
        {
          path: '/comissao',
          meta: 'Comissão',
          name: 'Comissao',
          component: () => import(/* webpackChunkName: "about" */ '../views/Relatorios/Comissao.vue')
        },
        {
          path: '/envelopes',
          name: 'Envelopes',
          meta: 'Envelopes',
          component: () => import(/* webpackChunkName: "about" */ '../views/Relatorios/Envelopes.vue')
        }
      ]
    },
    {
      path: '/relatorio-cliente',
      name: 'RelatorioFichaCliente',
      meta: 'Relatorio Ficha Cliente',
      component: () => import(/* webpackChunkName: "about" */ '../components/relatorios/RelatorioFichaCliente')
    },

    {
      path: '*',
      name: '404',
      component: () => import(/* webpackChunkName: "about" */ '../views/404.vue')
    }
  ]
})
