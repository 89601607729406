/* eslint-disable */
import {GrupoAcesso} from "../../models/grupo_acesso";

const state = {
  show_home: false,
  disableFieldEmp: JSON.parse(sessionStorage.getItem('disableFieldEmp')) || false,
  disableField: JSON.parse(sessionStorage.getItem('disableField')) || false,
  editavel: JSON.parse(sessionStorage.getItem('editavel')) || false,
  adicionavel: JSON.parse( sessionStorage.getItem('adicionavel')) || false,
  removivel: JSON.parse(sessionStorage.getItem('removivel')) || false,
  consultavel:  JSON.parse(sessionStorage.getItem('consultavel')) || false,
  baixavel:  JSON.parse(sessionStorage.getItem('baixavel')) || false,
  estornavel:  JSON.parse(sessionStorage.getItem('estornavel')) || false,
  exc_especial:  JSON.parse(sessionStorage.getItem('exc_especial')) || false,
  btn_adicionavel: JSON.parse(sessionStorage.getItem('btn_adicionavel')) || false,
  arquivo_morto: false,
  show_arq_morto: false,
  btnAdd: false,
  btnPrint: false,
  modal: false,
  toolbarsearch: false,
  toolbarsave: false,
  toolbarCancel: false,
  sidebarId: 'principal',
  miniVariant: false,
  sidebarColor: 'rgba(0, 0, 0, 0.8)',
  modalfiltro: false,
  search: '',
  toolbarDash: null,
  dialogError: false,
  objMsgError: null,
  snackSuccess: false,
  btnDownload: false,
  btnFiltro: false,
  isLogado: false,
  tab: '',
  msgSuccess: '',
  chktema: true,
  swatches: '#000000',
  background: '',
  permissao_grupo_acesso: new GrupoAcesso()
}

const getters = {
  get_btn_adicionavel: state => {
    return state.btn_adicionavel
  },
  get_toolbarCancel: state => {
    return state.toolbarCancel
  },
  btnAdd: state => {
    return state.btnAdd
  },
  arquivo_morto: state => {
    return state.arquivo_morto
  },
  show_arq_morto: state => {
    return state.show_arq_morto
  },
  btnPrint: state => {
    return state.btnPrint
  },
  getShowHome: state => {
    return state.show_home
  },
  getDisableField: state => {
    return state.disableField
  },
  getDisableFieldEmp: state => {
    return state.disableFieldEmp
  },
  getSearch: state => {
    return state.search
  },
  getIsLogado: state => {
    return state.isLogado
  },
  pegarTab: state => {
    return state.tab
  },
  pegarEditavel: state => {
    return state.editavel
  },
  pegarAdicionavel: state => {
    return state.adicionavel
  },
  pegarRemovivel: state => {
    return state.removivel
  },
  pegarConsultavel: state => {
    return state.consultavel
  },
  pegarBaixavel: state => {
    return state.baixavel
  },
  pegarEstornavel: state => {
    return state.estornavel
  },
  pegarExcusaoEspecial: state => {
    return state.exc_especial
  },
  pegarPermissaoGrupoAcesso: state => {
    return state.permissao_grupo_acesso
  }
}

const actions = {
  setAdicionavel({ commit }, {valor, setAll}) {
    commit('SET_ADICIONAVEL', !valor)
    sessionStorage.setItem('adicionavel', !valor)
  },
  setBtnAdicionavel({ commit }, valor) {
    sessionStorage.setItem('btn_adicionavel', !valor)
    commit('SET_BTN_ADICIONAVEL', !valor)
  },
  setRemovivel({ commit }, {valor, setAll}) {
    commit('SET_REMOVIVEL', !valor)
    sessionStorage.setItem('removivel', !valor)
  },
  setConsultavel({ commit }, {valor, setAll}) {
    commit('SET_CONSULTAVEL', !valor)
    sessionStorage.setItem('consultavel', !valor)
  },

  setBaixavel({ commit }, valor) {
    commit('SET_BAIXAVEL', valor)
    sessionStorage.setItem('baixavel', valor)
  },
  setEstornavel({ commit }, valor) {
    commit('SET_ESTORNAVEL', valor)
    sessionStorage.setItem('estornavel', valor)
  },
  setExclusaoEspecial({ commit }, valor) {
    commit('SET_EXCLUSAO_ESPECIAL', valor)
    sessionStorage.setItem('exc_especial', valor)
  },
  setEditavel({ commit }, {valor, setAll}) {
    commit('SET_EDITAVEL', !valor)
    sessionStorage.setItem('editavel', !valor)
  },

  setBtnFiltro({ commit }, valor) {
    commit('SET_BTN_FILTRO', valor)
  },
  setShowHome({ commit }, valor) {
    commit('SET_SHOW_HOME', valor)
  },
  setDisableField({ commit }, valor) {
    sessionStorage.setItem('disableField', valor)
    commit('SET_DESABILITAR_CAMPO', valor)
  },
  setDisableFieldEmp({ commit }, valor) {
    sessionStorage.setItem('disableFieldEmp', valor)
    commit('SET_DESABILITAR_CAMPO_EMP', valor)
  },
  setAdd ({ commit }, valor) {
    commit('SET_ADD', valor)
  },
  toggleArquivoMorto ({ commit }) {
    commit('SET_ARQUIVO_MORTO', !state.arquivo_morto)
  },
  setArquivoMorto ({ commit }, valor) {
    commit('SET_ARQUIVO_MORTO', valor)
  },
  setShowArqMorto ({ commit }, valor) {
    commit('SET_SHOW_ARQ_MORTO', valor)
  },
  setPrint({ commit }, valor) {
    commit('SET_PRINT', valor)
  },
  setModal ({ commit }, valor) {
    commit('SET_MODAL', valor)
  },
  setToolbarSearch ({ commit }, valor) {
    commit('SET_TOOLBARSEARCH', valor)
  },
  setToolbarSave ({ commit }, valor) {
    commit('SET_TOOLBARSAVE', valor)
  },
  setToolbarCancel({ commit }, valor) {
    commit('SET_TOOLBARCANCEL', valor)
  },
  setSidebarID ({ commit }, valor) {
    commit('SET_SIDEBARID', valor)
  },
  setModalFiltro ({ commit }, valor) {
    commit('SET_MODALFILTRO', valor)
  },
  toggleModalFiltro ({ commit }) {
    commit('SET_MODALFILTRO', !state.modalfiltro)
  },
  setMiniVariant ({ commit }, valor) {
    commit('SET_MINIVARIANT', valor)
  },
  toggleMiniVariant ({ commit }) {
    commit('SET_MINIVARIANT', !state.miniVariant)
  },
  setSidebarColor ({ commit }, valor) {
    commit('SET_SIDEBARCOLOR', valor)
  },
  setSearch ({ commit }, valor) {
    commit('SET_SEARCH', valor)
  },
  setToolbarDash ({ commit }, valor) {
    commit('SET_TOOLBARDASH', valor)
  },
  toggleDialogError ({ commit }) {
    commit('SET_DIALOGERROR', !state.dialogError)
  },
  setObjMsgError ({ commit }, valor) {
    commit('SET_OBJMSGERROR', valor)
    commit('SET_DIALOGERROR', true)
  },
  toggleSnackSuccess ({ commit }) {
    commit('SET_SNACKSUCCESS', !state.snackSuccess)
  },
  setMsgSuccess ({ commit }, valor) {
    commit('SET_MSGSUCCESS', valor)
    commit('SET_SNACKSUCCESS', true)
  },
  setBtnDownload ({ commit }, valor) {
    commit('SET_BTNDOWNLOAD', valor)
  },
  setTab ({ commit }, valor) {
    commit('SET_TAB', valor)
  },
  setIsLogado ({ commit }, valor) {
    commit('SET_ISLOGADO', valor)
  },
  setPermissaoGrupoAcesso ({ commit }, valor) {
    commit('SET_PERMISSAO_GRUPO_ACESSO', valor)
  }
}

const mutations = {
  SET_EXCLUSAO_ESPECIAL(state, payload) {
    state.exc_especial = payload
  },
  SET_BTN_ADICIONAVEL(state, payload) {
    state.btn_adicionavel = payload
  },
  SET_ADICIONAVEL(state, payload) {
    state.adicionavel = payload
  },
  SET_SHOW_HOME(state, payload) {
    state.show_home = payload
  },
  SET_REMOVIVEL(state, payload) {
    state.removivel = payload
  },
  SET_CONSULTAVEL(state, payload) {
    state.consultavel = payload
  },
  SET_BAIXAVEL(state, payload) {
    state.baixavel= payload
  },
  SET_ESTORNAVEL(state, payload) {
    state.estornavel= payload
  },
  SET_EDITAVEL(state, payload) {
    state.editavel = payload
  },

  SET_BTN_FILTRO(state, payload) {
    state.btnFiltro = payload
  },
  SET_DESABILITAR_CAMPO (state, payload) {
    state.disableField = payload
  },
  SET_DESABILITAR_CAMPO_EMP (state, payload) {
    state.disableFieldEmp = payload
  },
  SET_ADD (state, payload) {
    state.btnAdd = payload
  },
  SET_ARQUIVO_MORTO (state, payload) {
    state.arquivo_morto = payload
  },
  SET_SHOW_ARQ_MORTO (state, payload) {
    state.show_arq_morto = payload
  },
  SET_PRINT (state, payload) {
    state.btnPrint = payload
  },
  SET_MODAL (state, payload) {
    state.modal = payload
  },
  SET_TOOLBARSEARCH (state, payload) {
    state.toolbarsearch = payload
  },
  SET_TOOLBARSAVE (state, payload) {
    state.toolbarsave = payload
  },
  SET_TOOLBARCANCEL(state, payload) {
    state.toolbarCancel = payload
  },
  SET_SIDEBARID (state, payload) {
    state.sidebarId = payload
  },
  SET_MODALFILTRO (state, payload) {
    state.modalfiltro = payload
  },
  SET_MINIVARIANT (state, payload) {
    state.miniVariant = payload
  },
  SET_SIDEBARCOLOR (state, payload) {
    state.sidebarColor = payload
  },
  SET_SEARCH (state, payload) {
    state.search = payload
  },
  SET_TOOLBARDASH (state, payload) {
    state.toolbarDash = payload
  },
  SET_DIALOGERROR (state, payload) {
    state.dialogError = payload
  },
  SET_OBJMSGERROR (state, payload) {
    state.objMsgError = payload
  },
  SET_SNACKSUCCESS (state, payload) {
    state.snackSuccess = payload
  },
  SET_MSGSUCCESS (state, payload) {
    state.msgSuccess = payload
  },
  SET_BTNDOWNLOAD (state, payload) {
    state.btnDownload = payload
  },
  SET_TAB (state, payload) {
    state.tab = payload
  },
  SET_ISLOGADO (state, payload) {
    state.isLogado = payload
  },
  SET_PERMISSAO_GRUPO_ACESSO (state, payload) {
    state.permissao_grupo_acesso = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
