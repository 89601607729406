/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
import {FuncionarioGrupoAcesso} from './funcionario_grupo_acesso'
export class Funcionario {
  constructor () {


    this.funcionario_id = uuidV4()
    this.nome = ''
    this.logrado = ''
    this.numero = ''
    this.complemento = ''
    this.bairro = ''
    this.municipio_id = ''
    this.cep = ''
    this.fone1 = ''
    this.fone2 = ''
    this.bloqueado = false
    this.motivo_delete = ''
    this.funcionarios_usuario_acesso = new FuncionarioGrupoAcesso(this.funcionario_id)
    this.funcionarios_acessibilidade = []
  }
}
