<template>
  <div class="app-login">
    <v-card flat>

      <div class="app-login topback" ></div>

      <v-row pb-2 align="center" justify="center">

        <v-col cols="12" sm="8" offset-sm2 align-self="center">
          <v-card class="card--flex-toolbar " style="background: #EEEEEE !important;">
            <v-card-text>
              <v-layout row wrap>
                <v-col cols="12" sm="12" md="6">
                  <div>
                    <div class="logo">
                      <img src="../assets/img/constrular2.webp" alt="" width="571" height="154" style="height: auto;width: 100%">
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <div class="formLogin">
                    <v-form v-model="valid">
                      <v-container>
                        <v-layout row wrap justify-center>
                          <h1 class="login">Entrar</h1>

                          <v-col cols="12">
                            <v-text-field name="usuario" dense outlined class="inp" v-model="user.username" label="Usuário"
                                          id="usuario" prepend-inner-icon="mdi-account"
                                          v-on:keyup="PressEnter(user.senha, user.username)" required></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field name="senha" dense outlined class="inp" v-model="user.senha" label="Senha"
                                          prepend-inner-icon="mdi-lock" type="password" id="senha"
                                          v-on:keyup="PressEnter(user.senha, user.username)" required></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <div style="text-align: right;">
                              <v-btn @click="efetuaLogin(user)" color="primary" :loading="spinner2">Entrar</v-btn>
                            </div>
                          </v-col>
                        </v-layout>
                      </v-container>
                    </v-form>
                  </div>
                </v-col>
              </v-layout>

            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <!-- Dialog que vai exibir a lista de erros caso aja uma validação -->
    <v-dialog v-model="dialogError" max-width="500">
      <v-card v-if="objMsgError !== null">
        <v-card-title class="headline">{{ objMsgError.message }}</v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <div v-if="objMsgError.details !== null">
            <ul :key="index" v-for="(message, index) in objMsgError.details">
              <li> {{ message.mensagem }}</li>
            </ul>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="toggleDialogError()"> Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-footer :fixed="fixed" app dark class="footerLogin">
      <v-col py-3 cols="12" style="text-align: center">
        <span style="font-size: 0.7rem">Copyrigth &copy; {{ new Date().getFullYear() }}  <a href="http://www.prodatanet.com.br/">Prodata Informática</a>. Todos os direitos reservados.</span>
      </v-col>
    </v-footer>
  </div>
</template>

<script async>
/* eslint-disable */
  import {EventBus} from '../helpers/event-bus'
  import {mapActions, mapState} from "vuex";
  import router from '../router/router'
  import {Tema} from "../models/configuracao/tema";

  export default {
    data: () => ({
      fixed: true,
      show1: false,
      user: [],
      spinner2: false,
      valid: false,
      name: '',
      nameRules: [
        v => !!v || 'Digite o usuario',
        v => v.length <= 1 || 'O nome deve ter mais de 1 letra'
      ],
      senha: '',
      senhaRules: [
        v => !!v || 'Digite a senha',
        v => v.length <= 1 || 'A senha deve ter mais de 1 letra'
      ]
    }),

    methods: {
      ...mapActions('funcionario', ['autenticarUsuario']),
      ...mapActions('geral', ['toggleDialogError', 'setIsLogado']),
      ...mapActions('configuracao', ['setTema']),
      zeroEsquerda(value, totalWidth, paddingChar) {
        var length = totalWidth - value.toString().length + 1
        return Array(length).join(paddingChar || '0') + value
      },
      retorno(item, termo, retorno1, retorno2) {
        return item === termo ? retorno1 : retorno2
      },
      validarCampo(campo) {
        if (campo !== undefined && campo !== null && campo !== '') {
          return true
        } else {
          return false
        }
      },

      efetuaLogin(usuario) {
        this.spinner2 = true
        var d = new Date();
        var hora = this.zeroEsquerda(d.getHours(), 2) + '' + this.zeroEsquerda(d.getMinutes(), 2)
        var semana = [
          {dia_semana: 1, descricao: 'Domingo'},
          {dia_semana: 2, descricao: 'Segunda-Feira'},
          {dia_semana: 3, descricao: 'Terça-Feira'},
          {dia_semana: 4, descricao: 'Quarta-Feira'},
          {dia_semana: 5, descricao: 'Quinta-Feira'},
          {dia_semana: 6, descricao: 'Sexta-Feira'},
          {dia_semana: 7, descricao: 'Sábado'},
        ]

        var dia_semana = semana[d.getDay()].dia_semana

        var array = {
          username: this.retorno(this.validarCampo(usuario.username), true, usuario.username, ""),
          senha: this.retorno(this.validarCampo(usuario.senha), true, usuario.senha, ""),
          acessibilidade: {dia: dia_semana, hora: hora}
        }
        this.autenticarUsuario(array)
      },
      PressEnter(senha, usuario) {
        var _this = this
        var user = document.getElementById('usuario')
        var senhaId = document.getElementById('senha')

        user.addEventListener('keypress', function (e) {
          if (e.keyCode === 13 || e.which === 13) {
            if (!_this.validarCampo(usuario)) {
              user.focus()
            } else if (!_this.validarCampo(senha)) {
              senhaId.focus()
            } else {
              _this.efetuaLogin(_this.user)
            }
          }
        }, false)
        senhaId.addEventListener('keypress', function (e) {
          if (e.keyCode === 13 || e.which === 13) {
            if (!_this.validarCampo(senha)) {
              senhaId.focus()
            } else {
              if (!_this.validarCampo(usuario)) {
                user.focus()
              } else {
                _this.efetuaLogin(_this.user)
              }
            }
          }
        }, false)
      },
    },
    computed: {
      ...mapState('geral', {
        dialogError: 'dialogError',
        objMsgError: 'objMsgError',
        snackSuccess: 'snackSuccess',
        msgSuccess: 'msgSuccess'
      }),
    },
    mounted() {
      var _this = this
      EventBus.$on('USUARIO_AUTENTICADO', (sucesso, usuario, nome, existe) => {
        this.spinner2 = false
        if (sucesso) {
          var inter = setInterval(function () {
            if (!existe) {
              var tema = new Tema(usuario.funcionario_id)
              tema.cor = '#000000'
              tema.opacidade = 0.8
              _this.setTema({valor: tema, nome: sessionStorage.getItem('funcionario_nome')})
            } else {
              _this.setTema({valor: usuario, nome: nome})
            }
            router.push({name: 'Principal'}).catch(err => {})
            clearInterval(inter)
          }, 1000)
          this.setIsLogado(true)
        }
      })

    }
  }
</script>
<style scoped>
  .topback {
    /*background: rgba(0, 0, 0, 0.7) !important;*/
    background: transparent!important;
    height: 300px !important;
  }

  .card--flex-toolbar {
    margin-top: -100px;
  }

  .formLogin {
    width: 90%;
    margin: 0 auto;
    padding: 2%;
  }

  .logo {
    text-align: center;
    /*padding-top: 2%;*/
  }

  .logo img {
    width: 70%;
  }

  .logoprod {
    text-align: right;
    padding-right: 3%;
  }

  .logoprod img {
    width: 10%;
  }

  .br {
    display: block;
  }

  @media screen and (min-width: 200px) and (max-width: 450px) {
    .card--flex-toolbar {
      margin-top: -200px !important;
    }

    .login {
      font-size: 1.5rem;
    }

    .br {
      display: none;
    }

    .logoprod {
      padding-right: 15%;
    }

    .logoprod img {
      width: 40%;
    }
  }

  @media screen and (min-width: 451px) and (max-width: 599px) {
    .card--flex-toolbar {
      margin-top: -190px !important;
    }

    .br {
      display: none;
    }

    .logoprod {
      padding-right: 10%;
    }

    .logoprod img {
      width: 30%;
    }
  }

  @media screen and (min-width: 600px) and (max-width: 959px) {
    .card--flex-toolbar {
      margin-top: -110px !important;
    }

    .logoprod {
      padding-right: 10%;
    }

    .logoprod img {
      width: 30%;
    }
  }

  @media screen and (min-width: 960px) and (max-width: 1600px) {
    .topback {
      height: 300px
    }

    .card--flex-toolbar {
      margin-top: -110px !important;
    }

    .logo {
      text-align: center;
      padding-top: 5%;
    }

    .logo img {
      width: 90%;
    }
  }

  @media screen and (min-width: 1601px) and (max-width: 1800px) {
    .topback {
      height: 300px
    }

    .card--flex-toolbar {
      margin-top: -110px !important;
    }

    .logo {
      text-align: center;
      padding-top: 10%;
    }

    .logo img {
      width: 90%;
    }
  }

  @media screen and (min-width: 1801px) and (max-width: 1919px) {
    .topback {
      height: 300px
    }

    .card--flex-toolbar {
      margin-top: -150px !important;
    }

    .logo {
      text-align: center;
      padding-top: 15%;
    }

    .logo img {
      width: 90%;
    }
  }

  @media screen and (min-width: 1920px) {
    .topback {
      height: 350px
    }

    .card--flex-toolbar {
      margin-top: -150px !important;
    }

    .logo {
      text-align: center;
      padding-top: 15%;
    }

    .logo img {
      width: 90%;
    }

    .logoprod {
      padding-right: 10%;
    }

    .logoprod img {
      width: 15%;
    }
  }


</style>
