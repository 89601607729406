<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <v-data-table   :headers="cabecalho" :items="itens" :page.sync="page" :items-per-page="itemsPerPage"
                  hide-default-footer class="elevation-0" @page-count="pageCount = $event" :dense="dense">

      <template v-slot:[`header.`+[n.value]]="{ header }" v-for="(n, index) in cabecalho" :id="index">
        {{ header.text.toUpperCase() }}
      </template>

      <template v-slot:[`item.`+[n.slot]]="{ item }" v-for="(n, index) in cabecalho" :id="index">
        <slot :name="n.slot" v-bind:item="item"></slot>
      </template>
      <template v-slot:[`item.actions`]="{item }">
        <div class="acao_tabela">
          <slot name="acao" v-bind:item="item"></slot>
        </div>
      </template>
      <template v-slot:[`item.expand`]="{item }">
        <v-btn icon color="primary" @click="expandir(item, item[chave])">
          <v-icon class="expand">mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <template v-slot:footer >
        <v-divider></v-divider>

        <v-container max-width="300">
          <div>
            <v-row dense style="align-items: flex-end;padding: 5px">
              <div>
                <v-text-field :value="itemsPerPage" label="Itens por pagina" type="number" persistent-hint min="1"
                              @input="itemsPerPage = parseInt($event, 10)"></v-text-field>
              </div>
              <v-spacer></v-spacer>
              <v-pagination v-model="page" :length="pageCount" :total-visible="7" :color="setLoadingColor('red')"></v-pagination>
              <v-spacer></v-spacer>
              <v-btn v-if="showAddButton" tile color="primary" @click="goToAdd" :disabled="disabledAddButton">
                <v-icon>mdi-plus</v-icon>
                NOVO
              </v-btn>
              <slot name="slot_footer"></slot>
            </v-row>
          </div>
        </v-container>
      </template>
    </v-data-table>
  </div>
</template>

<script async>
  /* eslint-disable */
  import {EventBus} from "../helpers/event-bus";
  import {mapGetters} from "vuex";

  export default {
    name: "SimpleTable",
    components: {},
    props: {
      cabecalho: {type: Array, default: () => []},
      itens: {type: Array, default: () => []},
      dense: {type: Boolean, default: false},
      showAddButton: {type: Boolean, default: true},
      disabledAddButton: {type: Boolean, default: false},
    },
    data: () => ({
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
    }),
    methods: {
      goToAdd() {
        EventBus.$emit('CLICK_BTN_ADD')
      },

      validarCampo(campo) {
        if (campo !== undefined && campo !== null && campo !== '') {
          return true
        } else {
          return false
        }
      },
      setLoadingColor(cor) {
        return this.validarCampo(this.tema) ? this.tema.cor : cor
      },
    },
    created() {
    },
    mounted() {
    },
    computed: {
      ...mapGetters('configuracao', {tema: 'pegarTema'}),
    },
    watch: {}
  }
</script>
