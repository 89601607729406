/* eslint-disable */
import {geraUrlRelatorio, pegaDados, processar, trataError} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {Crm} from "../../../models/lancamento/crm";
import {filtrarCrm} from '../../../helpers/search'

import {Filtro, Where} from "../../../models/filtro/filtro";
import {CrmSCH, getCrmSCH} from "../../../search/CrmSCH";

const url = '/crm'

const state = {
  pdf: '',
  all: [],
  contfiltro: 0,
  crm: new Crm(),
  filtro: new Filtro()
}

const getters = {
  pegaPdf: (state) => {
    return state.pdf
  },
  listaCrmsSemFiltro: (state) => {
    return state.all
  },
  pegaCrm: (state) => {
    return state.crm
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
}

const actions = {
  async getItem({commit}, id) {
    let filtro = getCrmSCH('contato_id', id, '=')
    const list = (await pegaDados(url+'/get', filtro))
    EventBus.$emit('GET_CRM_CONCLUIDO', true,  list.data.data[0])
  },
  searchItem({commit}, search) {
    state.filtro.filters.search = []
    state.filtro.filters.addSearch([
      'clientes.nome',
      'aux_motivos_contatos.descricao'
    ],'%'+search+'%','ilike')
  },

  setFiltro({commit}, perPage) {
     commit('SET_FILTRO', CrmSCH(perPage))
  },
  async setAllCrms({commit},{onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data
      commit('SET_CRMS', list)
      onSucesso()
    } catch (error) {
      onErro()
    }
  },

  setCrm({commit}, crm) {
    commit('SET_CRM', crm)
  },

  async processarCrm({commit}, {crm, acao, onSucesso, onErro}) {
    try {
      let res = await processar(url, crm, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      this.dispatch('logs_audit/processarLog', {item: crm, key: 'contato_id'})
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', trataError(error))
      onErro()
    }
  },
  async GerarRelatorioPdf({commit}, item) {
    try {
      const list = (await geraUrlRelatorio(url+'/pdf', state.filtro))
      commit('SET_PDF', list)
      EventBus.$emit('GERAR_PDF', true)
    } catch (error) {
      EventBus.$emit('GERAR_PDF', false)
    }

  },
}

const mutations = {
  SET_CRM(state, crm) {
    state.crm = crm
  },

  SET_CRMS(state, crms) {
    state.all = crms
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_PDF(state, pdf) {
    state.pdf = pdf
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
