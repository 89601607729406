/* eslint-disable */
const dadosGrafigoLine = [
  {name: 'Installation', data: [43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175]},
  {name: 'Manufacturing', data: [24916, 24064, 29742, 29851, 32490, 30282, 38121, 40434]},
  {name: 'Sales & Distribution', data: [11744, 17722, 16005, 19771, 20185, 24377, 32147, 39387]},
  {name: 'Project Development', data: [null, null, 7988, 12169, 15112, 22452, 34400, 34227]},
  {name: 'Other', data: [12908, 5948, 8105, 11248, 8989, 11816, 18274, 18111]}
]

const dadosGraficoColumm = [
  {
    name: "Browsers",
    colorByPoint: true,
    data: [
      {color: '#E91E63', name: "Empreendimentos", y: 50.74, drilldown: "Chrome"},
      {color: '#33691E', name: "Lotes", y: 15.57, drilldown: "Firefox"},
      {color: '#FFD600', name: "Clientes", y: 10.23, drilldown: "Internet Explorer"},
      {color: '#673AB7', name: "Contratos", y: 25.58, drilldown: "Safari"},
      {color: '#F44336', name: "Other", y: 7.62, drilldown: null}
    ]
  }
]

const dadosGraficoPie = [{
  name: 'Brands',
  colorByPoint: true,
  data: [
    {name: 'Empreendimentos', y: 61.41, sliced: true, selected: true},
    {name: 'Lotes', y: 11.84},
    {name: 'Clientes', y: 10.85},
    {name: 'Contratos', y: 4.67},
    {name: 'Other', y: 2.61}]
}]

const drilldownColumn = [
  {
    name: "Empreendimentos",
    id: "Empreendimentos",
    data: [["v65.0", 0.1], ["v64.0", 1.3], ["v63.0", 53.02], ["v62.0", 1.4], ["v61.0", 0.88], ["v60.0", 0.56], ["v59.0", 0.45], ["v58.0", 0.49], ["v57.0", 0.32], ["v56.0", 0.29], ["v55.0", 0.79], ["v54.0", 0.18], ["v51.0", 0.13], ["v49.0", 2.16], ["v48.0", 0.13], ["v47.0", 0.11], ["v43.0", 0.17], ["v29.0", 0.26]]
  }, {
    name: "Lotes",
    id: "Lotes",
    data: [["v58.0", 1.02], ["v57.0", 7.36], ["v56.0", 0.35], ["v55.0", 0.11], ["v54.0", 0.1], ["v52.0", 0.95], ["v51.0", 0.15], ["v50.0", 0.1], ["v48.0", 0.31], ["v47.0", 0.12]]
  }, {
    name: "Clientes",
    id: "Clientes",
    data: [["v11.0", 6.2], ["v10.0", 0.29], ["v9.0", 0.27], ["v8.0", 0.47]]
  }, {
    name: "Contratos",
    id: "Contratos",
    data: [["v11.0", 3.39], ["v10.1", 0.96], ["v10.0", 0.36], ["v9.1", 0.54], ["v9.0", 0.13], ["v5.1", 0.2]]
  },
  ]

export default {
  getGraficoLinha() {
    return dadosGrafigoLine
  },
  getGraficoColuna() {
    return dadosGraficoColumm
  },
  getGraficoPizza() {
    return dadosGraficoPie
  },
  getDrilldownColumn() {
    return drilldownColumn
  },
}
