/* eslint-disable */
import {lista, pegaTema, processar, trataError} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {Filtro, Where} from "../../../models/filtro/filtro";

const url = '/configuracao'

const state = {
  all: [],
  tema: null,
  filtro: new Filtro()
}

const getters = {
  pegarTema: state => {
    return state.tema
  },
}

const actions = {
  async setAllTema ({ commit }) {
    const list = await pegaTema('funcionario/tema', {funcionario_id: state.tema.funcionario_id})
    commit('SET_TEMA', list.data[0].original[0])
  },
  async setAllTemaByUser ({ commit }, {id}) {
    const list = await pegaTema('funcionario/tema', {funcionario_id: id})
    commit('SET_TEMA', list.data[0].original[0])
    EventBus.$emit('TEMA_CONCLUIDO', true)
    EventBus.$emit('TEMA_CARREGADO', true)
  },
  async setAllConfiguracao ({ commit }) {
    const list = (await lista(url)).data
    commit('SET_CONFIGURACOES', list)
  },

  async processarConfiguracao ({ commit }, { configuracao, acao }) {
    try {
      let res = await processar('funcionario/tema', configuracao, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)

      this.dispatch('configuracao/setAllTema')
      EventBus.$emit('PROCESSO_CONCLUIDO', true, true, 'Tema')
    } catch (error) {
      this.dispatch('geral/setObjMsgError', trataError(error))
      EventBus.$emit('PROCESSO_CONCLUIDO', false, true, 'Tema')
    }
  },
  setTema({ commit }, {valor, nome}) {
    commit('SET_TEMA', valor)
  },
  setTemaImagens({ commit }, valor) {
    commit('SET_TEMA_IMAGENS', valor)
  },
  setTemaUrlImagens({ commit }, valor) {
    commit('SET_TEMA_URLS_IMAGENS', valor)
  },
  searchItem({commit}, search) {
    state.filtro.filters.search = []
  },
}

const mutations = {
  SET_TEMA (state, payload) {
    state.tema = payload
  },
  SET_TEMA_IMAGENS (state, payload) {
    state.tema.imagens = payload
  },
  SET_TEMA_URLS_IMAGENS (state, payload) {
    state.tema.urls_imagem = payload
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
