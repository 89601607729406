<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <v-system-bar dark :height="30" window fixed id="system_bar">
      <v-icon>mdi-account-circle</v-icon>
      <span>{{ usuario}}</span>
      <v-spacer></v-spacer>
      <pro-timer :tempo="10" texto="Sua sessão expira em: "></pro-timer>
      <v-icon @click="sair()" style="margin-left: 1%" name="sair" aria-label="sair">mdi-exit-to-app</v-icon>
    </v-system-bar>

    <v-navigation-drawer style="top: 30px" width="260px" left class="testeHover" id="sidebar" ref="sideDrawer" stateless value="true" dark
                         persistent :mini-variant="miniVariant" :clipped="clipped" v-model="drawer"
                         enable-resize-watchers app>
      <v-list>
        <v-list-item id="iListItemMeta">
          <v-list-item-action @click.stop="abreFechaSidebar()" id="icnClick2">
            <v-btn text icon name="app_menu_btn" aria-label="btn_menu_lateral">
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </v-list-item-action>
          <v-list-item-title class="title">{{ $route.meta }}</v-list-item-title>
        </v-list-item>
      </v-list>

      <v-list dense style="background: transparent" id="listHover" class="listHover">
        <v-list>
          <v-list-item to="/principal" @click="irPara('principal')">
            <v-list-item-action>
              <v-icon>mdi-home</v-icon>
            </v-list-item-action>
            <v-list-item-title class="navmenu" style="font-size: 1.2rem; ">HOME</v-list-item-title>
          </v-list-item>
        </v-list>

        <!--CADASTROS-->
        <v-list-group prepend-icon="mdi-clipboard-text" no-action v-show="show_cadastros">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item>
                <v-list-item-title style="text-transform: uppercase">Cadastros</v-list-item-title>
              </v-list-item>
              <v-list-item-subtitle>
                Lotes, Clientes, Corretoras
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
          <v-list-item class="navmenu" v-for="cadastro in cadastros" :key="cadastro.title"
                       @click="irPara(cadastro.path, cadastro.rotina)" :id="cadastro.rotina" v-show="cadastro.visibile">

            <v-card class="dropnav" flat style="background: transparent" v-show="miniVariant">
              <v-list-item-title>
                {{ cadastro.title.substr(0, 3).toUpperCase() }}
              </v-list-item-title>
            </v-card>

            <v-list-item-content>
              <v-list-item-title :id="cadastro.path"><span class="lista_label">{{ cadastro.title }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!--LANÇAMENTOS-->
        <v-list-group prepend-icon="mdi-cash-100" no-action v-show="show_lancamentos">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item>
                <v-list-item-title style="text-transform: uppercase">Lançamentos</v-list-item-title>
              </v-list-item>
              <v-list-item-subtitle>
                Contratos, Caixa, CRM
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
          <v-list-item class="navmenu" v-for="lancamento in lancamentos" :key="lancamento.title"
                       @click="irPara(lancamento.path, lancamento.rotina)" :id="lancamento.rotina"
                       v-show="lancamento.visibile">

            <v-card class="dropnav" flat style="background: transparent"
                    v-show="miniVariant">
              <v-list-item-title>
                {{ lancamento.title.substr(0, 3).toUpperCase() }}
              </v-list-item-title>
            </v-card>

            <v-list-item-content>
              <v-list-item-title :id="lancamento.path"><span class="lista_label">{{ lancamento.title }}</span>
              </v-list-item-title>

            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group prepend-icon="mdi-bank" no-action v-show="show_mov_banc">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item>
                <v-list-item-title style="text-transform: uppercase">Mov. Bancária</v-list-item-title>
              </v-list-item>
              <v-list-item-subtitle>
                Boletos, Remessas, Retornos
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
          <v-list-item class="navmenu" v-for="mov_banc in movimentacao_bancaria" :key="mov_banc.title"
                       @click="irPara(mov_banc.path, mov_banc.rotina)" :id="mov_banc.rotina" v-show="mov_banc.visibile">

            <v-card class="dropnav" flat style="background: transparent"
                    v-show="miniVariant">
              <v-list-item-title>
                {{ mov_banc.title.substr(0, 3).toUpperCase() }}
              </v-list-item-title>
            </v-card>

            <v-list-item-content>
              <v-list-item-title :id="mov_banc.path"><span class="lista_label">{{ mov_banc.title }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

       <!--RELATORIOS-->
        <v-list-group prepend-icon="mdi-chart-box" no-action v-show="show_relatorio">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item>
                <v-list-item-title style="text-transform: uppercase">Relatórios</v-list-item-title>
              </v-list-item>
              <v-list-item-subtitle>
                Financeiro, Cobranças, Comissões
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
          <v-list-item class="navmenu" v-for="rel in relatorios" :key="rel.title"
                       @click="irPara(rel.path, rel.rotina)"  :id="rel.rotina"  v-show="rel.visibile">

            <v-card class="dropnav" flat style="background: transparent"
                    v-show="miniVariant">
              <v-list-item-title>
                {{ rel.title.substr(0, 3).toUpperCase() }}
              </v-list-item-title>
            </v-card>

            <v-list-item-content>
              <v-list-item-title :id="rel.path"><span class="lista_label">{{ rel.title }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!--CONFIGURAÇÕES-->
        <v-list-group prepend-icon="mdi-cog" no-action class="testeicon">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item>
                <v-list-item-title style="text-transform: uppercase">Configurações</v-list-item-title>
              </v-list-item>
              <v-list-item-subtitle>
                Acesso, Auditorias, Temas
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>

          <v-list-item class="navmenu" v-for="configuracao in configuracoes" :key="configuracao.title"
                       @click="irPara(configuracao.path, configuracao.rotina)" :id="configuracao.rotina"
                       v-show="configuracao.visibile">
            <v-card class="dropnav" flat style="background: transparent" v-show="miniVariant">

              <v-list-item-title>
                {{ configuracao.title.substr(0, 3).toUpperCase() }}
              </v-list-item-title>
            </v-card>

            <v-list-item-content>
              <v-list-item-title :id="configuracao.path"><span class="lista_label">{{ configuracao.title }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="navmenu" to="/sobre">
            <v-card class="dropnav" flat style="background: transparent" v-show="miniVariant">
              <v-list-item-title>
                SOB
              </v-list-item-title>
            </v-card>

            <v-list-item-content>
              <v-list-item-title id="/sobre"><span class="lista_label">Sobre o sistema</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

      </v-list>
      <br> <br>
    </v-navigation-drawer>

    <v-app-bar id="toobarDash" :clipped-left="clipped" app dark style="top: 30px; z-index: 8!important;">

      <v-app-bar-nav-icon v-show="bntTop" @click.stop="drawer = !drawer" name="app_menu"></v-app-bar-nav-icon>
      <v-toolbar-title v-show="bntTop">{{ $route.meta }}</v-toolbar-title>
      <!--SEARCH-->
      <div style="width: 50%" v-show="toolbarsearch" class="toolbarSearch">
        <v-text-field :value="pesquisando" @change="v => pesquisando = v" flat solo-inverted prepend-inner-icon="mdi-magnify"
                      label="Pesquisar" class="hidden-sm-and-down inp ">

          <template v-slot:append v-if="btnFiltro">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" icon v-on="on" @click="abreModalFiltro()">
                  <v-icon @click:append="abreModalFiltro()"> mdi-filter</v-icon>
                </v-btn>
              </template>
              <span>Fitrar</span>
            </v-tooltip>
          </template>

        </v-text-field>
      </div>

      <!--ARQQUIVO MORTO-->
      <div v-if="show_arq_morto" style="margin: 10px; width: 50px; height: 30px">
        <v-tooltip right>
          <template v-slot:activator="{ on }">
           <div v-on="on" >
             <v-switch color="primary" v-model="ArquivMorto"></v-switch>
           </div>
          </template>
          <span>Arquivo Morto</span>
        </v-tooltip>
      </div>

      <v-spacer></v-spacer>

      <v-btn style="right: 1%;" dark icon v-show="toolbarCancel" @click="executaCancelBtn">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-btn style="right: 1%;" dark icon v-show="toolbarsave" @click="executaCancelBtn">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-btn style="right: 1%;" dark icon v-show="toolbarsave" :loading="show_progress" :disabled="btn_adicionavel"
             @click="executaSaveBtn">
        <v-icon>mdi-check</v-icon>
      </v-btn>

      <v-btn icon color="white" @click="executaPrintBtn"  v-show="btnPrint">
        <v-icon>mdi-printer</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main fluid id="conteudo">
      <v-container fluid style="position: relative !important; ">
        <br>
        <router-view/>
        <br>
      </v-container>
    </v-main>

    <v-navigation-drawer v-if="btnAdd" :clipped="clipped" clipped-right dark v-model="drawerRight" right enable-resize-watchers
                         stateless persistent app id="sidebarRight" width="60px" style="top: 30px">
      <v-list style="background: transparent" class="drawbtn" v-show="btnPrint">
        <v-btn icon color="white" @click="executaPrintBtn">
          <v-icon>mdi-printer</v-icon>
        </v-btn>
      </v-list>

      <div class="drawrightbtn" v-show="btnAdd">
        <v-btn small fab color="green darken-2" @click="executaAddBtn" :disabled="btn_adicionavel">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
    </v-navigation-drawer>

    <!-- Dialog que vai exibir a lista de erros caso aja uma validação -->
    <pro-modal-cadastro :modal="dialogError" width="40%" :titulo="objMsgError !== null ? objMsgError.message : ''">
      <div v-if="objMsgError !== null">
        <div v-if="objMsgError.details !== null" style="padding: 15px">
          <ul :key="index" v-for="(message, index) in objMsgError.details" style="text-align: left">
            <li> {{ message.mensagem }}</li>
          </ul>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="toggleDialogError()">
            Fechar
          </v-btn>
        </v-card-actions>
      </div>
    </pro-modal-cadastro>

    <!-- Popup que vai exibir mensagens de sucesso -->
    <v-snackbar v-model="showSnack" top center color="success" :timeout="2000">
      {{ msgSuccess }}
      <v-btn small color="white" text @click="toggleSnackSuccess">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script async>
/* eslint-disable */
import util from '../assets/js/Util'
import {mapState, mapActions, mapGetters} from 'vuex'
import {EventBus} from '../helpers/event-bus'
import router from '../router/router'
import miscMixin from '../helpers/misc'
import miscImageCropper from '../helpers/img-cropper'
import acessoHlp from '../helpers/acessoHLP'

  export default {
    mixins: [miscMixin, miscImageCropper, acessoHlp],
    components: {
      ProTimer: () => import('../components/Temporizador.vue'),
  },
    data() {
      return {
        acessibilidade: [],
        usuario: '',
        bntTop: false,
        desabilitarAdd: false,
        bntTop2: true,
        drawerRight: true,
        icon: 'keyboard_arrow_left',
        drawerR: true,
        clipped: false,
        drawer: true,
        fixed: false,
        show_lancamentos: false,
        show_mov_banc: false,
        show_relatorio: false,
        show_configuracoes: false,
        show_cadastros: false,
        title: 'Titulo',
        movimentacao_bancaria: [
          {path: '/emissao_boleto', title: 'Emissão de Boletos', icon: 'home', rotina: '04.01', visibile: false},
          {path: '/remessa', title: 'Geração de remessas', icon: 'home', rotina: '04.02', visibile: false},
          {path: '/baixa_arquivo', title: 'Baixa de retorno', icon: 'home', rotina: '04.03', visibile: false},
          ],
        principal: [{path: '/principal', title: 'Principal', icon: 'home'}],
        relatorios: [
          {path: '/financeiro', title: 'Financeiro', icon: 'home', rotina: '05.01', visibile: false},
          {path: '/extrato', title: 'Extrato', icon: 'home', rotina: '05.02', visibile: false},
          {path: '/cobranca', title: 'Cobrança', icon: 'home', rotina: '05.03', visibile: false},
          {path: '/comissao', title: 'Comissão', icon: 'home', rotina: '05.04', visibile: false},
          {path: '/envelopes', title: 'Envelopes', icon: 'home', rotina: '05.05', visibile: false},
          ],
        cadastros: [
          {path: '/cliente', title: 'Clientes', icon: 'group', rotina: '01.01', visibile: false, setAll: 'cliente/setAllClientes'},
          {path: '/municipio', title: 'Municípios', icon: 'map', rotina: '01.02', visibile: false, setAll: 'municipio/setAllMunicipios'},
          {path: '/corretora', title: 'Corretoras', icon: 'business', rotina: '01.03', visibile: false, setAll: 'corretora/setAllCorretoras'},
          {path: '/empreendimento', title: 'Empreendimentos', icon: 'shop_two', rotina: '01.04', visibile: false, setAll: 'empreendimento/setAllEmpreendimentos'},
          {path: '/quadra', title: 'Quadras', icon: 'beenhere', rotina: '01.05', visibile: false, setAll: 'quadra/setAllQuadras'},
          {path: '/lote', title: 'Lotes', icon: 'place', rotina: '01.06', visibile: false, setAll: 'lote/setAllLotes'},
          {path: '/modelos-documento', title: 'Modelos de Documentos', icon: 'place', rotina: '01.07', visibile: false, setAll: 'modelo_documento/setAllModelosDocumentos'},
          {path: '/usuario-funcionario', title: 'Usuários/Funcionários', icon: 'place', rotina: '01.08', visibile: false, setAll: 'funcionario/setAllFuncionarios'},
          {path: '/caixa', title: 'Caixas', icon: 'place', rotina: '01.09', visibile: false, setAll: 'caixa/setAllCaixas'},
          {path: '/evento-caixa', title: 'Eventos de Caixa', icon: 'move_to_inbox', rotina: '01.10', visibile: false, setAll: 'evento_caixa/setAllEventoCaixas'}
        ],
        lancamentos: [
          {path: '/contrato', title: 'Contratos', icon: 'home', rotina: '02.01', visibile: false, setAll: 'contratos/setAllContratos'},
          {path: '/contas-receber', title: 'Contas a receber', icon: 'home', rotina: '02.02', visibile: false, setAll: 'conta_receber/setAllContaReceber'},
          {path: '/mov-caixa', title: 'Movimentos Caixa', icon: 'home', rotina: '02.03', visibile: false, setAll: 'movimento_caixa/setAllMovimentoCaixas'},
          {path: '/crm', title: 'CRM', icon: 'home', rotina: '02.04', visibile: false, setAll: 'crm/setAllCrms'}
        ],
        configuracoes: [
          {path: '/empresa', title: 'Empresa', icon: 'build', rotina: '03.01', visibile: false, setAll: 'empresa/setAllEmpresa'},
          {path: '/grupo_acesso', title: 'Grupo de Acesso', icon: 'group', rotina: '03.02', visibile: false, setAll: 'grupo_acesso/setAllGrupoAcessos'},
          {path: '/auditoria', title: 'Auditoria', icon: 'add_shopping_cart', rotina: '03.03', visibile: false, setAll: 'auditoria/setAllAuditoria'},
          {path: '/tema', title: 'Tema', icon: 'image', rotina: '03.04', visibile: false, setAll: 'configuracao/setAllTema'}
        ],
        miniVariant2: false,
        show_progress: false,
        right: null
      }
    },
    name: 'Dash',
    methods: {
      ...mapActions('token', ['start', 'cancelarToken']),
      ...mapActions('geral', ['setMiniVariant', 'toggleMiniVariant', 'setSidebarColor', 'setSidebarID', 'setModalFiltro',
        'setModal', 'toggleModalFiltro','setBtnAdicionavel', 'setSearch', 'toggleDialogError', 'toggleSnackSuccess', 'setBtnFiltro',
        'setEditavel', 'setAdicionavel', 'setRemovivel', 'setConsultavel','setBaixavel','setEstornavel', 'setShowHome', 'setDisableField',
        'setPermissaoGrupoAcesso', 'toggleArquivoMorto', 'setShowArqMorto','setExclusaoEspecial']),
      ...mapActions('configuracao', ['setAllTemaByUser']),
      ...mapActions('grupo_acesso', ['getGrupoAcesso']),
      ...mapActions('logs_audit', ['setLog']),
      sair() {
        this.cancelarToken()
        sessionStorage.clear()
        router.push({path: '/'}).catch(err => {})
      },
      mobileBtn() {
        var width = window.screen.width
        if (width <= 1024) {
          this.bntTop2 = false
          return true
        } else {
          this.bntTop2 = true
          return false
        }
      },
      irPara(id, rotina) {
        if (rotina !== undefined) {
          this.habilitarAdd(rotina)
        }
        this.setSearch('')
        this.setSidebarID(id)
        this.setModalFiltro(false)
        let tinycolor = require('tinycolor2')
        let color
        let c
        if (util.validaCampo(this.sidebarColor)) {
          if (this.sidebarColor === '#000000') {
            c = '#1d94ff'
          } else {
            color = this.sidebarColor
            var colors = tinycolor(color).triad()
            colors = colors.map(function (t) {
              return t.toHexString()
            })
            c = colors[1]
            c = tinycolor(c).darken().toString()
          }
        } else {
          c = '#1d94ff'
        }

      let teste = document.getElementsByClassName('testeHover')
      let listtile = teste[0].getElementsByTagName('div')
      for (let i = 0; i < listtile.length; i++) {
        if (util.validaCampo(listtile[i].id)) {
          if (listtile[i].id.toString() !== id.toString()) {
            listtile[i].style.color = 'white'
          } else {
            listtile[i].style.color = c
          }
        }
      }

      var inter = setInterval(function() {
        router.push({path: id}).catch(err => {
        })
        clearInterval(inter)
      }, 500)
    },
    trocaIncon(drawerRight) {
      this.drawerRight = !this.drawerRight
      this.icon = drawerRight ? 'keyboard_arrow_right' : 'keyboard_arrow_left'
    },
    abreModalFiltro() {
      this.toggleModalFiltro()

      const sectionQuadra = document.getElementById('sectionQuadra')
      const sectionLote = document.getElementById('sectionLote')
      const sectionEventoCaixa = document.getElementById('sectionEventoCaixa')

      if (sectionQuadra !== null) {
        sectionQuadra.style.animation = ''
        if (this.modalfiltro) {
          sectionQuadra.style.animation = 'fadeInDown2 0.5s linear'
        }
      }
      if (sectionLote !== null) {
        sectionLote.style.animation = ''
        if (this.modalfiltro) {
          sectionLote.style.animation = 'fadeInDown2 0.5s linear'
        }
      }
      if (sectionEventoCaixa !== null) {
        sectionEventoCaixa.style.animation = ''
        if (this.modalfiltro) {
          sectionEventoCaixa.style.animation = 'fadeInDown2 0.5s linear'
        }
      }
    },
    abreFechaSidebar() {
      this.toggleMiniVariant()
    },
    executaAddBtn(event) {
      EventBus.$emit('CLICK_BTN_ADD')
    },
    executaPrintBtn(event) {
      EventBus.$emit('CLICK_BTN_PRINT')
    },
    executaArquivoMorto(event) {
        EventBus.$emit('ARQUIVO_MORTO')
      },
      executaSaveBtn(event) {
        this.show_progress = true
        EventBus.$emit('CLICK_BTN_SAVE')
      },
      executaCancelBtn(event) {
        EventBus.$emit('CLICK_BTN_CANCEL')
      },
      setBacground() {
        var url = ''
        var cont = 0
        if (this.validarCampo(this.tema)) {
          if (this.tema.urls_imagem.length > 0) {
            this.tema.urls_imagem.forEach(i => {
              if (i.capa) {
                cont++
              }
            })
            if (cont > 0) {
              url = this.selecionaImagem(this.tema.urls_imagem, '1600')
            }
          }
        } else {
          url = 'https://picsum.photos/1600/900?random.jpg'
      }
      return url
    },
    getPermissao(achou, rotina) {
      var permissao = achou.filter(lista => lista.rotina_id.substring(6, 8) === rotina)[0]
      permissao = permissao === undefined ? false : permissao.acessivel
      return permissao
    },

    habilitarAdd(rotina) {
      let list = []
      if (rotina.substring(0, 2) === '01') {
        list = this.cadastros.filter(lista => lista.rotina === rotina)[0]
      } else if (rotina.substring(0, 2) === '02') {
        list = this.lancamentos.filter(lista => lista.rotina === rotina)[0]
      } else if (rotina.substring(0, 2) === '03') {
        list = this.configuracoes.filter(lista => lista.rotina === rotina)[0]
      } else if (rotina.substring(0, 2) === '04') {
        list = this.movimentacao_bancaria.filter(lista => lista.rotina === rotina)[0]
      } else if (rotina.substring(0, 2) === '05') {
        list = this.relatorios.filter(lista => lista.rotina === rotina)[0]
      }

      var achou = this.acessibilidade.grupo_acessos_permissoes.filter(lista => lista.rotina_id.substring(0, 5) === rotina)

      sessionStorage.setItem('rotina_id', rotina)

      if (achou.length > 0) {
        this.setSearch('')

        this.setBtnAdicionavel(this.getPermissao(achou, '01'))

        this.setAdicionavel({
          valor: this.getPermissao(achou, '01'),
          setAll: list.setAll
        })

        this.setEditavel({
          valor: this.getPermissao(achou, '02'),
          setAll: list.setAll
        })

        this.setRemovivel({
          valor: this.getPermissao(achou, '03'),
          setAll: list.setAll
        })

        this.setConsultavel({
          valor: this.getPermissao(achou, '04'),
          setAll: list.setAll
        })

        this.setBaixavel(this.getPermissao(achou, '05'))
        this.setEstornavel(this.getPermissao(achou, '06'))
        this.setExclusaoEspecial(this.getPermissao(achou, '09'))

        this.desabilitarAdd = achou.acessivel

      } else {
        console.log('Não se aplica ')
      }
    },
    percorrerMenu(lista, rotina) {
      if (rotina.substring(0, 2) === '01') {
        this.show_cadastros = true

      } else if (rotina.substring(0, 2) === '02') {
        this.show_lancamentos = true
      } else if (rotina.substring(0, 2) === '04') {
        this.show_mov_banc = true
      }
      else if (rotina.substring(0, 2) === '05') {
        this.show_relatorio= true
      }


      var achou = lista.filter(lista => lista.rotina === rotina)[0]
      if (achou) {
        let pos = lista.indexOf(achou)
        var change = achou
        change.visibile = true
        lista.splice(pos, 1, change)
      }
    },
    menu_acessivel() {
      var _this = this

      this.getGrupoAcesso(sessionStorage.getItem('grp_acesso_id'))

      EventBus.$on('GET_GRUPO_ACESSO_CONCLUIDO', (sucesso, lista) => {
        if (sucesso) {
          this.setPermissaoGrupoAcesso(lista)
          this.acessibilidade = lista
          if (lista.grupo_acessos_permissoes.length > 0) {
            let array = []

            // Cria array com as rotinas
            lista.grupo_acessos_permissoes.forEach(function(gp) {
              array.push(gp.rotina_id.substring(0, 5))
            })

            // Apaga registros duplicados
            var novaArr = array.filter(function(este, i) {
              return array.indexOf(este) === i
            })

            // Torna visivel as rotinas permitidas
            novaArr.forEach(function(gp) {
              if (gp === '00') {
                sessionStorage.setItem('setShowHome', true)
              }
              _this.percorrerMenu(_this.cadastros, gp)
              _this.percorrerMenu(_this.lancamentos, gp)
              _this.percorrerMenu(_this.configuracoes, gp)
              _this.percorrerMenu(_this.movimentacao_bancaria, gp)
              _this.percorrerMenu(_this.relatorios, gp)
            })
            _this.setShowHome(JSON.parse(sessionStorage.getItem('setShowHome')) || false)
          }
        }
      })
    }
  },
  created() {
    this.menu_acessivel()
  },
  mounted() {
    var inspireInicio = document.getElementById('inspireInicio')

    inspireInicio.style.backgroundRepeat = 'no-repeat'
    inspireInicio.style.backgroundAttachment = 'fixed'
    inspireInicio.style.backgroundSize = '100% 100%'

    this.bntTop = this.mobileBtn()
    var _this = this

    if (sessionStorage.getItem('funcionario_id') !== null) {
      this.usuario = sessionStorage.getItem('funcionario_nome')

      if (!this.validarCampo(this.tema)) {
        this.setAllTemaByUser({id: sessionStorage.getItem('funcionario_id')})

        EventBus.$on('TEMA_CONCLUIDO', (sucesso) => {
          if (sucesso) {
            this.nova_configuracao(this.tema)
            inspireInicio.style.backgroundImage = 'url(' + this.setBacground() + ')'
          }
        })
      } else {
        inspireInicio.style.backgroundImage = 'url(' + this.setBacground() + ')'
        this.nova_configuracao(this.tema)
      }
    } else {
      router.push({path: '/'}).catch(err => {
      })
    }
    let item = document.getElementById('sidebar')

    function func() {
      if (_this.miniVariant2) {
        _this.setMiniVariant(false)
      }
    }

    function func1() {
      if (_this.miniVariant2) {
        _this.setMiniVariant(true)
      }
    }

    let icnClick2 = document.getElementById('icnClick2')
    icnClick2.addEventListener('click', function(event) {
      if (!_this.miniVariant2) {
        _this.miniVariant2 = true
        item.addEventListener('mouseover', func, false)
        item.addEventListener('mouseout', func1, false)
      } else {
        _this.miniVariant2 = false
        _this.setMiniVariant(false)
      }
    }, false)

    EventBus.$on('PROCESSO_CONCLUIDO', (sucesso, fecha, path) => {
      this.show_progress = false
      if (sucesso && fecha) {
        router.push({name: path}).catch(err => {
        })
      }
    })
  },
  computed: {
    ...mapState('token', { iniciado: 'iniciado' }),
    ...mapGetters('configuracao', {tema: 'pegarTema'}),
    ...mapGetters('geral', {
      adicionavel: 'pegarAdicionavel',
      btn_adicionavel: 'get_btn_adicionavel',
    }),
    ...mapState('geral', {
      disableField: 'disableField',
      search: 'search',
      toolbarCancel: 'toolbarCancel',
      toolbarsave: 'toolbarsave',
      toolbarsearch: 'toolbarsearch',
      btnAdd: 'btnAdd',
      btnPrint: 'btnPrint',
      miniVariant: 'miniVariant',
      sidebarColor: 'sidebarColor',
      modalFiltro: 'modalFiltro',
      toolbarDash: 'toolbarDash',
      dialogError: 'dialogError',
      objMsgError: 'objMsgError',
      snackSuccess: 'snackSuccess',
      btnFiltro: 'btnFiltro',
      arquivo_morto: 'arquivo_morto',
        show_arq_morto: 'show_arq_morto',
        msgSuccess: 'msgSuccess'
      }),
      pesquisando: {
        get() {
          return this.search
        },
        set(val) {
          this.setSearch(val)
        }
      },
      showSnack: {
        get() {
          return this.snackSuccess
        },
        set() {
          this.toggleSnackSuccess()
        }
      },
      ArquivMorto: {
        get() {
          return this.arquivo_morto
        },
        set() {
          this.toggleArquivoMorto()
        }
      }
    },
    watch:  {
      ArquivMorto: function () {
        this.executaArquivoMorto()
      }
    },
    beforeDestroy () {
      EventBus.$off('CLICK_BTN_ADD')
      EventBus.$off('CLICK_BTN_SAVE')
      EventBus.$off('CLICK_BTN_CANCEL')
      EventBus.$off('PROCESSO_CONCLUIDO')
      EventBus.$off('ARQUIVO_MORTO')
  }
}
</script>
<style lang="scss">

#iListItemMeta {
  margin-left: -5px !important;
  margin-right: 0 !important;
}

#iListItemMeta .v-list-item__action {
  margin-right: 14px !important;
}

#sidebarRight .drawbtn {
  justify-content: center !important;
}

#sidebarRight .drawrightbtn {
  justify-content: center !important;
  margin-right: 2px;
}

.v-navigation-drawer .navmenu {
  padding-left: 56px !important;
}

.v-navigation-drawer .v-list-item .v-btn__content {
  padding-left: 0 !important;
  margin-left: 0 !important;
}

.v-navigation-drawer .v-list-item__action {
  margin-right: 15px !important;
  padding-right: 0 !important;
}

.v-navigation-drawer .v-list-item__subtitle {
  padding-left: 16px !important;
  font-size: 8pt !important;
}

.v-navigation-drawer .v-list-item__title {
  margin-left: 0 !important;
  padding-left: 0 !important;
}

.v-navigation-drawer .v-list-item__content {
  margin-left: 0 !important;
  padding-left: 0 !important;
}

.v-list-item__subtitle {

}
.v-navigation-drawer .v-list-item__icon {
  margin-right: 0 !important;
  padding-right: 0 !important;
  justify-content: center !important;
  align-self: center;
}
</style>
