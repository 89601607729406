/* eslint-disable */
import {geraUrlRelatorio, lista, pegaLista, processar, trataError} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {Municipio} from "../../../models/cadastros/municipio";
import {Filtro, Where} from "../../../models/filtro/filtro";
import {MunicipioSCH} from "../../../search/MunicipioSCH"

const url = '/municipio'

const state = {
  all: [],
  filtro: new Filtro(),
  ListAll: [],
  pdf: '',
}

const getters = {
  pegaPdf: (state) => {
    return state.pdf
  },
  listaMunicipiosCompleto: (state) => {
    return state.ListAll
  },
  listaMunicipios: (state) => {
    return state.all
  },
  pegaMunicipioId: (state) => (id) => {
    let mun = state.all.find(municipio => municipio.municipio_id === id)
    return mun !== null ? mun.nome : '** NÃO ENCONTRADO'
  },
  pegaMunicipio: (state) => (id) => {
    let mun = state.ListAll.find(municipio => municipio.municipio_id === id)
    return mun !== null ? mun : new Municipio()
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = []
    state.filtro.filters.addSearch(['nome', 'uf'],'%'+search+'%','ilike')
  },
  setFiltro({commit}, perPage) {
     commit('SET_FILTRO', MunicipioSCH(perPage))
  },
  async setAllMunicipiosCompleto({commit}) {
    const list = (await lista(url+'/all')).data
    commit('SET_ALL_MUNICIPIOS', list)
  },
  async setAllMunicipios({commit},{onSucesso,onErro}) {
    try {
      const list = (await pegaLista(url, state.filtro)).data
      commit('SET_MUNICIPIOS', list)
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
    }
  },
  async processarMunicipio({commit, rootActions}, {municipio, acao, onSucesso, onErro}) {
    try {

      let res = await processar(url, municipio, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      this.dispatch('logs_audit/processarLog', {item: municipio, key: 'municipio_id'})
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', trataError(error))
      onErro()
    }
  },

  async GerarRelatorioPdf({commit}, item) {
    try {
      const list = (await geraUrlRelatorio(url+'/pdf', state.filtro))
      commit('SET_PDF', list)
      EventBus.$emit('GERAR_PDF', true)
    } catch (error) {
      EventBus.$emit('GERAR_PDF', false)
    }
  },
}

const mutations = {
  SET_MUNICIPIOS(state, municipios) {
    state.all = municipios
  },
  SET_ALL_MUNICIPIOS(state, municipios) {
    state.ListAll = municipios
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_PDF(state, pdf) {
    state.pdf = pdf
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
