/* eslint-disable */
import {pegaDados} from '../../services/GenericAPI'

const url = '/analise/get'
const state = {
  all: []
}

const getters = {
  listaAll: (state) => {
    return state.all
  }
}

const actions = {
  async setAllList({commit}) {
    const list = (await pegaDados(url)).data
    commit('SET_ALL', list)
  }
}

const mutations = {
  SET_ALL(state, all) {
    state.all = all
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
