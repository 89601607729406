/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";

export const  getClienteImprimirSCH = (campo, valor, condicao)=>{
  var filtro = new Filtro()
  filtro.perPage = 1
  filtro.filters.addFilter(campo,valor,condicao)
  filtro.fields = [
    'clientes.nome',
    'fone1',
    'tp_pessoa',
    'logrado',
    'complemento',
    'numero',
    'bairro',
    'cep',
    'email',
    'fone1',
    'fone2',
    'tp_pessoa',
    'local_trab',
    'fone_trab',
    'ie',
    'cpf',
    'cnpj',
    'rg',
    'dt_nascimento',
    'mae',
    'fantasia',
    'contato',
    'municipios.nome as municipio']
  filtro.addOrder('clientes.nome', 'asc')
  filtro.join.addLeft('municipios','municipios.municipio_id','clientes.municipio_id')
  filtro.join.addLeft('clientes_pf','clientes_pf.cliente_id','clientes.cliente_id')
  filtro.join.addLeft('clientes_pj','clientes_pj.cliente_id','clientes.cliente_id')
  return filtro
}
export const  getClienteSCH = (campo, valor, condicao)=>{
  var filtro = new Filtro()
  filtro.perPage = 1
  filtro.filters.addFilter(campo,valor,condicao)
  filtro.fields = ['*', 'municipios.nome as municipio']
  return filtro
}
export const ClienteSCH = (perPage)=> {
  var filtro = new Filtro()
  filtro.perPage = perPage
  filtro.fields = ['clientes.nome', 'fone1', 'clientes_pf.cpf', 'clientes_pj.cnpj', 'tp_pessoa', 'municipios.nome as municipio', 'clientes.deleted']
  filtro.filters.addFilter('clientes.deleted',true,'<>')
  filtro.addOrder('clientes.nome', 'asc')
  filtro.join.addLeft('municipios','municipios.municipio_id','clientes.municipio_id')
  filtro.join.addLeft('clientes_pf','clientes_pf.cliente_id','clientes.cliente_id')
  filtro.join.addLeft('clientes_pj','clientes_pj.cliente_id','clientes.cliente_id')
  return filtro
}

export const ClienteDeletedSCH = (perPage)=> {
  var filtro = new Filtro()
  filtro.perPage = perPage
  filtro.fields = ['clientes.nome', 'fone1', 'clientes_pf.cpf', 'clientes_pj.cnpj', 'tp_pessoa', 'municipios.nome as municipio', 'clientes.deleted']
  filtro.filters.addFilter('clientes.deleted',true,'=')
  filtro.addOrder('clientes.nome', 'asc')
  filtro.join.addLeft('municipios','municipios.municipio_id','clientes.municipio_id')
  filtro.join.addLeft('clientes_pf','clientes_pf.cliente_id','clientes.cliente_id')
  filtro.join.addLeft('clientes_pj','clientes_pj.cliente_id','clientes.cliente_id')
  return filtro
}

export const ClienteSimplesSCH = (perPage)=> {
  var filtro = new Filtro()
  filtro.perPage = perPage
  filtro.addOrder('cliente', 'asc')
  return filtro
}
