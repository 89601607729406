import {geraUrlRelatorio, processar, trataError} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {zeroEsquerda} from '../../../helpers/util-functions'

const url = '/contrato_receber'

const state = {
  pdf: ''
}

const getters = {
  pegaPdf: (state) => {
    return state.pdf
  }
}

const actions = {
  async previewRemessa({commit}, dados) {
    try {
      const list = (await geraUrlRelatorio(url + '/preview_remessa', dados))
      commit('SET_PDF', list)
      EventBus.$emit('pdf-gerado', true)
    } catch (error) {
      this.dispatch('geral/setObjMsgError', trataError(error))
      EventBus.$emit('pdf-gerado', false)
    }
  },

  async processaRemessa({commit}, dados) {
    try {
      console.log(dados);
      const res = await processar(url + '/gera_remessa', dados)

      const urlDownload = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = urlDownload
      link.setAttribute('download', 'REMESSA_SICOOB_' + zeroEsquerda(dados.num_remessa, 6) + '.TXT')
      document.body.appendChild(link)
      link.click()

      EventBus.$emit('remessa-gerada', true)
    } catch (error) {
      this.dispatch('geral/setObjMsgError', trataError(error))
      EventBus.$emit('remessa-gerada', false)
    }
  },

  async buscaUltimaRemessa({commit}, numRemessa) {
    try {
      const res = await processar(url + '/ultima_remessa')

      const urlDownload = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = urlDownload
      link.setAttribute('download', 'REMESSA_SICOOB_' + zeroEsquerda(numRemessa - 1, 6) + '.TXT')
      document.body.appendChild(link)
      link.click()

      EventBus.$emit('ultima-remessa-gerada', true)
    } catch (error) {
      this.dispatch('geral/setObjMsgError', trataError(error))
      EventBus.$emit('ultima-remessa-gerada', false)
    }
  }
}

const mutations = {
  SET_PDF(state, pdf) {
    state.pdf = pdf
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
