/* eslint-disable */
import {VMoney} from 'v-money'
import {mask} from 'vue-the-mask'

export default {
  data() {
    return {
      setPage: false,
      show_list: false,
      itemsPerPage: 10
    }
  },
  directives: {money: VMoney, mask},
  methods: {
    getTotais(total, tam){
      return this.validarCampo(total) ? parseInt(total) : tam
    },
  }
}
