/* eslint-disable */
import {geraUrlRelatorio, lista, pegaLista, processar, trataError} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {Filtro, Where} from "../../../models/filtro/filtro";
import {CaixaSCH, getCaixaSCH} from "../../../search/CaixaSCH";

const url = '/caixa'

const state = {
  all: [],
  allList: [],
  filtro: new Filtro(),
  pdf: ''
}

const getters = {
  pegaPdf: (state) => {
    return state.pdf
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
  listaCaixas: (state) => {
    return state.all
  },
  listaCaixasSemFiltro: (state) => {
    return state.all
  },
  listaAllCaixas: (state) => {
    return state.allList
  }

}

const actions = {

  searchItem({commit}, search) {
    state.filtro.filters.search = []
    state.filtro.filters.addSearch(['descricao'], '%' + search + '%', 'ilike')
  },
  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', CaixaSCH(perPage))
  },
  async getItem({commit}, id) {
    var filtro = getCaixaSCH('caixa_id', id, '=')
    const list = (await pegaLista(url, filtro)).data
    EventBus.$emit('GET_CAIXA_CONCLUIDO', true,  list.data[0])
  },
  async setAllCaixas({commit},{onSucesso,onErro}) {
    try {
      const list = (await pegaLista(url, state.filtro)).data
      commit('SET_CAIXAS', list)
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
    }
  },

  async setAllListCaixas({commit}) {
    const list = (await lista(url+'/all')).data
    commit('SET_ALL_CAIXA', list)
  },

  async processarCaixa({commit}, {caixa, acao, onSucesso, onErro}) {
    try {
      let res = await processar(url, caixa, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      this.dispatch('logs_audit/processarLog', {item: caixa, key: 'caixa_id'})
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', trataError(error))
      onErro()
    }
  },
  async GerarRelatorioPdf({commit}, item) {
    try {
      const list = (await geraUrlRelatorio(url+'/pdf', item))
      commit('SET_PDF', list)
      EventBus.$emit('GERAR_PDF', true)
    } catch (error) {
      EventBus.$emit('GERAR_PDF', false)
    }

  },
}

const mutations = {
  SET_CAIXAS(state, caixas) {
    state.all = caixas
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_ALL_CAIXA(state, allList) {
    state.allList = allList
  },
  SET_PDF(state, pdf) {
    state.pdf = pdf
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
