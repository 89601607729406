/* eslint-disable */
import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import Vuetify from 'vuetify'
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/dist/vuetify.min.css'
import './assets/css/main.scss'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'
import ContainerForm from '../src/components/ContainerForm.vue'
import ModalCadastro from '../src/components/ModalCadastro.vue'
import store from './store/index'
import { sync } from 'vuex-router-sync'
import VuejsDialog from 'vuejs-dialog'
import ProCard from './components/ProCard.vue'
import 'vue2-animate/dist/vue2-animate.min.css'
import 'cropperjs/dist/cropper.css'
import currency from 'v-currency-field'
import pNumeric from './components/pComponents/pNumeric'
import pText from './components/pComponents/pText'
import pLabel from './components/pComponents/pLabel'

import VueDraggableResizable from 'vue-draggable-resizable'

// optionally import default styles
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
import TableIterator from "./components/TableIterator";
import SimpleTable from "./components/SimpleTable";

Vue.component('vue-draggable-resizable', VueDraggableResizable)
Vue.use(currency)

Vue.component('simple-table', SimpleTable)
Vue.component('pro-container-form', ContainerForm)
Vue.component('pro-card', ProCard)
Vue.component('pro-modal-cadastro', ModalCadastro)
Vue.component('table-iterator', TableIterator)
Vue.component('p-numeric', pNumeric)
Vue.component('p-text', pText)
Vue.component('p-label', pLabel)


Vue.use(Vuetify, { theme: {
  primary: '#1d94ff',
  secondary: '#0a593b',
  accent: '#82B1FF',
  error: '#FF5252',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FFC107'
} })



Vue.use(VuejsDialog, {
  html: true,
  loader: true,
  okText: 'Continuar',
  cancelText: 'Cancelar',
  animation: 'fadeIn',
  type: 'alert'
})

// TRUE para testar no servidor e FALSE para Testar localmente
Vue.config.productionTip = true

sync(store, router)

const vuetifyOptions = {
  icons: {
    iconfont: 'mdi'
  }
}

new Vue({
  router,
  store,
  render: h => h(App),
  vuetify: new Vuetify(vuetifyOptions)
}).$mount('#app')
