/* eslint-disable */
import {lista} from '../../services/GenericAPI'

const url = 'estado_civil'

const state = {
  all: []
}

const getters = {
  listaEstadoCivil: (state) => {
    return state.all
  }
}

const actions = {
  async setAllEstadoCivil ({ commit }) {
    const list = (await lista(url)).data
    commit('SET_ESTADO_CIVIL', list)
  }
}

const mutations = {
  SET_ESTADO_CIVIL(state, estado) {
    state.all = estado
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
