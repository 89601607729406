/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class EmpresaDadosBancarios {
  constructor (empresaId) {

    this.empresa_dados_bancario_id = uuidV4()
    this.empresa_id = empresaId
    this.banco = ''
    this.cod_cliente = ''
    this.coop_convenio = ''
    this.conta_cliente = ''
    this.instrucoes = ''
    this.carteira = 0
  }
}
