<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div v-if="textarea">
    <v-textarea :name=name
                :readonly=chkReadonly
                :hide-details="hideDetails"
                dense
                :class=classes
                :rows=rows
                auto-grow
                outlined
                :value="value"
                @input="updateValueTextArea"
                autocomplete="no"
                :label=label>
    </v-textarea>
  </div>

  <div v-else-if="buildMask">
    <v-text-field :name=name
                  autocomplete="off"
                  :hide-details="hideDetails"
                  dense
                  outlined
                  :label=label
                  :type=pInputType
                  :disabled=disabled
                  :class=classes
                  :readonly=chkReadonly
                  :rules=rulesValidation
                  :hint=hint
                  v-model="updateValueMask"
                  v-mask=buildMask>
      <template v-slot:append-outer>
        <slot></slot>
      </template>

      <template v-slot:append v-if="hasTooltip">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon  color="gray" size="18" style="padding-top: 3px"  v-bind="attrs" v-on="on">
              {{iconTooltip}}
            </v-icon>
          </template>
          <span>{{txtTooltip}}</span>
        </v-tooltip>
      </template>
    </v-text-field>
  </div>

  <div v-else>
    <v-text-field :name=name
                  autocomplete="off"
                  :hide-details="hideDetails"
                  dense
                  outlined
                  :label=label
                  :type=pInputType
                  :disabled=disabled
                  :class=classes
                  :readonly=chkReadonly
                  :rules=rulesValidation
                  :hint=hint
                  v-model="updateValue">
      <template v-slot:append-outer>
        <slot></slot>
      </template>

      <template v-slot:append v-if="hasTooltip">
        <v-tooltip bottom >
          <template v-slot:activator="{ on, attrs }">
            <v-icon  color="gray" size="18" style="padding-top: 3px"  v-bind="attrs" v-on="on">
              {{iconTooltip}}
            </v-icon>
          </template>
          <span>{{txtTooltip}}</span>
        </v-tooltip>
      </template>

    </v-text-field>
  </div>

</template>

<script async>
  /* eslint-disable */
  import validator from './commons/validator'
  import {mapGetters} from 'vuex'
  import {mask} from 'vue-the-mask'
  import {removeMascaraCampoNumerico, colocaMascaraHora} from './commons/misc'

  export default {
    name: 'pText',
    mixins: [validator],
    props: {
      label: {
        default: 'Cabecalho'
      },
      value: {
        type: [String, Number]
      },
      name: {
        type: String
      },
      addclass: {
        type: String,
        default: ''
      },
      validation: {
        type: String,
        default: ''
      },
      iconTooltip: {
        type: String,
        default: ''
      },
      txtTooltip: {
        type: String,
        default: ''
      },
      hint: {
        type: String,
        default: ''
      },
      textarea: {
        type: Boolean,
        default: false
      },
      hasTooltip: {
        type: Boolean,
        default: false
      },
      hideDetails: {
        type: Boolean,
        default: true
      },
      disabled: {
        type: Boolean,
        default: false
      },
      rows: {
        type: String,
        default: '2'
      },
      readonly: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      rulesValidation: [],
      pInputType: null,
      classes: '',
      masked_value: '',
      convertTo: 'N' // U = Upper, L = Lower, N = Nada
    }),
    directives: {mask},
    methods: {
      updateValueTextArea(valor) {
        /* eslint-disable no-console */
        const _valor = this.convertTo === 'U' ? valor.toUpperCase() : this.convertTo === 'L' ? valor.toLowerCase() : valor
        this.$emit('input', _valor)
        /* eslint-enable no-console */
      }
    },
    computed: {
      ...mapGetters('geral', {
        disableField: 'getDisableField'
      }),
      buildMask() {
        /* eslint-disable no-console */
        if (this.validation.indexOf('fone') >= 0) {
          return ['(##)#####-####', '(##)####-####']
        } else if (this.validation.indexOf('cnpj_cpf') >= 0) {
          return ['###.###.###-##', '##.###.###/####-##']
        } else if (this.validation.indexOf('cpf') >= 0) {
          return ['###.###.###-##']
        } else if (this.validation.indexOf('cnpj') >= 0) {
          return ['##.###.###/####-##']
        } else if (this.validation.indexOf('cfop') >= 0) {
          return ['#.###']
        } else if (this.validation.indexOf('cep') >= 0) {
          return ['##.###-###']
        } else if (this.validation.indexOf('integer') >= 0) {
          return ['#####################']
        }
        return ''
        /* eslint-enable no-console */
      },
      updateValueMask: {
        /* eslint-disable no-console */
        set: function (val) {
          this.$emit('input', removeMascaraCampoNumerico(val))
        },
        get: function () {
          return this.masked_value
        }
        /* eslint-enable no-console */
      },
      updateValue: {
        set: function (valor) {
          /* eslint-disable no-console */
          const _valor = this.convertTo === 'U' ? valor.toUpperCase() : this.convertTo === 'L' ? valor.toLowerCase() : valor
          this.$emit('input', _valor)
          /* eslint-enable no-console */
        },
        get: function () {
          return this.value
        }
      },
      // alteraClasse: {
      //   set: function (valor) {
      //    this.classes = this.addclass
      //   },
      //   get: function () {
      //     return this.classes
      //   }
      // },
      chkReadonly() {
        if (this.disableField || this.readonly) {
          this.classes = 'desabilitado'
        }
        return this.disableField || this.readonly
      }
    },
    watch: {
      addclass: function () {
        this.classes = this.addclass
      }
    },
    created() {
      /* eslint-disable no-console */
      this.classes = this.addclass
      this.masked_value = this.value
      if (this.textarea) {
        this.classes = ' vtextarea ' // aqui depois se tiver a necessidade do required eu quebro o padraoTextArea
      }

      if (this.validation !== '') {
        this.buildRules(this)
      }

      // checando se a class requerido foi adicionado no objeto, se não tiver vou adicionar a classe padrão


      if (this.classes.indexOf('requerido') === -1) {
        // this.classes += ''
        this.classes = 'padrao'
      }

      /* eslint-enable no-console */
    }
  }
</script>

<style lang="sass">
  @import "./commons/p.scss"
</style>
