/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";
export const  getEmpreendimentoSCH = (campo, valor, condicao)=>{
  var filtro = new Filtro()
  filtro.perPage = 1
  filtro.filters.addFilter(campo,valor,condicao)
  filtro.fields = ['*']
  return filtro
}

export const EmpreendimentoSCH = (perPage)=> {
    var filtro = new Filtro()
    filtro.perPage = perPage
    filtro.fields = ['empreendimentos.nome', 'logrado', 'bairro', 'municipios.nome as municipio']
    filtro.addOrder('nome', 'asc')
    filtro.join.addLeft('municipios','municipios.municipio_id','empreendimentos.municipio_id')
  return filtro
}
