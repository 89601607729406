/* eslint-disable */
import { ClientePf } from './cliente_pf'
import { ClientePj } from './cliente_pj'
import { v4 as uuidV4 } from 'uuid';
export class Cliente {
  constructor () {


    this.cliente_id = uuidV4()
    this.nome = ''
    this.logrado = ''
    this.numero = ''
    this.complemento = ''
    this.bairro = ''
    this.municipio_id = ''
    this.cep = ''
    this.fone1 = ''
    this.fone2 = ''
    this.email = ''
    this.referencia = ''
    this.observacao = ''
    this.deleted = false
    this.motivo_delete = ''
    this.tp_pessoa = ''
    this.anexos = []
    this.cliente_pf = new ClientePf(this.cliente_id)
    this.cliente_pj = new ClientePj(this.cliente_id)
  }
}
