export const hasOnlyDigits = function(str) {
  return /^\d+$/.test(str)
}

export const isValidEmail = function(str) {
  return /.+@.+\..+/.test(str)
}

export const isValidURL = function(str) {
  // eslint-disable-next-line
  const res = str.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)
  return (res !== null)
}

export const removeMascaraCampoNumerico = function(str) {
  if (str === null || str === undefined) {
    return ''
  }
  return str.replace(/[^0-9]+/g, '')
}

export const colocaMascaraHora = function(str) {
  /* eslint-disable no-console */
  if (str === null || str === undefined) {
    return '--:--'
  } else {
    str = removeMascaraCampoNumerico(str)
    return str.replace(/(\d{2})(\d{2})/gim, '$1:$2')
  }
  /* eslint-enable no-console */
}

export const validaCnpjCpf = function(str) {
  if (str === null || str === undefined) {
    return false
  }

  const tmpCnpjCpf = str.replace(/[^\d]+/g, '')
  if (tmpCnpjCpf === '') {
    return false
  }

  if (tmpCnpjCpf.length !== 11 && tmpCnpjCpf.length !== 14) {
    return false
  }

  if (tmpCnpjCpf === tmpCnpjCpf.substr(0, 1).repeat(tmpCnpjCpf.length)) {
    return false
  }

  if (tmpCnpjCpf.length === 11) { // validando o CPF
    let soma = 0
    let resto
    for (let i = 1; i <= 9; i++) {
      soma = soma + parseInt(tmpCnpjCpf.substring(i - 1, i)) * (11 - i)
    }
    resto = (soma * 10) % 11
    if ((resto === 10) || (resto === 11)) {
      resto = 0
    }
    if (resto !== parseInt(tmpCnpjCpf.substring(9, 10))) {
      return false
    }
    soma = 0
    for (let i = 1; i <= 10; i++) {
      soma = soma + parseInt(tmpCnpjCpf.substring(i - 1, i)) * (12 - i)
    }
    resto = (soma * 10) % 11
    if ((resto === 10) || (resto === 11)) {
      resto = 0
    }
    return resto === parseInt(tmpCnpjCpf.substring(10, 11))
  } else { // validando o CNPJ
    let tamanho = tmpCnpjCpf.length - 2
    let numeros = tmpCnpjCpf.substring(0, tamanho)
    const digitos = tmpCnpjCpf.substring(tamanho)
    let soma = 0
    let pos = tamanho - 7
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--
      if (pos < 2) pos = 9
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
    if (resultado !== parseInt(digitos.charAt(0))) {
      return false
    }
    tamanho = tamanho + 1
    numeros = tmpCnpjCpf.substring(0, tamanho)
    soma = 0
    pos = tamanho - 7
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--
      if (pos < 2) pos = 9
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
    return resultado === parseInt(digitos.charAt(1))
  }
}
