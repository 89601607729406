/* eslint-disable */
import {getArquivo} from '../../services/GenericAPI'
import {EventBus} from '../../helpers/event-bus'

const url = '/arquivo'

const state = {
  all: []
}

const getters = {
}

const actions = {
  async setAllArquivo({commit}, {item, link}) {
    try {
      const list = (await getArquivo(url + link, item))
      commit('SET_ARQUIVO', list)
      EventBus.$emit('ARQUIVO', list, true)
    } catch (error) {
      EventBus.$emit('ARQUIVO', [], false)
    }
  },
}

const mutations = {
  SET_ARQUIVO(state, list) {
    state.all = list
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
