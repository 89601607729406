/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class ClientePf {
  constructor (clienteId) {


    this.cliente_pf_id = uuidV4()
    this.cliente_id = clienteId
    this.cpf = ''
    this.rg = ''
    this.dt_nascimento = ''
    this.mae = ''
    this.local_trab = ''
    this.fone_trab = ''
    this.nacionalidade = ''
    this.estado_civil_id = 0
  }
}
