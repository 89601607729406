/* eslint-disable */
import {ContratosClientes} from "./contrato_cliente";
import { v4 as uuidV4 } from 'uuid';

export class Contrato {
  constructor () {
    this.contrato_id = uuidV4()
    this.numero = ''
    this.lote_id = ''
    this.corretora_id = ''

    this.dt_contrato = ''
    this.dt_cadastro = ''
    this.dt_primeira_parcela = ''
    this.dt_entrada = ''
    this.vl_lote = ''
    this.qt_parcelas = ''
    this.perc_reajuste = ''
    this.prazo_reajuste = ''
    this.perc_multa = ''
    this.perc_juros = ''
    this.vl_comissao = ''
    this.vl_entrada = ''
    this.vl_liberacao_comissao = ''

    this.observacao = ''
    this.intervalo_parcelas_entrada = ''
    this.qt_parcelas_entrada = ''
    this.contratos_clientes= []
    this.contratos_documentos= []
    this.contratos_receber = []
    this.anexos = []
  }
}
