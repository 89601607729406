<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <v-currency-field
      label=""
      disabled solo dense
      v-bind=money
      :value="value"
      :class="classe">
      <template v-slot:append>
       <slot></slot>
      </template>
    </v-currency-field>
  </div>
</template>

<script async>
/* eslint-disable */
  export default {
    name: 'pLabel',
    props: {
      classe: {
        type: String,
        default: 'decimalDisabled'
      },
      name: {
        type: String
      },
      addclass: {
        type: String,
        default: ''
      },
      value: {
        type: Number | String,
      },
      money: {
        default: () => ({
          decimal: ',',
          thousands: '.',
          prefix: '',
          suffix: '',
          precision: 2,
          masked: false,
          allowBlank: false,
          min: Number.MIN_SAFE_INTEGER,
          max: Number.MAX_SAFE_INTEGER
        })
      }
    },
    data: () => ({
      classes: ''
    }),
    created() {
      /* eslint-disable no-console */
      this.classes += this.addclass
      /* eslint-enable no-console */
    }
  }
</script>

