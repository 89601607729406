/* eslint-disable */
import {geraUrlRelatorio, lista, pegaLista, processar, trataError} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {MovimentoCaixa} from "../../../models/lancamento/movimento_caixa";
import {Filtro, Where} from "../../../models/filtro/filtro";
import {getData, MovimentoCaixaSCH} from "../../../search/MovimentoCaixaSCH";
import {getLoteSCH} from "../../../search/LoteSCH";


const url = '/mov_caixa'

const state = {
  all: [],
  documento: [],
  movimento_caixa: new MovimentoCaixa(),
  contfiltro: 0,
  primeiro: [],
  pdf: '',
  filtro: new Filtro()
}

const getters = {
  pegaPdf: (state) => {
    return state.pdf
  },
  listaMovimentoCaixas: (state, getters, rootState) => {
    return state.all
  },
  pegaMovimentoCaixa: (state) => {
    return state.movimento_caixa !== null && state.movimento_caixa !== undefined ? state.movimento_caixa : new MovimentoCaixa()
  },
  pegaMovCaixa: (state) => {
    return state.movimento_caixa
  },
  getPrimeiro: (state) => {
    return state.primeiro
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  async getItem({commit}, id) {
    var filtro = getLoteSCH('mov_caixa.mov_caixa_id', id, '=')
    const list = (await pegaLista(url, filtro)).data
    EventBus.$emit('GET_MOV_CAIXA_CONCLUIDO', true, list.data[0])
  },
  searchItem({commit}, search) {
    state.filtro.filters.search = []
    state.filtro.filters.addSearch(
      ['mov_caixa.data', 'mov_caixa.status', 'mov_caixa.vl_receitas', 'mov_caixa.vl_despesas', 'caixas.descricao']
      , '%' + search + '%', 'ilike')
  },
  setMovCaixa({commit}, movimento_caixa) {
    commit('SET_MOVIMENTO_CAIXA', movimento_caixa)
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', MovimentoCaixaSCH(perPage))
  },
  async setAllMovimentoCaixas({commit},{onSucesso, onErro}) {
    try {
      const list = (await pegaLista(url, state.filtro)).data
      commit('SET_MOVIMENTO_CAIXAS', list)
      onSucesso()
    } catch (error) {
      onErro()
    }
  },

  async setPrimeiro({commit}) {
    try {
      const list = (await lista(url + '/primeiro_mov')).data
      var primeiro = {data: list[0].data, isFirst: getData(list[0].data)}
      commit('SET_PRIMEIRO', primeiro)
      EventBus.$emit('PRIMEIRO', true)
    } catch (error) {
      EventBus.$emit('PRIMEIRO', false)
    }
  },

  async processaMovEventoCaixa({commit}, {item, acao,onSucesso, onErro}) {
    try {
      console.log('1-processaMovEventoCaixa');
      let res = await processar(url + '/evento', item, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', trataError(error))
      onErro()
    }
  },
  async processarMovimentoCaixa({commit}, {acao, tipo,onSucesso, onErro}) {
    try {
      console.log('2-processarMovimentoCaixa');
      let res = await processar(url, state.movimento_caixa, acao)
      if (tipo === 1) {
        this.dispatch('geral/setMsgSuccess', res.data.message)
        EventBus.$emit('PROCESSO_CONCLUIDO', true, true, 'MovimentosCaixa')
      } else if (tipo === 2) {
        this.dispatch('geral/setMsgSuccess', res.data.message)
        EventBus.$emit('PROCESSO_CONCLUIDO', true, true, 'MovimentoEventoCaixa')
      } else {
        onSucesso()
      }
      this.dispatch('logs_audit/processarLog', {item: state.movimento_caixa, key: ''})
    } catch (error) {
      console.log('error: ', error);
      this.dispatch('geral/setObjMsgError', trataError(error))
      if (tipo === 1) {
        EventBus.$emit('PROCESSO_CONCLUIDO', false, true, 'MovimentoEventoCaixa')
      }else if (tipo === 2) {
        EventBus.$emit('PROCESSO_CONCLUIDO', false, true, 'MovimentosCaixa')
      } else {
        onErro()
      }
    }
  },
  async filtrando({commit}, {filtro}) {
    commit('SET_FILTRO', filtro)
  },

  processarExtras({commit}, {item, acao}) {
    if (acao === 'adicionar') {
      commit('ADICIONA_MOV_EVENTO_CAIXA', item)
    } else if (acao === 'alterar') {
      commit('ALTERA_MOV_EVENTO_CAIXA', item)
    } else if (acao === 'remover') {
      commit('REMOVE_MOV_EVENTO_CAIXA', item)
    }
  },

  async imprimirMovCaixa({commit}, item) {
    try {
      var filtro = {
        filters:{
          filter: [{
            field: 'mov_caixa_id',
            value: item.mov_caixa_id,
            condition: '=',
          }]
        }
      }
      const list = (await geraUrlRelatorio(url+'/pdf', filtro))
      commit('SET_PDF', list)
      EventBus.$emit('GERAR_PDF', true)
    } catch (error) {
      EventBus.$emit('GERAR_PDF', false)
    }
  },

}

const mutations = {
  SET_PRIMEIRO(state, primeiro) {
    state.primeiro = primeiro
  },

  SET_MOVIMENTO_CAIXAS(state, movimento_caixas) {
    state.all = movimento_caixas
  },
  SET_MOVIMENTO_CAIXA(state, movimento_caixa) {
    state.movimento_caixa = movimento_caixa
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  // MOV_EVENTO_CAIXAS
  REMOVE_MOV_EVENTO_CAIXA(state, mov_eventocaixa) {
    let pos = state.movimento_caixa.mov_eventos_caixa.indexOf(mov_eventocaixa)
    state.movimento_caixa.mov_eventos_caixa.splice(pos, 1)
  },

  ALTERA_MOV_EVENTO_CAIXA(state, mov_eventocaixa) {
    var c = state.movimento_caixa.mov_eventos_caixa.find(lista => lista.mov_evento_caixa_id === mov_eventocaixa.mov_evento_caixa_id)
    let pos = state.movimento_caixa.mov_eventos_caixa.indexOf(c)
    state.movimento_caixa.mov_eventos_caixa.splice(pos, 1, mov_eventocaixa)
  },

  ADICIONA_MOV_EVENTO_CAIXA(state, mov_eventocaixa) {
    state.movimento_caixa.mov_eventos_caixa.splice(state.movimento_caixa.mov_eventos_caixa.length, 1, mov_eventocaixa)
  },
  SET_PDF(state, pdf) {
    state.pdf = pdf
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
