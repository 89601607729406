/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class EventoCaixa {
  constructor () {


    this.evento_id = uuidV4()
    this.descricao = ''
  }
}
