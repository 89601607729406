/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";


export const  getCorretoraSCH = (campo, valor, condicao)=>{
  var filtro = new Filtro()
  filtro.perPage = 1
  filtro.filters.addFilter(campo,valor,condicao)
  filtro.fields = ['*']
  return filtro
}

export const CorretoraSCH = (perPage)=> {
    var filtro = new Filtro()
    filtro.perPage = perPage
    filtro.fields = ['corretoras.nome', 'cnpj_cpf',  'municipios.nome as municipio', 'fone1']
    filtro.addOrder('corretoras.nome', 'asc')
    filtro.join.addLeft('municipios','municipios.municipio_id','corretoras.municipio_id')
  return filtro
}
