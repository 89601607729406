/* eslint-disable */
import {mask} from 'vue-the-mask'
import {VMoney} from 'v-money'
import {API} from '../api'
import {pegaDados} from '../services/GenericAPI'

export default {
  directives: {
    money: VMoney,
    mask
  },
  data() {
    return {
      mandatory: true,
      desabilitarCampos: false,
      mascara_fone: ['(##)####-####', '(##)#.####-####'],
      listaItens: [],
      dialog: {
        show: false,
        title: '',
        message: ''
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      },
      precisao_2: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
        allowBlank: false,
        min: Number.MIN_SAFE_INTEGER,
        max: Number.MAX_SAFE_INTEGER
      },
      precisao_3: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 3,
        masked: false,
        allowBlank: false,
        min: Number.MIN_SAFE_INTEGER,
        max: Number.MAX_SAFE_INTEGER
      },
      motivo_delete: false,
      removendo: false,
      dialogPdf: false
    }
  },
  methods: {
    getMandatory() {
      if (this.mandatory) {
        this.mandatory = false
      }
    },
    cloneObj(obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    itens(item) {
      this.listaItens = item
    },
    closeDialog(dialog) {
      dialog.close()
    },
    onCancel() {
      this.value = 'Não'
    },
    validarCampo(campo) {
      if (campo !== undefined && campo !== null && campo !== '') {
        return true
      } else {
        return false
      }
    },
    mobileModal(size) {
      let width = window.screen.width
      if (width <= 600) {
        size = '90%'
      } else if (width >= 601 && width <= 900) {
        size = '80%'
      } else if (width >= 901 && width <= 1300) {
        size = '70%'
      }
      return size
    },
    retorno(item, termo, retorno1, retorno2) {
      return item === termo ? retorno1 : retorno2
    },
    maskHora(hora) {
      if (this.validarCampo(hora)) {
        hora = this.removerMascara(hora)
        return hora.replace(/(\d{2})(\d{2})/gim, '\$1\:\$2')
      } else {
        return '- - : - -'
      }
    },
    removerMascara(str) {
      if (this.validarCampo(str)) {
        return str.replace(/[^0-9]+/g, '')
      } else {
        return ''
      }
    },
    focusInput(id) {
      var loadTime = 10
      window.onload = function() {
        loadTime = window.performance.timing.domContentLoadedEventEnd - window.performance.timing.navigationStart
      }
      let interval = setInterval(function() {
        let muniImpt = document.getElementById(id)
        if (muniImpt !== null) {
          muniImpt.focus()
        }

        clearInterval(interval)
      }, loadTime)
    },

    limparMotivo(mtv) {
      return mtv !== null && mtv.length >= 15 ? mtv : null
    },

    maskData(data) {
      // eslint-disable-next-line no-useless-escape,no-useless-escape, no-useless-escape, no-useless-escape
      return data.replace(/(\d{2})(\d{2})(\d{4})/g, '\$1/\$2/\$3')
    },

    inverterData(newData) {
      newData = this.maskData(newData)
      var xvals = newData.split('/')
      var dia = ('00' + parseInt(xvals[0])).slice(-2)
      var mes = ('00' + parseInt(xvals[1])).slice(-2)
      var ano = parseInt(xvals[2])
      return parseInt(xvals[2]) + '-' + mes + '-' + dia
    },

    reverterData(data) {
      var newData = data.split('-')
      return newData.reverse().join('')
    },
    gerarDataAtual(data) {
      if (this.validarCampo(data)) {
        return data
      } else {
        var dt = new Date().toLocaleDateString()
        var newData = dt.split('/')
        return newData.reverse().join('-') // 0000-00-00
      }


    },
    maskData2(data) {
      if (this.validarCampo(data)) {
        return this.maskData(this.reverterData(data))
      }
    },
    maskValor(valor, decimais, comPrefixo) {
      const numDecimais = (decimais !== null && decimais !== undefined) ? decimais : 2
      if (this.validarCampo(valor)) {
        if (comPrefixo !== null && comPrefixo !== undefined && comPrefixo) {
          return Number(valor).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: numDecimais
          })
        } else {
          return Number(valor).toLocaleString('pt-br', {minimumFractionDigits: numDecimais})
        }
      }
      return ''
    },
    maskCep(cpf) {
      // eslint-disable-next-line no-useless-escape,no-useless-escape, no-useless-escape, no-useless-escape
      return cpf.replace(/(\d{2})(\d{3})(\d{3})/g, '\$1.\$2\-\$3')
    },

    maskTel(srt) {
      if (this.validarCampo(srt)) {
        if (srt.length <= 10) {
          return srt.replace(/(\d{2})(\d{4})(\d{4})/g, '(\$1) \$2\-\$3')
        } else {
          return srt.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/g, '(\$1) \$2.\$3\-\$4')
        }
      }
    },

    maskCpfCnpj(srt) {
      if (this.validarCampo(srt)) {
        if (srt.length <= 11) {
          return srt.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '\$1.\$2.\$3\-\$4')
        } else {
          return srt.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '\$1.\$2.\$3\/\$4\-\$5')
        }
      }
    },
    maskCpfCnpjFull(srt) {
      if (this.validarCampo(srt)) {
        if (srt.length <= 11) {
          if (srt.length <= 3) {
            return srt.replace(/(\d{3})/g, '\$1.')
          } else if (srt.length > 3 && srt.length <= 6) {
            return srt.replace(/(\d{3})(\d{3})/g, '\$1.\$2.')
          } else if (srt.length > 6 && srt.length <= 9) {
            return srt.replace(/(\d{3})(\d{3})(\d{3})/g, '\$1.\$2.\$3\-')
          } else {
            return srt.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '\$1.\$2.\$3\-\$4')
          }
        } else {
          if (srt.length >= 12 && srt.length <= 13) {
            return srt.replace(/(\d{2})(\d{3})(\d{3})/g, '\$1.\$2.\$3\/')
          } else {
            return srt.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '\$1.\$2.\$3\/\$4\-\$5')
          }
        }
      }
    },

    getValueByKey(item, key) {
      if (!this.validarCampo(item[key])) {
        return item
      } else {
        return item[key]
      }
    },
// Configuração  de cores
    nova_configuracao(tema) {

      var item = document.getElementById('sidebar')
      var sidebarRight = document.getElementById('sidebarRight')
      var toobarDash = document.getElementById('toobarDash')
      var system_bar = document.getElementById('system_bar')
      toobarDash.style.backgroundColor = this.setValues('cor', '#000000', tema)

      item.style.backgroundColor = this.setValues('cor', '#000000', tema)
      if (sidebarRight != null) {
        sidebarRight.style.backgroundColor = this.setValues('cor', '#000000', tema)
        sidebarRight.style.opacity = this.setValues('opacidade', 0.75, tema)

      }

      toobarDash.style.opacity = this.setValues('opacidade', 0.75, tema)
      item.style.opacity = this.setValues('opacidade', 0.75, tema)

      var tinycolor = require('tinycolor2')

      var cor = this.setValues('cor', '#000000', tema)
      cor = tinycolor(cor).darken(2).toString()
      system_bar.style.backgroundColor = tema.opacidade <= 0.9 ? cor : '#343434'

      var color = tinycolor(this.setValues('cor', '#000000', tema))
      var color2 = ''
      if (color.isDark()) {
        color2 = tinycolor(color).lighten(80).toString()
      } else {
        color2 = tinycolor(color).darken(80).toString()
      }
      this.mudarCorListaSidebar(color2)
    },

    percorrerElemento(classe, cor, nivel) {
      var elemento = document.getElementsByClassName(classe)
      if (nivel === 1) {
        for (var i = 0; i < elemento.length; i++) {
          elemento.item(i).style.color = cor
        }
      } else {
        for (var k = 0; k < elemento.length; k++) {
          elemento.item(k).style.color = cor
          elemento.item(k).childNodes.item(0).style.color = cor
        }
      }
    },
    mudarCorListaSidebar(cor) {
      this.percorrerElemento('v-list-item__title', cor, 1)
      this.percorrerElemento('listHoverIcon', cor, 1)

      this.percorrerElemento('v-list-item__subtitle', cor, 1)
      this.percorrerElemento('v-list-item__action', cor, 2)
      this.percorrerElemento('v-list-item__icon v-list-group__header__prepend-icon', cor, 2)
      this.percorrerElemento('v-list-item__icon v-list-group__header__append-icon', cor, 2)
    },
    mudarBackground(cor) {
      var tinycolor = require('tinycolor2')
      var color = cor !== undefined ? tinycolor(cor) : tinycolor('#000000')
      var color2 = tinycolor(color).lighten(45).toString()
      var inspireInicio = document.getElementById('inspireInicio')
      inspireInicio.style.background = color2
    },

    setValues(key, valor, tema) {
      var retorno = ''
      if (this.validarCampo(tema)) {
        retorno = tema[key]
      } else {
        retorno = valor
      }
      return retorno
    },

    zeroEsquerda(value, totalWidth, paddingChar) {
      var length = totalWidth - value.toString().length + 1
      return Array(length).join(paddingChar || '0') + value
    },

    zeroDireita(v) {
      if (this.validarCampo(v)) {
        return parseFloat(v).toFixed(2)
      }
    },

    verConsultavel(editavel, consultavel, adicionavel) {
      consultavel = !consultavel
      editavel = !editavel
      adicionavel = !adicionavel

      var resultado = false

      if (adicionavel && editavel && consultavel) {
        resultado = true
      } else if (adicionavel && editavel && !consultavel) {
        resultado = true
      } else if (adicionavel && !editavel && consultavel) {
        resultado = false
      } else if (adicionavel && !editavel && !consultavel) {
        resultado = false
      } else if (!adicionavel && editavel && consultavel) {
        resultado = true
      } else if (!adicionavel && editavel && !consultavel) {
        resultado = true
      } else if (!adicionavel && !editavel && consultavel) {
        resultado = false
      } else if (!adicionavel && !editavel && !consultavel) {
        resultado = false
      }

      return resultado
    },
    getStorageBoolean(nome) {
      return JSON.parse(sessionStorage.getItem(nome))
    },

    getDataAtual() {
      var d = this.zeroEsquerda(new Date().getDate(), 2)
      var m = this.zeroEsquerda(new Date().getMonth() + 1, 2)
      var a = new Date().getFullYear()
      return a + '-' + m + '-' + d
    },

    setDataAtual(data) {
      var d = this.zeroEsquerda(data.getDate(), 2)
      var m = this.zeroEsquerda(data.getMonth() + 1, 2)
      var a = data.getFullYear()
      return a + '-' + m + '-' + d
    },

    compararData(dt_inicial, dt_final) {
      var g1 = new Date(dt_inicial)
      var g2 = new Date(dt_final)
      return g1.getTime() > g2.getTime()
    },
    contarPeriodo(now, past) {
      var g1 = new Date(now)
      var g2 = new Date(past)
      var diff = Math.abs(g1.getTime() - g2.getTime())
      var days = Math.ceil(diff / (1000 * 3600 * 24))
      return days > 30
    },
    contarDias(past) {
      var g1 = new Date()
      var g2 = new Date(past)
      var diff = Math.abs(g1.getTime() - g2.getTime())
      var days = Math.ceil(diff / (1000 * 3600 * 24))
      return days
    },
    dataParcelado(vezes, data) {
      var dias = 30 * vezes
      var date = new Date(data)
      date.setDate(date.getDate() + dias)
      return this.setDataAtual(date)
    },

    /* STRNGS  */
    cortarString(str, tam) {
      return str.slice(0, tam)
    },
    // imprimirItem(item){
    //
    // },
    parseURLParams(url) {
      var queryStart = url.indexOf('?') + 1,
        queryEnd = url.indexOf('#') + 1 || url.length + 1,
        query = url.slice(queryStart, queryEnd - 1),
        pairs = query.replace(/\+/g, ' ').split('&'),
        parms = {}, i, n, v, nv

      if (query === url || query === '') return

      for (i = 0; i < pairs.length; i++) {
        nv = pairs[i].split('=', 2)
        n = decodeURIComponent(nv[0])
        v = decodeURIComponent(nv[1])

        if (!parms.hasOwnProperty(n)) parms[n] = []
        parms[n].push(nv.length === 2 ? v : null)
      }
      return JSON.parse(parms.data[0])
    },
    toDecimal(v) {
      if (this.validarCampo(v)) {
        return parseFloat(parseFloat(v).toFixed(2))
      } else {
        return 0
      }
    },

    valorParcela(vez, vezes, vl_total) {
      var parcela = vl_total / vezes

      if (vez === vezes) {
        var aux = parcela * (vezes - 1)
        parcela = vl_total - aux
      }
      return this.toDecimal(parcela)
    },

    criarPaginaHtmlParaImpressao(conteudo) {
      var mywindow = window.open('', 'my div', 'height=700,width=900')
      mywindow.document.write('<html><head><title>Modelo de documento</title>')
      mywindow.document.write('<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" type="text/css" />')
      mywindow.document.write('<link rel="stylesheet" href="https://cdn.quilljs.com/1.3.6/quill.snow.css" type="text/css" />')
      mywindow.document.write('<link rel="stylesheet" href="https://cdn.quilljs.com/1.3.6/quill.core.css" type="text/css"  />')
      mywindow.document.write('<link rel="stylesheet" href="https://cdn.quilljs.com/1.3.6/quill.bubble.css" type="text/css"  />')
      mywindow.document.write('<style> body {font-family: Helvetica,Arial,sans-serif;}' +
        '.ql-align-center {text-align: center}' +
        '.ql-align-right {text-align: right} ' +
        '.ql-align-left {text-align: left} ' +
        'p { word-wrap: break-word;}' +
        'li {list-style-position: inside;}' +
        '.ql-align-justify {text-align: justify }</style>')
      mywindow.document.write('</head><body > <div>')
      mywindow.document.write(conteudo)
      mywindow.document.write('</div></body></html>')

      var inter = setInterval(function() {
        mywindow.print()
        mywindow.close()
        clearInterval(inter)
      }, 500)
    },
    alertas(_this, titulo, mensagem, txtBtn) {
      let message = {
        title: titulo,
        body: mensagem
      }
      _this.$dialog.alert(message, {okText: txtBtn})
        .then(function(dialog) {
          _this.closeDialog(dialog)
        })
    },
    get_pdf(urlPdf) {
      window.open(urlPdf, '_blank')
    },
    validarValor(valor) {
      return this.validarCampo(valor) ? this.toDecimal(valor) : 0
    },
    ordenarLista(lista,key) {
      lista = lista.sort(function (a, b) {
        return a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0
      })
      return lista
    },

  },


}
