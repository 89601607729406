/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class ContratoReceberControleEntrega {
  constructor (contrato_receber_id) {


    this.contrato_receber_controle_entrega_id = uuidV4()
    this.contrato_receber_id = contrato_receber_id
    this.dt_entrega = ''
    this.funcionario = ''

  }
}
