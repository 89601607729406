/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class ContratoReceberRemessa {
  constructor (contrato_receber_id) {


    this.contrato_receber_remessa_id = uuidV4()
    this.contrato_receber_id = contrato_receber_id
    this.nosso_numero = ''
    this.dt_fato = ''
    this.numero_remessa = ''
    this.tp_remessaa = ''
    this.dt_remessa = ''

  }
}
