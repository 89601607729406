/* eslint-disable */
import {autentica, lista, pegaLista, processar, trataError} from '../../../services/GenericAPI'
import {Funcionario} from '../../../models/cadastros/funcionario'
import {EventBus} from '../../../helpers/event-bus'

import {FuncionarioSCH, getFuncionarioSCH} from "../../../search/FuncionarioSCH"
import {Filtro} from "../../../models/filtro/filtro";

const url = '/funcionario'

const state = {
  all: [],
  allFuncionarios: [],
  funcionario: new Funcionario(),
  filtro: new Filtro()
}

const getters = {
  pegaFiltro: (state) => {
    return state.filtro
  },
  listaFuncionarios: (state) => {
    return state.all
  },

  listaAllFuncionarios: (state) => {
    return state.allFuncionarios
  },
  pegaFuncionario: (state) => {
    return state.funcionario
  },
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = []
    state.filtro.filters.addSearch(['funcionarios.nome', 'numero', 'logrado', 'bairro', 'municipios.nome'],
       '%'+search+'%',
        'ilike'
      )
  },
  async getItem({commit}, id) {
     var filtro = getFuncionarioSCH('funcionario_id', id, '=')
     const list = (await pegaLista(url, filtro)).data
      commit('SET_FUNCIONARIO', list.data[0])
      EventBus.$emit('GET_FUNCIONARIO_CONCLUIDO', true, list.data[0])
  },
  setFiltro({commit}, perPage) {
     commit('SET_FILTRO', FuncionarioSCH(perPage))
  },
  async setAllListFuncionarios ({ commit }) {
     const list = (await lista(url+'/all')).data
     commit('SET_ALL_FUNCIONARIOS', list)
  },

  async setAllFuncionarios ({ commit }, {onSucesso,onErro}) {
    try {
     const list = (await pegaLista(url, state.filtro)).data
     commit('SET_FUNCIONARIOS', list)
      onSucesso()
    } catch (error) {
      onErro()
    }
  },
  async processarFuncionario ({ commit }, {acao,onSucesso,onErro}) {
    try {
      let res = await processar(url, state.funcionario, acao)
      EventBus.$emit('PROCESSO_CONCLUIDO', true, true, 'UsuarioFuncionario')
      EventBus.$emit('FUNC_PROCESSO_CONCLUIDO', true)
      this.dispatch('logs_audit/processarLog', {item: state.funcionario, key: 'funcionario_id'})

      state.funcionario = new Funcionario()
      onSucesso()
    } catch (error) {
      console.log(error);
      this.dispatch('geral/setObjMsgError', trataError(error))
      EventBus.$emit('PROCESSO_CONCLUIDO', false, true, 'UsuarioFuncionarioCadastrar')
      EventBus.$emit('FUNC_PROCESSO_CONCLUIDO', false)
      onErro()
    }
  },
  async autenticarUsuario({commit}, usuario) {
    try {
      let res = await autentica(url + '/auth', usuario)
      sessionStorage.setItem('grp_acesso_id', res.data[0].grp_acesso_id)
      sessionStorage.setItem("funcionario_id", res.data[0].funcionario_id);
      sessionStorage.setItem("funcionario_nome", res.data[0].nome);

      if (res.data[0].funcionario_tema.original[0] === null) {
        EventBus.$emit('USUARIO_AUTENTICADO', true, {funcionario_id: res.data[0].funcionario_id}, false)
      } else {
        EventBus.$emit('USUARIO_AUTENTICADO', true,res.data[0].funcionario_tema.original[0], res.data[0].nome , true)
      }
    } catch (error) {
      console.log(error.response)
      if (error.response === undefined){
        let respostaError = {
          message: "Falha na comunicação com o servidor.",
          success: false,
        }
        this.dispatch('geral/setObjMsgError', respostaError)
      } else {
        this.dispatch('geral/setObjMsgError', error.response.data)
      }
      EventBus.$emit('USUARIO_AUTENTICADO', false,[])
    }
  },

  setFuncionario ({ commit }, funcionario) {
    commit('SET_FUNCIONARIO', funcionario)
  },

// ACESSIBILIDADE
  removeAcessibilidade ({ commit }, acessibilidade) {
    commit('REMOVE_FUNC_ACESS', acessibilidade)
  },
  alteraAcessibilidade ({ commit }, acessibilidade) {
    commit('ALTERA_FUNC_ACESS', acessibilidade)
  },
  adicionaAcessibilidade ({ commit }, acessibilidade) {
    commit('ADICIONA_FUNC_ACESS', acessibilidade)
  },

}

const mutations = {
  SET_ALL_FUNCIONARIOS (state, funcionarios) {
    state.allFuncionarios = funcionarios
  },
  SET_FUNCIONARIOS (state, funcionarios) {
    state.all = funcionarios
  },
  SET_FUNCIONARIO (state, funcionario) {
    state.funcionario = funcionario
  },
  // ACESSIBILIDADE
  REMOVE_FUNC_ACESS (state, acessibilidade){
    let pos = state.funcionario.funcionarios_acessibilidade.indexOf(acessibilidade)
    state.funcionario.funcionarios_acessibilidade.splice(pos, 1)
  },
  ALTERA_FUNC_ACESS (state, acessibilidade){
    let pos = state.funcionario.funcionarios_acessibilidade.indexOf(acessibilidade)
    state.funcionario.funcionarios_acessibilidade.splice(pos, 1, acessibilidade)
  },
  ADICIONA_FUNC_ACESS (state, acessibilidade){
    state.funcionario.funcionarios_acessibilidade.splice(state.funcionario.funcionarios_acessibilidade.length, 1, acessibilidade)
  },
  SET_FILTRO(state, filtro) {
  state.filtro = filtro
}
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
