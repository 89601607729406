/* eslint-disable */
import {lista} from '../../../services/GenericAPI'

const url = 'aux_situacao'

const state = {
  all: []
}

const getters = {
  listaSituacao: (state) => {
    return state.all
  }
}

const actions = {
  async setAllSituacao ({ commit }) {
    const list = (await lista(url)).data
    commit('SET_SITUACAO', list)
  }
}

const mutations = {
  SET_SITUACAO(state, motivo) {
    state.all = motivo
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
