/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Municipio {
  constructor () {

    this.municipio_id = uuidV4()
    this.nome = ''
    this.uf = ''
  }
}
