/* eslint-disable */
import {pegaDados, pegaLista} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {AuditoriaSCH, getAuditoriaSCH} from "../../../search/AuditoriaSCH";
import {Filtro} from "../../../models/filtro/filtro";

const url = 'log'

const state = {
  all: [],
  filtro: new Filtro()
}

const getters = {
  listaAuditoria: (state) => {
    return state.all
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
}
const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = []
    state.filtro.filters.addSearch(['dados_registro'],
      '%'+search+'%',
      'ilike'
    )
  },
  async getItem({commit}, id) {
    var filtro = getAuditoriaSCH('log_audit_id', id, '=')
    const list = (await pegaDados(url + '/get', filtro)).data

    EventBus.$emit('GET_LOG_CONCLUIDO', true, list.data[0])

  },
  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', AuditoriaSCH(perPage))
  },

  setFiltroGet({commit }, {perPage, filtros}) {
    // commit('SET_FILTRO', setFiltroQuadraSCH(perPage,filtros))
  },
  async setAllAuditoria({commit}, path) {
    try {
      path = path !== undefined ? path : ''
      const list = (await pegaLista(url+path, state.filtro)).data
      commit('SET_ALL', list)

      EventBus.$emit('LISTA_PROCESSADA', true)
    } catch (error) {
      EventBus.$emit('LISTA_PROCESSADA', false)
    }
  },
  async setAllAuditoria2({commit},{onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url + '/get', state.filtro)).data
      list.data.forEach(function (logs) {
        var cor = 'black'
        if (logs.acao === 'Inclusão') {
          cor = 'black'
        } else  if (logs.acao === 'Alteração') {
          cor = 'green'
        } else  if (logs.acao === 'Consulta') {
          cor = 'blue'
        } else  if (logs.acao === 'Exclusão Especial') {
          cor = '#FF9800'
        } else  if (logs.acao === 'Exclusão') {
          cor = '#EF5350'
        } else  if (logs.acao === 'Cancelamento') {
          cor = '#D50000'
        } else  if (logs.acao === 'Estorno') {
          cor = 'orange'
        } else  if (logs.acao === 'Isentar') {
          cor = 'gray'
        } else  if (logs.acao === 'Baixa') {
          cor = 'purple'
        }
        logs.rotina_acao = logs.acao
        logs.rotina_cor = cor
      })

      commit('SET_ALL', list)
      onSucesso()
    } catch (error) {
      onErro()
    }
  },
}

const mutations = {
  SET_ALL(state, quadras) {
    state.all= quadras
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
