/* eslint-disable */
import {geraUrlRelatorio, pegaDados, pegaLista, processar, trataError} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {Contrato} from "../../../models/lancamento/contrato";
import {Filtro, Where} from "../../../models/filtro/filtro";
import {ContratoDeletedSCH, ContratoSCH, getContratoSCH, setFiltroContratoSCH} from "../../../search/ContratoSCH";
import {Anexo} from "../../../models/anexo";


const url = '/contrato'

const state = {
  pdf: '',
  all: [],
  allList: [],
  allList30Dias: [],
  allListSemDocumentos: [],
  allQuitados: [],
  documento: [],
  anexos: [],
  contrato: new Contrato(),
  contfiltro: 0,
  filtro: new Filtro()
}

const getters = {
  pegaPdf: (state) => {
    return state.pdf
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
  listaAllContratos: (state) => {
    return state.allList
  },
  listaQuitados: (state) => {
    return state.allQuitados
  },
  listaAllList30Dias: (state) => {
    return state.allList30Dias
  },
  listaAllListSemDocumentos: (state) => {
    return state.allListSemDocumentos
  },
  listaContratosSemFiltro: (state) => {
    return state.all
  },
  pegaContrato: (state) => {
    return state.contrato !== null && state.contrato !== undefined ? state.contrato : new Contrato()
  },
  pegaDocumento: (state) => {
    return state.documento
  },
  pegaContratoAnexos: (state) => {
    return state.anexos !== null && state.anexos !== undefined ? state.anexos : []
  }
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = []
    state.filtro.filters.addSearch([
      'contratos_view.numero',
      'contratos_view.vl_lote',
      'contratos_view.cliente_nome',
      'contratos_view.corretora_nome',
      'contratos_view.empreendimento_nome',
      'contratos_view.lote_nome',
      'contratos_view.quadra_nome'
    ], '%' + search + '%', 'ilike')
  },
  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', ContratoSCH(perPage))
  },
  setFiltroDeleted({commit}, perPage) {
    commit('SET_FILTRO', ContratoDeletedSCH(perPage))
  },
  async getItem({commit}, id) {
    let filtro = getContratoSCH('contrato_id', id, '=')
    const list = (await pegaLista(url, filtro)).data
    commit('SET_CONTRATO', list.data[0])
    EventBus.$emit('GET_CONTRATO_CONCLUIDO', true, list.data[0])
  },
  async getContrato({commit}, {id, onSucesso, onErro}) {
    try{
      let filtro = getContratoSCH('contrato_id', id, '=')
      const list = (await pegaLista(url, filtro)).data
      commit('SET_CONTRATO', list.data[0])
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
    }
  },
  async cancelContrato({commit}, {contrato, onComplete}) {
    try {
      const list = (await pegaDados(url+'/cancel', contrato)).data
      this.dispatch('geral/setMsgSuccess', list.message)
      onComplete()
    } catch (error) {
      console.log(error)
      this.dispatch('geral/setObjMsgError', trataError(error))
      EventBus.$emit('GET_CONTRATO_CANCELADO', false)
    }
  },

  async getItemDeletadoContrato({commit}, id) {
    try {
      let filtro = getContratoSCH('contratos.contrato_id', id, '=')
      filtro.filters.addFilter('contratos.deleted', true, '=')
      const list = (await pegaLista(url, filtro)).data
      commit('SET_CONTRATO', list.data[0])
      EventBus.$emit('GET_CONTRATO_CONCLUIDO', true, list.data[0])
    } catch (error) {
      console.log(error)
      this.dispatch('geral/setObjMsgError', trataError(error))
      EventBus.$emit('GET_CONTRATO_CONCLUIDO', false)
    }
  },

  async setAllContratos({commit}, {onSucesso,onErro }) {
    try {
      const list = (await pegaDados(url + '/view', state.filtro)).data
      commit('SET_CONTRATOS', list)
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
    }
  },
  async setAllContratosQuitados({commit}) {
    try {
      const filtro = new Filtro()
      filtro.perPage = 5

      const list = (await pegaDados(url + '/get-quitados', filtro)).data
      commit('SET_CONTRATOS_QUITADOS', list)
      EventBus.$emit('QUITADOS_PROCESSADO', true)
    } catch (error) {
      EventBus.$emit('QUITADOS_PROCESSADO', false)
    }
  },

  async setAllContratosEmitidos30Dias({commit}) {
    try {
      const filtro = new Filtro()
      filtro.perPage = 5

      const list = (await pegaDados(url + '/get-emitidos-ultimos-trinta-dias', filtro)).data
      commit('SET_CONTRATOS_EMITIDOS', list)
      EventBus.$emit('EMITIDOS_PROCESSADO', true)
    } catch (error) {
      EventBus.$emit('EMITIDOS_PROCESSADO', false)
    }
  },

  async setAllContratosSemDocumentos({commit}, pagination) {
    try {
      const list = (await pegaDados(url + '/get-sem-documentos', pagination)).data
      commit('SET_CONTRATOS_SEM_DOCUMENTOS', list)
      EventBus.$emit('CONTRATOS_SEM_DOCUMENTOS_PROCESSADOS', true)
    } catch (error) {
      EventBus.$emit('CONTRATOS_SEM_DOCUMENTOS_PROCESSADOS', false)
    }
  },

  async setAllListContratos({commit}) {
    var filtro = ContratoSCH('*')
    filtro.fields = ['cliente_id', 'contrato_id', 'cliente_nome', 'numero', 'lastparcela','lastparcelaentrada']
    filtro.filters.addFilter('contratos_view.iscancelado', true, '<>')

    const list = (await pegaDados(url + '/view', filtro)).data
    commit('SET_ALL_CONTRATOS', list.data)
  },

  async setContrato({commit}, contrato) {
    if (contrato.anexos === undefined || contrato.anexos === null) {
      contrato.anexos = []
    }
    commit('SET_CONTRATO', contrato)
  },
  async setDocumento({commit}, documento) {
    commit('SET_DOCUMENTO', documento)
  },
  async setAllContratos2({commit}, {lista}) {
    commit('SET_CONTRATOS', lista)
  },

  async processarContrato({commit}, {contrato, acao, onSucesso}) {
    try {
      contrato.urls_anexos = null
      if (contrato.anexos === undefined || contrato.anexos === null) {
        contrato.anexos = []
      }
      let res = await processar(url, contrato, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      this.dispatch('logs_audit/processarLog', {item: contrato, key: 'contrato_id'})
      EventBus.$emit('PROCESSO_CONCLUIDO', true, true, 'Contratos')
      onSucesso()
    } catch (error) {
      EventBus.$emit('PROCESSO_CONCLUIDO', false, true, 'ContratosCadastrar')
      console.log('error: ', error)
      this.dispatch('geral/setObjMsgError', trataError(error))
    }
  },

  async processarContratoExcluido({commit}, {contrato,acao}) {
    try {
      contrato.urls_anexos = null
      if (contrato.anexos === undefined || contrato.anexos === null) {
        contrato.anexos = []
      }

      let res = await processar(url, contrato, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      this.dispatch('logs_audit/processarLog', {item: contrato, key: 'contrato_id'})
      EventBus.$emit('PROCESSO_CONCLUIDO', true, true, 'Contratos')
      EventBus.$emit('CONTRATO_RECUPERADO', true)
    } catch (error) {
      console.log(error)

      this.dispatch('geral/setObjMsgError', trataError(error))
      EventBus.$emit('CONTRATO_RECUPERADO', false)
      EventBus.$emit('PROCESSO_CONCLUIDO', true, true, 'ContratosCadastrar')
    }
  },

  async filtrando({commit}, {filtro}) {
    commit('SET_FILTRO', filtro)
  },

  setFiltroGet({commit}, {perPage, filtros}) {
    commit('SET_FILTRO', setFiltroContratoSCH(perPage, filtros))
  },

  processarExtras({commit}, {item, acao, tipo}) {
    if (acao === 'adicionar') {
      commit('ADICIONA_' + tipo, item)
    } else if (acao === 'alterar') {
      commit('ALTERA_' + tipo, item)
    } else if (acao === 'remover') {
      commit('REMOVE_' + tipo, item)
    }
  },
  async GerarRelatorioPdf({commit}, item) {
    try {
      const list = (await geraUrlRelatorio(url + '/pdf', state.filtro))
      commit('SET_PDF', list)
      EventBus.$emit('GERAR_PDF', true)
    } catch (error) {
      EventBus.$emit('GERAR_PDF', false)
    }

  },
}

const mutations = {
  SET_CONTRATOS_EMITIDOS(state, contratos) {
    state.allList30Dias = contratos
  },
  SET_CONTRATOS_SEM_DOCUMENTOS(state, contratos) {
    state.allListSemDocumentos = contratos
  },
  SET_ALL_CONTRATOS(state, contratos) {
    state.allList = contratos
  },
  SET_CONTRATOS(state, contratos) {
    state.all = contratos
  },
  SET_CONTRATO(state, contrato) {
    state.contrato = contrato

    if (contrato.urls_anexos !== undefined && contrato.urls_anexos !== null) {
      contrato.urls_anexos.forEach(u => {
        let anexo = new Anexo()
        anexo.anexo_id = u.anexo
        anexo.nome = u.nome
        anexo.capa = u.capa
        anexo.url = u.urls[0].url
        if (state.contrato.anexos !== undefined && state.contrato.anexos !== null) {
          state.contrato.anexos.push(anexo)
        } else {
          state.contrato.anexos = []
          state.contrato.anexos.push(anexo)
        }
      })
    }
    state.anexos = []
    if (state.contrato.anexos !== undefined) {
      state.anexos = state.contrato.anexos
    }
  },
  SET_DOCUMENTO(state, documento) {
    state.documento = documento
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },

  SET_CONTRATOS_QUITADOS(state, allQuitados) {
    state.allQuitados = allQuitados
  },

  // RECEBER
  REMOVE_RECEBER(state, receber) {
    let pos = state.contrato.contratos_receber.indexOf(receber)
    state.contrato.contratos_receber.splice(pos, 1)
  },

  ALTERA_RECEBER(state, receber) {
    var c = state.contrato.contratos_receber.find(lista => lista.contrato_receber_id === receber.contrato_receber_id)
    let pos = state.contrato.contratos_receber.indexOf(c)
    state.contrato.contratos_receber.splice(pos, 1, receber)
  },

  ADICIONA_RECEBER(state, receber) {
    state.contrato.contratos_receber.splice(state.contrato.contratos_receber.length, 1, receber)
  },

  // CLIENTES
  REMOVE_CLIENTE(state, cliente) {
    let pos = state.contrato.contratos_clientes.indexOf(cliente)
    state.contrato.contratos_clientes.splice(pos, 1)
  },

  ALTERA_CLIENTE(state, cliente) {
    var c = state.contrato.contratos_clientes.find(lista => lista.contrato_cliente_id === cliente.contrato_cliente_id)
    let pos = state.contrato.contratos_clientes.indexOf(c)
    state.contrato.contratos_clientes.splice(pos, 1, cliente)

    if (cliente.principal) {
      state.contrato.contratos_clientes.forEach(function (cc) {
        if (cc.principal && cc.contrato_cliente_id !== cliente.contrato_cliente_id) {
          cc.principal = false
        }
      })
    }
  },
  ADICIONA_CLIENTE(state, cliente) {
    if (cliente.principal) {
      state.contrato.contratos_clientes.forEach(function (cc) {
        if (cc.principal) {
          cc.principal = false
        }
      })
    }
    state.contrato.contratos_clientes.splice(state.contrato.contratos_clientes.length, 1, cliente)
  },

  // DOCUMENTOS
  REMOVE_DOCUMENTO(state, documento) {
    let pos = state.contrato.contratos_documentos.indexOf(documento)
    state.contrato.contratos_documentos.splice(pos, 1)
  },

  ALTERA_DOCUMENTO(state, documento) {
    var c = state.contrato.contratos_documentos.find(lista => lista.contrato_documento_id === documento.contrato_documento_id)
    let pos = state.contrato.contratos_documentos.indexOf(c)
    state.contrato.contratos_documentos.splice(pos, 1, documento)
  },

  ADICIONA_DOCUMENTO(state, documento) {
    state.contrato.contratos_documentos.splice(state.contrato.contratos_documentos.length, 1, documento)
  },

  // ANEXOS
  REMOVE_ANEXO(state, anexo) {

    state.contrato.anexos = state.contrato.anexos.filter(a => {
      return a.anexo_id !== anexo.anexo_id
    })
    state.anexos = []
    state.anexos = state.contrato.anexos
  },

  ALTERA_ANEXO(state, anexo) {
    var c = state.contrato.anexos.find(lista => lista.anexo_id === anexo.anexo_id)
    let pos = state.contrato.anexos.indexOf(c)
    state.contrato.anexos.splice(pos, 1, anexo)
    state.anexos = state.contrato.anexos
  },

  ADICIONA_ANEXO(state, anexo) {
    let achou = false
    if (state.contrato.anexos === undefined) {
      state.contrato.anexos = []
      state.contrato.anexos.push(anexo)
    } else if (state.contrato.anexos.length <= 0) {
      state.contrato.anexos.push(anexo)
    } else {
      state.contrato.anexos.forEach(a => {
        if (a.anexo_id === anexo.anexo_id) {
          achou = true
          a.url = anexo.url
          a.src = anexo.src
          a.nome = anexo.nome
          a.capa = anexo.capa
          a.extensao = anexo.extensao
          a.mantem_extensao = anexo.mantem_extensao
        }
      })
      if (!achou) {
        state.contrato.anexos.push(anexo)
      }
    }
    state.anexos = state.contrato.anexos
  },
  SET_PDF(state, pdf) {
    state.pdf = pdf
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
