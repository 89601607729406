/* eslint-disable */
export const removerAcentos = (text) => {
  text = text.toLowerCase()
  text = text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a')
  text = text.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e')
  text = text.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i')
  text = text.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o')
  text = text.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u')
  text = text.replace(new RegExp('[Ç]', 'gi'), 'c')
  return text
}

// QUADRAS
export const filtrarQuadra = (fil, list) => {
  var lista = []
  if (fil) {
    let exp = new RegExp(fil, 'i')
    lista = _.slice(_.filter(list, p => exp.test(
      p.numero + ' ' +
      p.empreendimento.nome + ' ' +
      p.descricao)), 0, list.length)
    return ordenarByEmpreendimento(lista)
  } else {
    return ordenarByEmpreendimento(_.slice(list, 0, list.length))
  }
}

const ordenarByEmpreendimento = (lista) => {
  lista = lista.sort(function (a, b) {
    var nameA = a.descricao.toLowerCase(), nameB = b.descricao.toLowerCase()
    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0
  })

  lista = lista.sort(function (a, b) {
    var nameA = a.numero, nameB = b.numero
    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0
  })

  lista = lista.sort(function (a, b) {
    var nameC = a.empreendimento.nome.toLowerCase(), nameD = b.empreendimento.nome.toLowerCase()
    return nameC < nameD ? -1 : nameC > nameD ? 1 : 0
  })
  return lista
}


// LOTE
export const filtrarLote = (fil, list) => {
  var lista = []
  if (fil) {
    let exp = new RegExp(fil, 'i')
    lista = _.slice(_.filter(list, p => exp.test(
      p.numero + ' ' + p.frente + ' ' + p.fundos + ' ' + p.lado_esq + ' ' + p.lado_dir + ' ' + p.tamanho + ' ' +
      p.quadra.descricao + ' ' + p.descricao)), 0, list.length)
    return ordenarLote(lista)
  } else {
    return ordenarLote(_.slice(list, 0, list.length))
  }
}


const ordenarLote = (lista) => {
  lista = lista.sort(function (a, b) {
    var nameA = a.descricao.toLowerCase(), nameB = b.descricao.toLowerCase()
    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0
  })

  lista = lista.sort(function (a, b) {
    var nameA = a.numero, nameB = b.numero
    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0
  })
  return lista
}


export const pegaCpfCnpj = (item) => {
  if (item.tp_pessoa === 'J') {
    if (item.cliente_pj !== null) {
      return item.cliente_pj.cnpj
    }
  } else {
    if (item.cliente_pf !== null) {
      return item.cliente_pf.cpf
    }
  }
}
