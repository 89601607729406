/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";


export const  getFuncionarioSCH = (campo, valor, condicao)=>{
  var filtro = new Filtro()
  filtro.perPage = 1
  filtro.filters.addFilter(campo,valor,condicao)
  filtro.fields = ['*']
  return filtro
}

export const setFiltroFuncionarioSCH = (perPage, filtro)=> {
  filtro.perPage = perPage
  return filtro
}
export const FuncionarioSCH = (perPage)=> {
    var filtro = new Filtro()
    filtro.perPage = perPage
    filtro.fields = ['funcionarios.nome', 'numero', 'logrado', 'bairro', 'municipios.nome as municipio', 'bloqueado']
    filtro.addOrder('funcionarios.nome', 'asc')
    filtro.join.addLeft('municipios','municipios.municipio_id','funcionarios.municipio_id')
  return filtro
}
