/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";

export const  getMovimentoCaixaSCH = (campo, valor, condicao)=>{
  var filtro = new Filtro()
  filtro.perPage = 1
  filtro.filters.addFilter(campo,valor,condicao)
  filtro.fields = ['*']
  return filtro
}

export const MovimentoCaixaSCH = (perPage = 1)=> {
    var filtro = new Filtro()
    filtro.perPage = perPage
    filtro.fields = ['mov_caixa.data', 'mov_caixa.status', 'mov_caixa.vl_receitas', 'mov_caixa.vl_despesas', 'caixas.descricao']
    filtro.addOrder('mov_caixa.data', 'asc')
    filtro.join.addLeft('caixas','caixas.caixa_id','mov_caixa.caixa_id')
  return filtro
}

export const getData = (datas) =>{
  var d = zeroEsquerda(new Date().getDate(),2)
  var m = zeroEsquerda(new Date().getMonth()+1, 2)
  var a = new Date().getFullYear()
  var dt = a + '-' + m + '-' +d
  return datas === dt
}

export const zeroEsquerda = (value, totalWidth, paddingChar) =>{
  var length = totalWidth - value.toString().length + 1
  return Array(length).join(paddingChar || '0') + value
}
