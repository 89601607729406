/* eslint-disable */
import {dataAtual, geraUrlRelatorio, pegaDados, processar, trataError} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {ContratoReceber} from "../../../models/lancamento/contrato_receber";
import {Filtro, Where} from "../../../models/filtro/filtro";

import {ContratoReceber2SCH, getContratoReceberSCH} from "../../../search/ContratoReceberSCH";

const url = '/contrato_receber'

const state = {
  all: [],
  conta_receber: new ContratoReceber(),
  dt_atual: '',
  contfiltro: 0,
  filtro: new Filtro(),
  pdf: '',
}

const getters = {
  pegaPdf: (state) => {
    return state.pdf
  },
  listaContaReceber: (state) => {
    return state.all
  },

  pegaContaReceberId: (state) => (id) => {
    let mun = state.all.find(conta_receber => conta_receber.conta_receber_id === id)
    return mun !== null ? mun.nome : '** NÃO ENCONTRADO'
  },
  pegaContaReceberById: (state) => (id) => {
    let mun = state.all.find(conta_receber => conta_receber.conta_receber_id === id)
    return mun !== null ? mun : new ContratoReceber()
  },
  pegaContaReceber: (state) => {
    return state.conta_receber !== null && state.conta_receber !== undefined ? state.conta_receber : new ContratoReceber()
  },
  pegaFiltro: (state) => {
    return state.filtro
  },

  pegaDataAtual: (state) => {
    return state.dt_atual
  },
}

const actions = {
  async getItem({commit}, {id, onSucesso,onErro}) {
    try {
      var filtro = getContratoReceberSCH('contrato_receber_id', id, '=')
      const list = (await pegaDados(url+'/get', filtro)).data
      onSucesso(list.data[0])
    } catch (error) {
      onErro()
    }
  },
  searchItem({commit}, search) {
    state.filtro.filters.search = []
    state.filtro.filters.addSearch([
      'numero',
      'parcela',
      'cliente',
      'lote',
      'vl_a_pagar'
    ],'%'+search+'%','ilike')
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', ContratoReceber2SCH(perPage))
    // commit('SET_FILTRO', ContratoReceberSCH(perPage))
  },
  setLista({commit}) {
    commit('SET_CONTAS_RECEBER', [])
  },

  async setdataAtual({commit}) {
    const data = (await dataAtual('/data_atual')).data
    commit('SET_DATA_ATUAL', data)
  },
  updDataBaixa({commit}) {
    commit('UPD_DATA_BAIXA')
  },
  async setAllContaReceber({commit},{ onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data
      commit('SET_CONTAS_RECEBER', list)

      // EventBus.$emit('LISTA_PROCESSADA', true)
      onSucesso()
    } catch (error) {
      // EventBus.$emit('LISTA_PROCESSADA', false)
      onErro()
    }

  },

  setContaReceber({commit}, conta_receber) {
    commit('SET_CONTA_RECEBER', conta_receber)
    EventBus.$emit('SET_CONTRATO_RECEBER', true)
  },

  async atualizaJurosMulta({commit}, {contrato_receber_id, data}) {
    let dados = {'contrato_receber_id' : contrato_receber_id, 'data' : data}
    const retorno = (await pegaDados(url + '/getjurosmulta', dados)).data[0]
    commit('UPD_JUROS_MULTA', retorno)
  },

  async processarContaReceber({commit}, {conta_receber, acao,onSucesso, onErro}) {
    try {
      let link = ''
      if (acao === 'estornar') {
        link = url + '/estornar'
      } else if (acao === 'isentar') {
        link = url + '/isentar-juros'
      } else if (acao === 'baixar') {
        link = url + '/baixar'
      } else if (acao === 'gravar') {
        link = url + '/gravar'
      } else if (acao === 'cancelar') {
        link = url + '/cancelar'
      } else if (acao === 'limpar_boleto') {
        link = url + '/limpar_boleto'
      } else {
        link = url
      }

      console.log('\nprocessarContaReceber: ', conta_receber)
      let res = await processar(link, conta_receber, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      this.dispatch('contratos/setAllListContratos')
      this.dispatch('logs_audit/processarLog', {item: conta_receber, key: 'contrato_receber_id'})
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', trataError(error))
      onErro()
    }
  },
  async processarContrato({commit}, {conta_receber, acao}) {
    try {
      let res = await processar(url, conta_receber, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      // this.dispatch('conta_receber/setAllContaReceber')
      this.dispatch('logs_audit/processarLog', {item: conta_receber, key: 'contrato_id'})
      EventBus.$emit('CONTAS_RECEBER_PROCESSO_CONCLUIDO', true)
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      EventBus.$emit('CONTAS_RECEBER_PROCESSO_CONCLUIDO', false)
    }
  },
  async filtrando({commit}, {filtro}) {
    commit('SET_FILTRO', filtro)
  },

  processarExtras({commit}, {item, acao, tipo}) {
    if (acao === 'adicionar') {
      commit('ADICIONA_' + tipo, item)
    } else if (acao === 'alterar') {
      commit('ALTERA_' + tipo, item)
    } else if (acao === 'remover') {
      commit('REMOVE_' + tipo, item)
    }
  },

  async GerarRelatorioPdf({commit}, item) {
    try {
      const list = (await geraUrlRelatorio(url+'/pdf', item))
      commit('SET_PDF', list)
      EventBus.$emit('GERAR_PDF', true)
    } catch (error) {
      EventBus.$emit('GERAR_PDF', false)
    }
  },
}

const mutations = {
  SET_PDF(state, pdf) {
    state.pdf = pdf
  },
  SET_DATA_ATUAL(state, dt_atual) {
    state.dt_atual = dt_atual
  },
  UPD_DATA_BAIXA(state) {
    let d = (new Date().getDate()).toString()
    let m = (new Date().getMonth()+1).toString()
    let a = new Date().getFullYear()

    m = (m.length === 1 ? '0' : '') + m
    d = (d.length === 1 ? '0' : '') + d
    state.conta_receber.dt_baixa = a + '-' + m + '-' + d
  },
  SET_CONTAS_RECEBER(state, conta_receber) {
    state.all = conta_receber
  },
  SET_CONTA_RECEBER(state, conta_receber) {
    state.conta_receber = conta_receber
  },
  UPD_JUROS_MULTA(state, dados) {
    state.conta_receber.vl_juros = Number(dados.juros)
    state.conta_receber.vl_multa = Number(dados.multa)

    state.conta_receber.vl_a_pagar = Number(state.conta_receber.vl_parcela) + state.conta_receber.vl_juros + state.conta_receber.vl_multa - Number(state.conta_receber.vl_desconto)
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },

  // CONTROLE DE ENTREGA
  REMOVE_CONTROLE_ENTREGA(state, documento) {
    let pos = state.conta_receber.contratos_receber_remessa.indexOf(documento)
    state.conta_receber.contratos_receber_remessa.splice(pos, 1)
  },

  ALTERA_CONTROLE_ENTREGA(state, documento) {
    var c = state.conta_receber.contratos_receber_remessa.find(lista => lista.contrato_receber_remessa_id === documento.contrato_receber_remessa_id)
    let pos = state.conta_receber.contratos_receber_remessa.indexOf(c)
    state.conta_receber.contratos_receber_remessa.splice(pos, 1, documento)
  },

  ADICIONA_CONTROLE_ENTREGA(state, documento) {
    state.conta_receber.contratos_receber_remessa.splice(state.conta_receber.contratos_receber_remessa.length, 1, documento)
  },

  // REMESSA
  REMOVE_REMESSA(state, anexo) {
    let pos = state.conta_receber.contratos_receber_controle_entrega.indexOf(anexo)
    state.conta_receber.contratos_receber_controle_entrega.splice(pos, 1)
  },

  ALTERA_REMESSA(state, anexo) {
    var c = state.conta_receber.contratos_receber_controle_entrega.find(lista => lista.contrato_receber_controle_entrega_id === anexo.contrato_receber_controle_entrega_id)
    let pos = state.conta_receber.contratos_receber_controle_entrega.indexOf(c)
    state.conta_receber.contratos_receber_controle_entrega.splice(pos, 1, anexo)
  },

  ADICIONAREMESSA(state, anexo) {
    state.conta_receber.contratos_receber_controle_entrega.splice(state.conta_receber.contratos_receber_controle_entrega.length, 1, anexo)
  },


}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
