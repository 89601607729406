/* eslint-disable */
import {ContratoReceberControleEntrega} from "./contrato_receber_controle_entrega";
import {ContratoReceberRemessa} from "./contrato_receber_remessa";
import { v4 as uuidV4 } from 'uuid';
export class ContratoReceber {
  constructor (contratoId) {
    this.contrato_receber_id = uuidV4()
    this.contrato_id = contratoId
    this.parcela = ''
    this.vl_parcela = ''
    this.dt_geracao = ''
    this.dt_vencimento = ''
    this.dt_pagamento = ''
    this.dt_baixa = ''
    this.dt_impressao_carne = ''
    this.dt_naocobrar_juros = ''
    this.vl_juros = ''
    this.vl_multa = ''
    this.vl_desconto = ''
    this.nosso_numero = ''
    this.observacoes = ''
    this.cancelado = false
    this.boleto = false
    this.tp_baixa = ''
    this.contrato_receber_controle_entrega = new ContratoReceberControleEntrega(this.contrato_receber_id)
    this.contrato_receber_remessa = new ContratoReceberRemessa(this.contrato_receber_id)
  }
}
