/* eslint-disable */
import {geraUrlRelatorio, trataError} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {Filtro, Where} from "../../../models/filtro/filtro";
import {ContratoFinSCH} from "../../../search/ContratoSCH"
import {ContratoReceberSCH} from "../../../search/ContratoReceberSCH"


const url = '/relatorio'

const state = {
  all: [],
  filtro: new Filtro(),
  pdf: '',
}

const getters = {
  pegaPdf: (state) => {
    return state.pdf
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
}

const actions = {
  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', ContratoFinSCH(perPage))
  },

  setFiltroComissao({commit}, perPage) {
    commit('SET_FILTRO', ContratoFinSCH(perPage))
  },
  setFiltroExtrato({commit}, perPage) {
    commit('SET_FILTRO', ContratoReceberSCH(perPage))
  },
  async GerarRelatorioPdf({commit}, path) {
    try {
      const list = (await geraUrlRelatorio(url+ path, state.filtro))
       commit('SET_PDF', list)
      EventBus.$emit('GERAR_PDF', true)
    } catch (error) {
      console.log(error)
      this.dispatch('geral/setObjMsgError', trataError(error))
      EventBus.$emit('GERAR_PDF', false)
    }
  },
}

const mutations = {
  SET_PDF(state, pdf) {
    state.pdf = pdf
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
