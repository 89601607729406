/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";


export const  getLoteSCH = (campo, valor, condicao)=>{
  var filtro = new Filtro()
  filtro.perPage = 1
  filtro.filters.addFilter(campo,valor,condicao)
  filtro.fields = ['*']
  return filtro
}

export const setFiltroLoteSCH = (perPage, filtro)=> {
  filtro.perPage = perPage
  return filtro
}
export const LoteSCH = (perPage)=> {
    var filtro = new Filtro()
    filtro.perPage = perPage
    filtro.fields = ['lotes.numero', 'lotes.descricao', 'quadras.descricao as quadra',
      'aux_situacao.descricao as situacao', 'empreendimentos.nome as empreendimento']
    filtro.addOrder('lotes.descricao', 'asc')
    filtro.join.addLeft('quadras','quadras.quadra_id','lotes.quadra_id')
    filtro.join.addLeft('empreendimentos','empreendimentos.empreendimento_id','quadras.empreendimento_id')
    filtro.join.addLeft('aux_situacao','aux_situacao.situacao_id','lotes.situacao_id')
  return filtro
}
export const LoteSimplesSCH = (perPage)=> {
    var filtro = new Filtro()
    filtro.perPage = perPage
  filtro.addOrder('nome', 'asc')
  return filtro
}
