/* eslint-disable */
import {EmpresaConfiguracoes} from "./empresa_configuracoes";
import {EmpresaDadosBancarios} from "./empresa_dados_bancarios";
import { v4 as uuidV4 } from 'uuid';
export class Empresa {
  constructor () {


    this.empresa_id = uuidV4()
    this.cnpj = ''
    this.razao = ''
    this.fantasia = ''
    this.ie = ''
    this.logrado = ''
    this.numero = ''
    this.bairro = ''
    this.municipio_id = ''
    this.complemento = ''
    this.cep = ''
    this.fone1 = ''
    this.fone2 = ''
    this.email = ''
    this.empresa_configuracoes = new EmpresaConfiguracoes(this.empresa_id)
    this.empresa_dados_bancario = new EmpresaDadosBancarios(this.empresa_id)
  }
}
