/* eslint-disable */
import {
  geraUrlRelatorio,
  lista,
  listaSituacao,
  pegaDados,
  pegaItem,
  pegaLista,
  processar,
  trataError
} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {Lote} from "../../../models/cadastros/lote";
import {Filtro, Where} from "../../../models/filtro/filtro";
import {getLoteSCH, LoteSCH, LoteSimplesSCH, setFiltroLoteSCH} from "../../../search/LoteSCH"

const url = '/lote'

const state = {
  all: [],
  allTemp: [],
  situacao: [],
  allListLotes: [],
  filtro2: false,
  filtro: new Filtro(),
  contfiltro: 0,
  pdf: ''
}

const getters = {
  pegaPdf: (state) => {
    return state.pdf
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
  listaLotes: (state) => {
    return state.all
  },
  listaAllListLotes: (state) => {
    return state.allListLotes
  },
  listaLotesSemFiltro: (state) => {
    return state.all
  },
  listaSituacao: (state) => {
    return state.situacao
  },
  pegaLoteId: (state) => (id) => {
    let mun = state.all.find(lote => lote.lote_id === id)
    return mun !== null ? mun.nome : '** NÃO ENCONTRADO'
  },
  pegaLote: (state) => (id) => {
    let mun = state.all.find(lote => lote.lote_id === id)
    return mun !== null ? mun : new Lote()
  },
  pegarSituacao: (state) => (id) => {
    let mun = state.situacao.find(sit => sit.situacao_id === id)
    return mun !== null ? mun : new Lote()
  },
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = []
    state.filtro.filters.addSearch(['lotes.numero', 'lotes.descricao','quadras.descricao','aux_situacao.descricao',
      'empreendimentos.nome'],'%'+search+'%','ilike')
  },
  async getItem({commit}, id) {
     var filtro = getLoteSCH('lotes.lote_id', id, '=')
     const list = (await pegaLista(url, filtro)).data
     EventBus.$emit('GET_LOTE_CONCLUIDO', true,  list.data[0])
  },
  async setAllLotes({commit},{onSucesso, onErro}) {
    try {
     const list = (await pegaLista(url, state.filtro)).data
     commit('SET_LOTES', list)
      onSucesso()
    } catch (error) {
      onErro()
    }
  },
  async setAllLotesHome({commit}) {
    try {
     const list = (await pegaDados(url+'/get-disponiveis', state.filtro)).data
     commit('SET_LOTES', list)
     EventBus.$emit('LOTES_HOME_PROCESSADA', true)
    } catch (error) {
     EventBus.$emit('LOTES_HOME_PROCESSADA', false)
    }
  },

  async setAllLotes2({commit}, {lista}) {
    commit('SET_LOTES', lista)
  },

  async setAllSituacao({commit}) {
    const list = (await listaSituacao(url)).data
    commit('SET_SITUACAO', list)
  },

  async setLotesByQuadra({commit}, quadra) {
    const list = (await pegaItem(url+'/get_by_quadra', quadra)).data
    commit('SET_ALL_LOTES', list)
    EventBus.$emit('GET_ALL_LOTE', true)
  },
  setAllListLotes2({commit}, list) {
    commit('SET_ALL_LOTES', list)
  },

  async setAllListLotes({commit}) {
    const list = (await lista(url+'/all')).data
    commit('SET_ALL_LOTES', list)
    EventBus.$emit('GET_ALL_LOTE', true)
  },

  async processarLote({commit}, {lote, acao,onSucesso,onErro}) {
    try {
      let res = await processar(url, lote, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      this.dispatch('logs_audit/processarLog', {item: lote, key: 'lote_id'})
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', trataError(error))
      onErro()
    }
  },
  async filtrando({commit}, {filtro}) {
    commit('SET_FILTRO', filtro)
  },
  setFiltro({commit}, perPage) {
     commit('SET_FILTRO', LoteSCH(perPage))
  },
  setFiltroHome({commit}, perPage) {
     commit('SET_FILTRO', LoteSimplesSCH(perPage))
  },
  setFiltroGet({commit}, {perPage, filtros}) {
    commit('SET_FILTRO', setFiltroLoteSCH(perPage,filtros))
  },
  async GerarRelatorioPdf({commit}, item) {
    try {
      const list = (await geraUrlRelatorio(url+'/pdf', state.filtro))
      commit('SET_PDF', list)
      EventBus.$emit('GERAR_PDF', true)
    } catch (error) {
      EventBus.$emit('GERAR_PDF', false)
    }

  },
}

const mutations = {
  SET_LOTES(state, lotes) {
    state.all = lotes
  },
  SET_ALL_LOTES(state, lotes) {
    state.allListLotes = lotes
  },
  SET_LOTES_TEMP(state, lotes) {
    state.allTemp = lotes
  },
  SET_SITUACAO(state, situacao) {
    state.situacao = situacao
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_PDF(state, pdf) {
    state.pdf = pdf
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
