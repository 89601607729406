/* eslint-disable */
import {geraUrlRelatorio, lista, pegaLista, processar, trataError} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {EventoCaixa} from "../../../models/cadastros/evento_caixa";
import {Filtro, Where} from "../../../models/filtro/filtro";
import {EventoCaixaSCH} from "../../../search/CaixaSCH";

const url = '/evento_caixa'

const state = {
  all: [],
  pdf: '',
  allList: [],
  filtro: new Filtro()
}

const getters = {
  pegaPdf: (state) => {
    return state.pdf
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
  listaEventoCaixas: (state) => {
    return state.all
  },
  listaEventoCaixasSemFiltro: (state) => {
    return state.allList
  },
  pegaEventoCaixaId: (state) => (id) => {
    let mun = state.allList.find(evento_caixa => evento_caixa.evento_id === id)
    return (mun !== null && mun !== undefined) ? mun.descricao : '** NÃO ENCONTRADO'
  },
  pegaEventoCaixa: (state) => (id) => {
    let mun = state.allList.find(evento_caixa => evento_caixa.evento_id === id)
    return mun !== null ? mun : new EventoCaixa()
  },
}

const actions = {
  async setAllListEventos ({ commit }) {
    const list = (await lista(url+'/all')).data
    commit('SET_ALL_EVENTO_CAIXAS', list)
  },

  async setAllEventoCaixas ({ commit },{onSucesso, onErro}) {
    try {
     const list = (await pegaLista(url, state.filtro)).data
     commit('SET_EVENTO_CAIXAS', list)
      onSucesso()
    } catch (error) {
      onErro()
    }
  },
  async processarEventoCaixa ({ commit }, { evento_caixa, acao, onSucesso, onErro }) {
    try {
      let res = await processar(url, evento_caixa, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      this.dispatch('logs_audit/processarLog', {item: evento_caixa, key: 'evento_id'})
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', trataError(error))
      onErro()
    }
  },

  searchItem({commit}, search) {
    state.filtro.filters.search = []
    state.filtro.filters.addSearch(['descricao'], '%' + search + '%', 'ilike')
  },

  setFiltro({commit}, perPage) {
     commit('SET_FILTRO', EventoCaixaSCH(perPage))
  },
  async GerarRelatorioPdf({commit}, item) {
    try {
      const list = (await geraUrlRelatorio(url+'/pdf', item))
      commit('SET_PDF', list)
      EventBus.$emit('GERAR_PDF', true)
    } catch (error) {
      EventBus.$emit('GERAR_PDF', false)
    }

  },
}

const mutations = {
  SET_EVENTO_CAIXAS (state, evento_caixa) {
    state.all = evento_caixa
  },
  SET_ALL_EVENTO_CAIXAS (state, evento_caixa) {
    state.allList = evento_caixa
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_PDF(state, pdf) {
    state.pdf = pdf
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
