/* eslint-disable */
import {lista, pegaLista, processar} from '../../services/GenericAPI'
import {EventBus} from '../../helpers/event-bus'
import {GrupoAcesso} from "../../models/grupo_acesso";
import {removerAcentos} from '../../helpers/search'
import {Filtro, Where} from "../../models/filtro/filtro";
import {getGrupoAcessoSCH} from "../../search/GrupoAcessoSCH";

const url = '/grupo_acesso'

const state = {
  all: [],
  allList: [],
  allListRotina: [],
  rotinas: [],
  grupo: [],
  filtro: new Filtro()
}

const getters = {
  pegaFiltro: (state) => {
    return state.filtro
  },
  listaGrupoAcessos: (state, getters, rootState) => {
    return state.all.filter(m => removerAcentos(m.descricao.toLowerCase()).includes(removerAcentos(rootState.geral.search.toLowerCase())))
  },
  listaGrupoAcessosSemFiltro: (state) => {
    return state.allList
  },
  listaAllRotinas: (state) => {
    return state.allListRotina
  },
  listaRotinas: (state) => {
    return state.rotinas
  },
  pegaGrupoAcesso: (state) => (id) => {
    let mun = state.allList.find(grupo_acesso => grupo_acesso.grp_acesso_id === id)
    return mun !== null ? mun : new GrupoAcesso()
  },
  pegaRotina: (state) => (id) => {
    let mun = state.allListRotina.find(rotina => rotina.rotina_id === id)
    return mun !== null || mun !== undefined ? mun.rotina_descricao : 'Não encontrado'
  },
}

const actions = {
  async setAllRotinaList ({ commit }) {
    const list = (await lista(url+'/rotina_list')).data
    commit('SET_ROTINAS_LIST', list)

  },
  async setAllRotina ({ commit }) {
    const list = (await lista(url+'/rotinas')).data
    commit('SET_ROTINAS', list)
  },

  async setAllGrupoAcessosCompleto ({ commit }) {
    const list = (await lista(url+'/all')).data
    commit('SET_ALL_GRUPO_ACESSOS', list)
  },
  async setAllGrupoAcessos ({ commit }) {
    const list = (await lista(url)).data
    commit('SET_GRUPO_ACESSOS', list)
  },
  async processarGrupoAcesso ({ commit }, { grupo_acesso, acao, onSucesso, onErro }) {
    try {
      let res = await processar(url, grupo_acesso, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      this.dispatch('logs_audit/processarLog', {item: grupo_acesso, key: 'grp_acesso_id'})
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro()
    }
  },
  searchItem({commit}, search) {
  },
  async getGrupoAcesso({commit}, id) {
     var filtro = getGrupoAcessoSCH('grupo_acesso.grp_acesso_id', id, '=')
     const list = (await pegaLista(url+'/item', filtro)).data
     EventBus.$emit('GET_GRUPO_ACESSO_CONCLUIDO', true,  list.data[0])
  },
  setFiltro({commit}, perPage) {
  },
}

const mutations = {
  SET_ALL_GRUPO_ACESSOS (state, grupo_acessos) {
    state.allList = grupo_acessos
  },
  SET_GRUPO_ACESSOS (state, grupo_acessos) {
    state.all = grupo_acessos
  },
  SET_ROTINAS (state, rotina) {
    state.rotinas = rotina
  },
  SET_ROTINAS_LIST (state, rotina) {
    state.allListRotina = rotina
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
