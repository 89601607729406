/* eslint-disable */
import {geraUrlRelatorio, lista, pegaDados, pegaLista, processar, trataError} from '../../../services/GenericAPIPOST'
import {Cliente} from '../../../models/cadastros/cliente'
import {ClientePf} from '../../../models/cadastros/cliente_pf'
import {ClientePj} from '../../../models/cadastros/cliente_pj'
import {EventBus} from '../../../helpers/event-bus'
import {Anexo} from '../../../models/anexo'
import {Filtro, Where} from "../../../models/filtro/filtro";
import {
  ClienteDeletedSCH,
  ClienteSCH,
  ClienteSimplesSCH,
  getClienteImprimirSCH,
  getClienteSCH
} from "../../../search/ClienteSCH"

const url = '/cliente'

const state = {
  all: [],
  allList: [],
  allListSimples: [],
  anexos: [],
  cliente: new Cliente(),
  filtro: new Filtro(),
  pdf: ''
}

const getters = {
  pegaPdf: (state) => {
    return state.pdf
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
  listAllListClientes: (state) => {
    return state.allList
  },
  listAllArray: (state) => {
    // criado esse método pra depois tratar pq o clientes inadimplentes tá preenchendo o all list
    // com conteudo diferente do setAllList normal
    return state.allList.length > 0 && state.allList[0]["cliente_id"] !== undefined ? state.allList : []
  },
  listaClientes: (state) => {return state.all},
  listaClientesInadinp: (state) => {return state.allListSimples},

  pegaClienteById: (state) => (clienteid) => {
    let cli = state.allList.find(list => list.cliente_id === clienteid)
    if (cli === undefined) {
      return  '** NÃO ENCONTRADO'
    } else  {
      return cli.nome
    }


  },
  pegaCliente: (state) => {
    return state.cliente
  },
  pegaClienteAnexos: (state) => {
    return state.anexos !== null && state.anexos !== undefined ? state.anexos : []
  }
}

const actions = {
  async setAllListClientes({commit}) {
    const list = (await lista(url + '/all')).data
    commit('SET_ALLLIST', list)
  },
  async setAllClientes({commit},{onSucesso,onErro}) {
    try {
      const list = (await pegaLista(url, state.filtro)).data
      commit('SET_CLIENTES', list)
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
    }
  },
  async setClientesInadimplentesHome({commit}) {
    try {
      const list = (await pegaDados(url+'/get-inadimplentes', state.filtro)).data
      commit('SET_ALLLIST_INAD', list)
      EventBus.$emit('CLIENTE_IN_PROCESSADA', true)
    } catch (error) {
      EventBus.$emit('CLIENTE_IN_PROCESSADA', false)
    }
  },

    async setClientesReceberHome({commit}) {
    try {
      const list = (await pegaLista(url+'/get-receber', state.filtro)).data
      commit('SET_CLIENTES', list)
      EventBus.$emit('CLIENTE_REC_PROCESSADA', true)
    } catch (error) {
      EventBus.$emit('CLIENTE_REC_PROCESSADA', false)
    }
  },

  async processarCliente({commit}, acao) {
    try {
      state.cliente.urls_anexos = null // só pra diminuir o tamanho do que manda pro server
      let res
      if (acao === 'remover') {
        res = await processar(url + '/delete', state.cliente, 'gravar')
      } else {
        res = await processar(url + '/commit', state.cliente, 'gravar')
      }

      this.dispatch('geral/setMsgSuccess', res.data.message)
      this.dispatch('logs_audit/processarLog', {item: state.cliente, key: 'cliente_id'})
      EventBus.$emit('PROCESSO_CONCLUIDO', true, true, 'Clientes')
    } catch (error) {
      this.dispatch('geral/setObjMsgError', trataError(error))
      EventBus.$emit('PROCESSO_CONCLUIDO', false, true, 'ClienteCadastrar')
    }
  },
  async processarClienteExcluido({commit}, acao) {
    try {
      console.log('processarClienteExcluido: ', state.cliente)

      state.cliente.urls_anexos = null // só pra diminuir o tamanho do que manda pro server
      let res
        res = await processar(url + '/commit', state.cliente, acao)

      this.dispatch('geral/setMsgSuccess', res.data.message)
      EventBus.$emit('CLIENTE_RECUPERADO', true)
    } catch (error) {
      this.dispatch('geral/setObjMsgError', trataError(error))
      EventBus.$emit('CLIENTE_RECUPERADO', false)
    }
  },

  async getItem2({commit}, id) {
    var filtro = getClienteImprimirSCH('clientes.cliente_id', id, '=')
    const list = (await pegaLista(url, filtro)).data
    commit('SET_CLIENTE', list.data[0])
    EventBus.$emit('GET_CLIENTE_IMPRIMIR', true)
  },

  async getItem({commit}, id) {
    try {
    var filtro = getClienteSCH('cliente_id', id, '=')
    const list = (await pegaDados(url, filtro)).data
    commit('SET_CLIENTE', list.data[0])

    EventBus.$emit('GET_CLIENTE_CONCLUIDO', true)
    } catch (error) {
      console.log(error)
      this.dispatch('geral/setObjMsgError', trataError(error))
      EventBus.$emit('GET_CLIENTE_CONCLUIDO', false)
    }
  },
  async getItemDeletado({commit}, id) {
    try {
    var filtro = getClienteSCH('cliente_id', id, '=')
     filtro.filters.addFilter('clientes.deleted',true,'=')
    const list = (await pegaDados(url, filtro)).data
    commit('SET_CLIENTE', list.data[0])

    EventBus.$emit('GET_CLIENTE_CONCLUIDO', true)
    } catch (error) {
      console.log(error)
      this.dispatch('geral/setObjMsgError', trataError(error))
      EventBus.$emit('GET_CLIENTE_CONCLUIDO', false)
    }
  },

  setCliente({commit}, cliente) {

    if (cliente.anexos === undefined || cliente.anexos === null) {
      cliente.anexos = []
    }
    commit('SET_CLIENTE', cliente)
  },
  addAnexo({commit}, anexo) {
    commit('ADD_ANEXO', anexo)
  },
  delAnexo({commit}, id) {
    commit('DEL_ANEXO', id)
  },
  searchItem({commit}, search) {
    state.filtro.filters.search = []
    state.filtro.filters.addSearch(['clientes.nome', 'fone1', 'clientes_pf.cpf', 'clientes_pj.cnpj', 'municipios.nome'],
      '%' + search + '%',
      'ilike'
    )
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', ClienteSCH(perPage))
  },
  setFiltroDeleted({commit}, perPage) {
    commit('SET_FILTRO', ClienteDeletedSCH(perPage))
  },
  setFiltroHome({commit}, perPage) {
    commit('SET_FILTRO', ClienteSimplesSCH(perPage))
  },
  async GerarRelatorioPdf({commit}, item) {
    try {
      const list = (await geraUrlRelatorio(url + '/pdf', item))
      commit('SET_PDF', list)
      EventBus.$emit('GERAR_PDF', true)
    } catch (error) {
      EventBus.$emit('GERAR_PDF', false)
    }
  },
}

const mutations = {
  SET_ALLLIST_INAD(state, clientes) {
    state.allListSimples = clientes
  },
  SET_ALLLIST(state, clientes) {
    state.allList = clientes
  },
  SET_CLIENTES(state, clientes) {
    state.all = clientes
  },
  SET_CLIENTE(state, cliente) {
    if (cliente.tp_pessoa === 'J') {
      cliente.cliente_pf = new ClientePf()
    } else if (cliente.tp_pessoa === 'F') {
      cliente.cliente_pj = new ClientePj()
    }
    state.cliente = cliente
    if (cliente.urls_anexos !== undefined && cliente.urls_anexos !== null) {
      cliente.urls_anexos.forEach(u => {
        let anexo = new Anexo()
        anexo.anexo_id = u.anexo
        anexo.nome = u.nome
        anexo.capa = u.capa
        anexo.url = u.urls[0].url
        if (state.cliente.anexos !== undefined && state.cliente.anexos !== null) {
          state.cliente.anexos.push(anexo)
        } else {
          state.cliente.anexos = []
          state.cliente.anexos.push(anexo)
        }
      })
    }
    state.anexos = []
    state.anexos = state.cliente.anexos
  },
  ADD_ANEXO(state, anexo) {
    let achou = false

    if (state.cliente.anexos === undefined) {
      state.cliente.anexos = []
      state.cliente.anexos.push(anexo)
    } else if (state.cliente.anexos.length <= 0) {
      state.cliente.anexos.push(anexo)
    } else {
      state.cliente.anexos.forEach(a => {
        if (a.anexo_id === anexo.anexo_id) {
          achou = true
          a.url = anexo.url
          a.src = anexo.src
          a.nome = anexo.nome
          a.capa = anexo.capa
          a.extensao = anexo.extensao
          a.mantem_extensao = anexo.mantem_extensao
        }
      })
      if (!achou) {
        state.cliente.anexos.push(anexo)
      }
    }
    state.anexos = state.cliente.anexos
  },
  DEL_ANEXO(state, id) {
    state.cliente.anexos = state.cliente.anexos.filter(a => {
      return a.anexo_id !== id
    })
    state.anexos = []
    state.anexos = state.cliente.anexos
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_PDF(state, pdf) {
    state.pdf = pdf
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
