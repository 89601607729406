<template>
  <div>
    <v-currency-field :name=name
                      dense
                      outlined
                      :label=label
                      :prefix=buildPrefix
                      :suffix=buildSuffix
                      :value="buildValue"
                      @input="updateValue"
                      :class=classes
                      :decimal-length=buildDecimals
                      :readonly=chkReadonly
                      :rules=rulesValidation
                      :hint=hint
                      :min=buildMin
                      :max=buildMax
                      :disabled=disabled
                      :allow-negative=false
                      class="inp v_decimal">
    </v-currency-field>
  </div>
</template>

<script async>
/* eslint-disable */
  import validator from './commons/validator'
  import {mapGetters} from 'vuex'

export default {
  mixins: [validator],
  name: 'pCurrency',
  props: {
    name: {
      type: String
    },
    label: {
      default: 'Label'
    },
    validation: {
      type: String,
      default: ''
    },
    addclass: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
    },
    hint: {
      type: String,
      default: ''
    },
    percent: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    normal: {
      type: Boolean,
      default: false
    },
    decimals: {
      type: Number,
      default: 2
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    rulesValidation: [],
    pInputType: null,
    classes: ''
  }),
  methods: {
    updateValue(valor) {
      // let _valor = valor.replace(/\./gi, '').replace(/,/gi, '.')
      // this.$emit('input', Number(_valor))
      this.$emit('input', valor)
    }
  },
  computed: {
    ...mapGetters('geral', {
      disableField: 'getDisableField'
    }),
    buildPrefix() {
      if (this.normal || this.percent) {
        return ''
      }
      return 'R$'
    },
    buildSuffix() {
      if (this.percent) {
        return '%'
      }
      return ''
    },
    buildDecimals() {
      return Number(this.decimals)
    },
    buildMin() {
      return 0
    },
    buildMax() {
      if (this.percent) {
        return 100
      }
      return 9999999.99
    },
    buildValue() {
      return Number(this.value)
    },
    chkReadonly() {
      if (this.disableField || this.readonly) {
        this.classes += ' desabilitado'
      }
      return this.disableField || this.readonly
    }
  },
  created() {
    /* eslint-disable no-console */
    this.classes = this.addclass

    if (this.validation !== '') {
      this.buildRules(this)
    }
    // checando se a class requerido foi adicionado no objeto, se não tiver vou adicionar a classe padrão
    if (this.classes.indexOf('requerido') === -1) {
      this.classes += ' padrao'
    }
    /* eslint-enable no-console */
  }
}
</script>

<style type="text/css">
  .v_decimal.v-input input {
    text-align: right;
  }
</style>
