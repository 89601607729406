import {mapGetters} from 'vuex'

export default {
  methods: {
    temAcesso(rotina) {
      const rotCompleta = sessionStorage.getItem('rotina_id') + '.' + rotina
      const temp = this.nivelAcesso.grupo_acessos_permissoes.filter(n => n.rotina_id === rotCompleta)
      if (temp.length > 0) {
        return temp[0].acessivel
      }
      return false
    },
    ehVisivel(rotina) {
      const rotCompleta = sessionStorage.getItem('rotina_id') + '.' + rotina
      const temp = this.nivelAcesso.grupo_acessos_permissoes.filter(n => n.rotina_id === rotCompleta)
      if (temp.length > 0) {
        return temp[0].visivel
      }
      return false
    }
  },
  computed: {
    ...mapGetters('geral', { nivelAcesso: 'pegarPermissaoGrupoAcesso'})
  }
}
