import {geraUrlRelatorio, processar, trataError} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {zeroEsquerda} from '../../../helpers/util-functions'

const url = '/contrato_receber'

const state = {
  pdf: ''
}

const getters = {
  pegaPdf: (state) => {
    return state.pdf
  }
}

const actions = {
  async previewRetorno({commit}, {dados, onSucesso, onErro}) {
    try {
      const list = (await geraUrlRelatorio(url + '/preview_retorno', dados))
      commit('SET_PDF', list)
      EventBus.$emit('pdf-gerado', true)
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', trataError(error))
      EventBus.$emit('pdf-gerado', false)
      onErro()
    }
  },

  async processaRetorno({commit}, {dados, onSucesso, onErro}) {
    try {
      const res = await processar(url + '/baixar_retorno', dados)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', trataError(error))
      onErro()
    }
  },
}

const mutations = {
  SET_PDF(state, pdf) {
    state.pdf = pdf
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
