/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Quadra {
  constructor () {


    this.quadra_id = uuidV4()
    this.descricao = ''
    this.empreendimento_id = ''
    this.numero = ''
  }
}
